import produce from 'immer';
import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { updateVisualizeOperationThunk } from 'actions/dataPanelConfigActions';
import { SortOrderToggle } from 'components/SortDirectionToggles';
import { Icon, Select, sprinkles } from 'components/ds';
import {
  DefaultSortColumn,
  OPERATION_TYPES,
  SortOrder,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { DatasetSchema } from 'types/datasets';
import { keyBy } from 'utils/standard';

import { CardConfig } from './CardConfig';
import { MultiCardConfig } from './MultiCardConfig';

const convertSortInfoToArray = (sortInfo: DefaultSortColumn | DefaultSortColumn[] | undefined) => {
  return Array.isArray(sortInfo) ? sortInfo : sortInfo === undefined ? [] : [sortInfo];
};

type Props = {
  schema: DatasetSchema;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  operationType: OPERATION_TYPES.VISUALIZE_PIVOT_TABLE | OPERATION_TYPES.VISUALIZE_TABLE;
};

export const MultiColumnSortingConfig: FC<Props> = ({ schema, instructions, operationType }) => {
  const dispatch = useDispatch();

  const [columns, sortOptions] = useMemo(() => {
    const defaultSortColumn = instructions.defaultSortedColumn;
    const initialSortColumns = !Array.isArray(defaultSortColumn)
      ? defaultSortColumn === undefined
        ? []
        : [defaultSortColumn]
      : defaultSortColumn;
    const initialSortColumnNames = new Set(initialSortColumns.map((col) => col.column));
    const options: Array<{ value: string; label: string }> = [];
    schema.forEach((col) => {
      if (!initialSortColumnNames.has(col.name))
        options.push({ value: col.name, label: col.friendly_name || col.name });
    });
    return [initialSortColumns, options];
  }, [instructions.defaultSortedColumn, schema]);

  const schemaByColumnName = useMemo(() => keyBy(schema, 'name'), [schema]);

  const updateInstructions = (
    updateFunc: (draft: VisualizeTableInstructions | VisualizePivotTableInstructions) => void,
  ) => {
    const newInstructions = produce(instructions, (draft) => {
      updateFunc(draft);
    });
    dispatch(updateVisualizeOperationThunk(newInstructions, operationType));
  };

  const onAdd = () =>
    updateInstructions((draft) => {
      draft.defaultSortedColumn = convertSortInfoToArray(draft.defaultSortedColumn);
      draft.defaultSortedColumn.push({ column: undefined, order: undefined });
    });

  const onDelete = (index: number) =>
    updateInstructions((draft) => {
      draft.defaultSortedColumn = convertSortInfoToArray(draft.defaultSortedColumn);
      draft.defaultSortedColumn.splice(index, 1);
    });

  const updateSelectedSortColumn = (sortColumn: DefaultSortColumn, index: number) =>
    updateInstructions((draft) => {
      draft.defaultSortedColumn = convertSortInfoToArray(draft.defaultSortedColumn);
      draft.defaultSortedColumn[index] = { ...draft.defaultSortedColumn[index], ...sortColumn };
    });

  return (
    <MultiCardConfig
      adderText="Sort by column values"
      defaultEnabled={columns.length > 0}
      onAdd={onAdd}
      onDisable={() => updateInstructions((draft) => (draft.defaultSortedColumn = undefined))}
      title="Set an initial sort">
      {columns.map((col, index) => {
        const selectedColumn = col.column;
        const columnOrder = col.order;
        const displayName = selectedColumn
          ? schemaByColumnName[selectedColumn]?.friendly_name ?? selectedColumn
          : undefined;

        const title = (
          <>
            <div className={sprinkles({ truncateText: 'ellipsis' })} title={displayName ?? ''}>
              {displayName ?? '[Column Name]'}
            </div>
            <Icon name="arrow-right" />
            {columnOrder === SortOrder.DESC ? 'Descending' : 'Ascending'}
          </>
        );
        return (
          <CardConfig
            defaultOpen={index === 0 || selectedColumn === undefined}
            key={`card-config-${index}`}
            onDelete={() => onDelete(index)}
            title={title}>
            <div className={sprinkles({ flexItems: 'column' })}>
              <div className={sprinkles({ backgroundColor: 'outline' })} style={{ height: 1 }} />
              <div className={sprinkles({ padding: 'sp2', flexItems: 'column', gap: 'sp2' })}>
                <Select
                  label="Column"
                  onChange={(newCol) => updateSelectedSortColumn({ column: newCol }, index)}
                  selectedValue={selectedColumn}
                  values={
                    selectedColumn
                      ? [...sortOptions, { value: selectedColumn, label: displayName }]
                      : sortOptions
                  }
                />
                <div className={sprinkles({ flexItems: 'column', gap: 'sp.5' })}>
                  <SortOrderToggle
                    order={columnOrder}
                    updateOrder={(newOrder) => updateSelectedSortColumn({ order: newOrder }, index)}
                  />
                </div>
              </div>
            </div>
          </CardConfig>
        );
      })}
    </MultiCardConfig>
  );
};
