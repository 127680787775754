import * as Redux from 'redux';

import { registerUserError, registerUserRequest, registerUserSuccess } from 'actions/authAction';
import { showSuccessToast } from 'shared/sharedToasts';

const VERIFICATION_EMAIL_SENT = 'Verification e-mail sent.';

export function registerUser(
  first_name: string,
  last_name: string,
  email: string,
  password1: string,
  password2: string,
  errorCallback: (response: Record<string, string>) => void,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (dispatch: Redux.Dispatch<any>) {
    dispatch(registerUserRequest({}));
    return fetch(process.env.REACT_APP_API_URL + 'rest-auth/registration/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        first_name: first_name,
        last_name: last_name,
        email: email,
        password1: password1,
        password2: password2,
      }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        // not really much we can do here without in-housing our account management backend
        if (data.detail !== VERIFICATION_EMAIL_SENT) {
          dispatch(registerUserError(''));
          errorCallback(data);
        } else {
          showSuccessToast(
            'A verification email has been sent to your email address, if this account does not already exist.',
          );
          dispatch(registerUserSuccess(data.user));
        }
      });
  };
}
