import { FC } from 'react';

import { TimeFormatConfig } from 'components/ChartConfigs/TimeFormatConfig';
import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { SettingHeader } from 'components/SettingHeader';
import { Input } from 'components/ds';
import { V2_NUMBER_FORMATS, NUMBER_FORMATS_WITH_DECIMALS } from 'constants/dataConstants';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { MapTooltipFormat } from 'types/maps';

type Props = {
  hideHeader?: boolean;
  format: MapTooltipFormat;
  onUpdateFormat: (newFormat: MapTooltipFormat) => void;
};

// TODO(SHIBA-5718): Consolidate this config with the KPI value format config
export const MapTooltipConfig: FC<Props> = ({ format, hideHeader, onUpdateFormat }) => {
  const selectedFormat = format.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  return (
    <div className={configRootClass}>
      {!hideHeader ? <SettingHeader name="Tooltip" /> : null}
      <NumberFormatToggle
        className={configInputClass}
        label="Format"
        selectedFormat={selectedFormat.id}
        updateFormat={(numberFormat) => onUpdateFormat({ numberFormat: { id: numberFormat } })}
      />
      {selectedFormat.id === V2_NUMBER_FORMATS.TIME.id ? (
        <TimeFormatConfig
          configInputClass={configInputClass}
          updateFormat={onUpdateFormat}
          valueFormat={format}
        />
      ) : null}
      {NUMBER_FORMATS_WITH_DECIMALS.includes(selectedFormat.id) ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(format.decimalPlaces ?? 2)}
          label="Decimal Places"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            onUpdateFormat({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
      {selectedFormat.id === V2_NUMBER_FORMATS.ABBREVIATED.id ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(format.significantDigits ?? 3)}
          label="Significant Digits"
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            onUpdateFormat({ significantDigits: intValue >= 0 ? intValue : 3 });
          }}
        />
      ) : null}

      <Input
        showInputButton
        className={configInputClass}
        defaultValue={format.unit}
        label="Unit"
        onSubmit={(newValue) => onUpdateFormat({ unit: newValue })}
      />
    </div>
  );
};
