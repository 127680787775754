import { Dashboard } from 'actions/dashboardActions';

/**
 * @returns The depth of the given dashboard in the dashboard hierarchy. This depth is 1 indexed
 * (i.e. the root is at a depth of 1).
 */
export const getDashboardDepth = (
  dashboardHierarchy: Record<string, Dashboard>,
  dashboardId: number,
): number => {
  let depth = 1;
  let currentDashboard = dashboardHierarchy[dashboardId];

  while (currentDashboard.parentDashboardId) {
    currentDashboard = dashboardHierarchy[currentDashboard.parentDashboardId];
    depth++;
  }

  return depth;
};
