import produce from 'immer';
import { FC } from 'react';

import { Input, sprinkles } from 'components/ds';
import { SliderElementConfig } from 'types/dashboardTypes';
import { getSliderThumbVariableName } from 'utils/sliderUtils';

type Props = {
  config: SliderElementConfig;

  updateSliderConfig: (config: SliderElementConfig) => void;
};

export const SliderDefaultValueConfig: FC<Props> = ({ config, updateSliderConfig }) => {
  return (
    <div className={sprinkles({ display: 'flex', flexDirection: 'column', gap: 'sp2' })}>
      {Array(config.numThumbs)
        .fill(undefined)
        .map((_, thumbIndex) => {
          let label = 'Default value';
          if (config.numThumbs > 1) {
            label += ` (thumb ${thumbIndex + 1})`;
          }
          return (
            <Input
              key={thumbIndex}
              label={label}
              onChange={(newValue) =>
                updateSliderConfig(
                  produce(config, (draft) => {
                    draft.defaultValue[getSliderThumbVariableName(thumbIndex)] = Number(newValue);
                  }),
                )
              }
              type="number"
              value={String(config.defaultValue[getSliderThumbVariableName(thumbIndex)])}
            />
          );
        })}
    </div>
  );
};
