import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { EmbedFetchShareData, embedFetchShareData } from 'actions/embedActions';
import { EmbeddedChart } from 'components/EmbeddedChart';
import { EmbedSpinner } from 'components/embed';

type MatchParams = {
  shareId: string;
  dataPanelId: string;
};

export default function SharedChartPage() {
  const dispatch = useDispatch();

  const [shareData, setShareData] = useState<EmbedFetchShareData | null>(null);
  const {
    params: { shareId, dataPanelId },
  } = useRouteMatch<MatchParams>();

  useEffect(() => {
    dispatch(
      embedFetchShareData({ id: shareId }, (shareData) => {
        setShareData(shareData);
      }),
    );
  }, [dispatch, shareId]);

  if (!shareData) return <EmbedSpinner fillContainer size="xl" />;

  return (
    <EmbeddedChart
      isScreenshotDownload
      customerToken={shareData.customer_token}
      dashboardEmbedId={shareData.dashboard_embed_id}
      dataPanelId={dataPanelId}
      versionNumber={shareData.version_number}
    />
  );
}
