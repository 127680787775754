import { FC, useState } from 'react';

import { DatePresetEditor } from 'components/ChartConfigs/DateConfig/DatePresetEditor';
import { DatePresetItem } from 'components/ChartConfigs/DateConfig/DatePresetItem';
import { Button, sprinkles } from 'components/ds';
import { CustomDatePresetId, DateRangePresetConfig } from 'types/dashboardTypes';

type Props = {
  presets: Record<CustomDatePresetId, DateRangePresetConfig>;
  onUpdate: (id: string, value: DateRangePresetConfig) => void;
  onAdd: (value: DateRangePresetConfig) => void;
  onDelete: (id: string) => void;
};

export const DatePresetManager: FC<Props> = ({ presets, onUpdate, onAdd, onDelete }) => {
  const [expandedKey, setExpandedKey] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  return (
    <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
      <span className={sprinkles({ body: 'section' })}>Date Range Presets</span>
      {Object.entries(presets).map(([key, preset]) =>
        expandedKey === key ? (
          <DatePresetEditor
            key={key}
            onCancel={() => setExpandedKey('')}
            onDelete={() => onDelete(key)}
            onUpdate={(updatedPreset) => {
              onUpdate(key, updatedPreset);
              setExpandedKey('');
            }}
            value={preset}
          />
        ) : (
          <DatePresetItem
            key={key}
            onDelete={() => onDelete(key)}
            onEdit={() => setExpandedKey(key)}
            value={preset}
          />
        ),
      )}
      {isAdding ? (
        <DatePresetEditor onAdd={onAdd} onCancel={() => setIsAdding(false)} />
      ) : (
        <Button onClick={() => setIsAdding(true)} variant="primary">
          Create New Preset
        </Button>
      )}
    </div>
  );
};
