import { OPERATION_TYPES } from 'constants/types';

// TODO(zifanxiang/tstenz): Add more operation types as we support them. Starting with just basic
// bar charts.
export const VALID_DRILLDOWN_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
]);

// These limit constants must be kept in sync with their corresponding constants in
// dashboard_template.py.
export const MAX_CHILD_DASHBOARDS_PER_PARENT = 3;

export const MAX_DASHBOARD_HIERARCHY_DEPTH = 3;
