import { AxiosResponse } from 'axios';
import { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createTeam, addUserToTeam, getUserTeamInvite, logOutUser } from 'actions/authAction';
import { ACTION } from 'actions/types';
import { logInUserSuccess } from 'actions/userActions';
import { fetchProfile } from 'auth/userAuth';
import { ExploLoadingSpinner } from 'components/ExploLoadingSpinner';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { Input, Button, sprinkles } from 'components/ds';
import { ROUTES } from 'constants/routes';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { showErrorToast } from 'shared/sharedToasts';
import { pageView } from 'telemetry/exploAnalytics';
import { isUUID } from 'utils/general';

enum PAGE_ACTION {
  PENDING_INVITE,
  REQUEST_TRIAL,
  JOIN_TEAM,
  CREATE_TEAM,
}

export const JoinTeamPage: FC = () => {
  const { currentUser, addUserToTeamLoading, createTeamLoading } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      addUserToTeamLoading: createLoadingSelector([ACTION.ADD_USER_TO_TEAM], false)(state),
      createTeamLoading: createLoadingSelector([ACTION.CREATE_TEAM], false)(state),
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [inviteHash, setInviteHash] = useState('');
  const [fetchedTeamName, setFetchedTeamName] = useState('');
  const [numTeamUsers, setNumTeamUsers] = useState(0);
  const [pageAction, setPageAction] = useState(PAGE_ACTION.REQUEST_TRIAL);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    pageView('Join Team');

    dispatch(
      getUserTeamInvite({}, (response) => {
        setIsLoading(false);
        if (response.team_name) {
          setInviteHash(response.invite_hash);
          setFetchedTeamName(response.team_name);
          setNumTeamUsers(response.num_users);
          setPageAction(PAGE_ACTION.PENDING_INVITE);
        }
      }),
    );
  }, [dispatch]);

  const onSuccess = () => {
    fetchProfile(
      (user) => {
        dispatch(logInUserSuccess(user));
        history.push(ROUTES.HOME_APP_PAGE);
      },
      () => dispatch(logOutUser()),
    );
  };

  const onJoinTeamError = (e: AxiosResponse) => {
    const errorMessage =
      e && e.status === 403
        ? `You are already logged in with ${currentUser.email}. If this is the wrong account, please logout with the link in the top right.`
        : 'Incorrect invite code. Please ensure there are no spaces or tabs.';

    showErrorToast(errorMessage, 10);
  };

  const renderJoinTeamButton = (isPrimary?: boolean) => (
    <Button
      loading={addUserToTeamLoading}
      onClick={() => {
        if (!currentUser.id || (!isUUID(inviteCode) && !inviteHash)) {
          return showErrorToast('Invalid Invite Code', 10);
        }

        dispatch(addUserToTeam(currentUser.id, inviteCode, onSuccess, onJoinTeamError, inviteHash));
      }}
      variant={isPrimary ? 'primary' : 'secondary'}>
      Join
    </Button>
  );

  const renderCreateTeamBody = () => (
    <>
      <Input
        data-testid="sign-up-team-input"
        onChange={setTeamName}
        placeholder="Team name"
        value={teamName}
      />
      <Button
        fillWidth
        data-testid="sign-up-team-submit"
        disabled={teamName === '' || addUserToTeamLoading}
        loading={createTeamLoading}
        onClick={() => dispatch(createTeam(teamName, onSuccess, (e) => showErrorToast(e, 10)))}>
        Create a Team
      </Button>
    </>
  );

  const renderDivider = () => (
    <div className={sprinkles({ flex: 1, backgroundColor: 'gray6' })} style={{ height: 1 }} />
  );

  const renderJoinTeamBody = () => (
    <>
      <div>
        Ask your teammates to send you an email invite, but if that isn’t working you can contact
        our team to set the account up with an invite code.
      </div>
      <div className={inlineClass}>
        <Input
          className={sprinkles({ flex: 1 })}
          defaultValue={inviteCode}
          id="invite_code"
          name="invite_code"
          onSubmit={setInviteCode}
          placeholder="000-000-000-000"
        />
        {renderJoinTeamButton()}
      </div>
    </>
  );

  const renderPendingInviteBody = (fetchedTeamName: string) => (
    <>
      <div>A teammate has invited you to join their team.</div>
      <div className={inlineClass}>
        <div className={pendingInviteClass}>
          <div>{fetchedTeamName}</div>
          <div className={sprinkles({ color: 'active' })}>
            {numTeamUsers} {numTeamUsers === 1 ? 'member' : 'members'}
          </div>
        </div>
        {renderJoinTeamButton(true)}
      </div>
      <div className={inlineClass}>
        <Button
          fillWidth
          onClick={() => setPageAction(PAGE_ACTION.REQUEST_TRIAL)}
          variant="secondary">
          Skip
        </Button>
      </div>
    </>
  );

  const renderRequestTrialBody = () => (
    <>
      <Button fillWidth to="https://www.explo.co/request-a-trial" variant="secondary">
        Request free trial
      </Button>
      {currentUser.can_create_team ? (
        <Button
          fillWidth
          data-testid="sign-up-create-team"
          disabled={addUserToTeamLoading}
          loading={addUserToTeamLoading}
          onClick={() => setPageAction(PAGE_ACTION.CREATE_TEAM)}>
          Create a New Team
        </Button>
      ) : null}
      <div className={inlineClass}>
        {renderDivider()}
        <div className={sprinkles({ textAlign: 'center', color: 'gray10' })}>or</div>
        {renderDivider()}
      </div>
      <div className={sprinkles({ heading: 'h2', color: 'black' })}>Join an existing team</div>
      <div>Ask your teammates to send you an email invite.</div>
      <div>
        Or ask our team for an
        <span
          className={sprinkles({ color: 'active', cursor: 'pointer' })}
          onClick={() => setPageAction(PAGE_ACTION.JOIN_TEAM)}>
          {' '}
          invite code
        </span>
        .
      </div>
    </>
  );

  const getContentTitle = () => {
    switch (pageAction) {
      case PAGE_ACTION.CREATE_TEAM:
        return 'Create a new team and begin your 7 day trial!';
      case PAGE_ACTION.JOIN_TEAM:
        return 'Join an existing team';
      case PAGE_ACTION.REQUEST_TRIAL:
        return 'Request a free trial';
      case PAGE_ACTION.PENDING_INVITE:
        return 'You have a pending invite';
    }
  };

  const getContent = () => {
    if (isLoading) return <ExploLoadingSpinner />;

    switch (pageAction) {
      case PAGE_ACTION.CREATE_TEAM:
        return renderCreateTeamBody();
      case PAGE_ACTION.JOIN_TEAM:
        return renderJoinTeamBody();
      case PAGE_ACTION.REQUEST_TRIAL:
        return renderRequestTrialBody();
      case PAGE_ACTION.PENDING_INVITE:
        return renderPendingInviteBody(fetchedTeamName);
    }
  };
  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Log Out', onClick: () => dispatch(logOutUser()) },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      title={getContentTitle()}>
      <div className={contentClass}>{getContent()}</div>
    </OnboardingFlowPage>
  );
};

const pendingInviteClass = sprinkles({
  flexItems: 'alignCenterBetween',
  flex: 1,
  padding: 'sp1',
  borderRadius: 4,
  backgroundColor: 'elevationMid',
  body: 'b2',
  color: 'black',
});

const contentClass = sprinkles({
  width: 'fill',
  flexItems: 'column',
  gap: 'sp3',
  color: 'contentSecondary',
  body: 'b2',
});

const inlineClass = sprinkles({ flexItems: 'alignCenter', gap: 'sp1' });
