import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { switchEditingDashboard } from 'actions/dashboardV2Actions';
import { Icon, sprinkles } from 'components/ds';
import { DashboardSelectionControlDatum } from 'pages/dashboardPage/LayersPanel/types';

interface Props {
  itemData: DashboardSelectionControlDatum;
  currentDashboardId: number;
}

const ROOT_HIERARCHY_LEVEL = 0;

// TODO(zifanxiang): Look into seeing how to type this more generally to all the values specified by
// the spacing object in sprinkles.
const ITEM_INDENT_MAP: Record<number, 'sp0' | 'sp1' | 'sp2'> = {
  0: 'sp0',
  1: 'sp1',
  2: 'sp2',
};

/** A control for selecting a particular dashboard within a dashboard hierarchy. */
export const DashboardSelectionControl: FC<Props> = ({ itemData, currentDashboardId }) => {
  const dispatch = useDispatch();

  const iconName =
    itemData.hierarchyLevel === ROOT_HIERARCHY_LEVEL ? 'folder' : 'arrow-turn-down-right';
  const itemIndent = ITEM_INDENT_MAP[itemData.hierarchyLevel];
  const isSelectedDashboardControl = currentDashboardId === itemData.dashboardId;
  return (
    <button
      className={createSelectionControlClass(itemIndent)}
      onClick={() => {
        if (!isSelectedDashboardControl) {
          dispatch(switchEditingDashboard({ dashboardId: itemData.dashboardId }));
        }
      }}>
      <div className={sprinkles({ flexItems: 'alignCenter' })}>
        <Icon name={iconName} />
        <div className={sprinkles({ marginX: 'sp1' })}>{itemData.name}</div>
      </div>
      {isSelectedDashboardControl ? <Icon name="check" /> : null}
    </button>
  );
};

const createSelectionControlClass = (itemIndent: 'sp0' | 'sp1' | 'sp2'): string =>
  sprinkles({
    flexItems: 'alignCenterBetween',
    height: 32,
    overflowX: 'hidden',
    marginLeft: itemIndent,
    marginY: 'sp2',
    width: 'fill',
  });
