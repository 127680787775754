import { FC } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import { SupportedDataSource } from 'actions/dataSourceActions';
import { DataSourceButton } from 'components/DataSourceButton';
import { sprinkles } from 'components/ds';
import { dataSourceByType } from 'constants/dataSourceConstants';
import { ConnectDataSourceContainer } from 'pages/ConnectDataSourceFlow/ConnectDataSourceContainer';
import { setDataSourceType } from 'reducers/fidoDataSourceConfigurationReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getOrDefault } from 'remotedata';

import { ConnectDataSourceStep, SUPPORTED_FIDO_DATA_SOURCES } from '../constants';
import { DBConnectionConfig } from '../types';

type Props = {
  config: DBConnectionConfig;
  updateConfig: (newConfig: DBConnectionConfig) => void;
  onNextClicked: () => void;
  onBackClicked: () => void;
};

export const SelectDatabase: FC<Props> = ({
  config,
  updateConfig,
  onNextClicked,
  onBackClicked,
}) => {
  const dispatch = useDispatch();

  const { supportedDataSources, type, useFido, enabledDataSources, canOnlyUseFidoDataSources } =
    useSelector(
      (state: ReduxState) => ({
        supportedDataSources: state.dataSource.supportedDataSources,
        type: state.fidoDataSourceConfig.dataSourceConfig.type,
        useFido: state.currentUser.team?.feature_flags.use_fido,
        canOnlyUseFidoDataSources:
          state.currentUser.team?.feature_flags.can_only_use_fido_data_sources,
        enabledDataSources: state.currentUser.team?.entitlements.enabled_data_sources ?? [],
      }),
      shallowEqual,
    );

  const shouldShowDataSource = (dataSource: SupportedDataSource) => {
    if (!canOnlyUseFidoDataSources) {
      return true;
    }

    // empty means allow all fido data sources
    if (enabledDataSources.length === 0) {
      return SUPPORTED_FIDO_DATA_SOURCES.includes(dataSource.name);
    }

    return enabledDataSources.includes(dataSource.name);
  };

  return (
    <ConnectDataSourceContainer
      backBtnOnClick={onBackClicked}
      bodyContent={
        <div
          className={sprinkles({
            display: 'grid',
            columnGap: 'sp1.5',
            rowGap: 'sp1.5',
          })}
          style={{ gridTemplateColumns: 'repeat(3, minmax(0, 1fr))' }}>
          {getOrDefault(supportedDataSources, []).map((dataSource) => {
            if (!shouldShowDataSource(dataSource)) {
              return null;
            }
            return (
              <DataSourceButton
                dataSourceName={dataSource.name}
                disabled={!!config.selectedDataSourceIsLocked}
                imgUrl={dataSourceByType[dataSource.name]?.datasourceIconImg}
                key={`select-db-${dataSource.name}`}
                onClick={() => {
                  //  Duplicating keeping track of the data source name when useFido so Fido components can have easy access via reducer
                  //  and avoid having to introduce a lot of splitting logic for use fido about type or data source name
                  if (useFido) dispatch(setDataSourceType(dataSource.name));
                  updateConfig({ ...config, selectedDataSource: dataSource });
                }}
                selected={config.selectedDataSource?.name === dataSource.name}
              />
            );
          })}
        </div>
      }
      currentStep={ConnectDataSourceStep.SELECT_DB}
      headerTitle="Select a database"
      primaryActionConfig={{
        text: 'Next',
        //These really should be the same - just sanity checking type gets set
        disabled: useFido ? !type : !config.selectedDataSource,
        onClick: onNextClicked,
      }}
    />
  );
};
