import { FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Tabs, sprinkles } from 'components/ds';
import { TabOption } from 'components/ds/Tabs';
import { EditingLayout, PanelTab, setPanelTab } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets, getSelectedDataPanel, getSelectedElement } from 'reducers/selectors';
import { isDashboardDatasetSaved } from 'utils/onboarding';

import { DataPanelConfig } from '../DataPanelConfig';
import { LayersPanel } from '../LayersPanel/LayersPanel';
import { ElementConfigPanel } from '../elementConfig/ElementConfigPanel';
import { PageHeaderConfig } from '../elementConfig/PageHeaderConfig/PageHeaderConfig';

import { DatasetsPanel } from './DatasetsPanel';
import { EditDashboardPanel } from './EditDashboardPanel';
import { EditableSectionConfig } from './EditableSectionConfig';
import { ElementShowHidePanel } from './ElementShowHidePanel';
import { VariablesPanel } from './VariablesPanel';
import * as styles from './styles.css';

type Props = {
  dashboardId: number;
  isFullPageDashboard: boolean;
  shareLinkTitle: string | null;
};

const getPanelTabs = (enableDrilldownDashboards: boolean): TabOption[] => {
  const panelTabs: TabOption[] = [
    { id: PanelTab.ADD, icon: 'plus', tooltip: 'Add' },
    { id: PanelTab.CONFIGURE, icon: 'chart-column', tooltip: 'Configure' },
    { id: PanelTab.DATASETS, icon: 'database', tooltip: 'Datasets' },
    { id: PanelTab.VARIABLES, icon: 'brackets', tooltip: 'Variables' },
  ];
  if (enableDrilldownDashboards) {
    panelTabs.push({ id: PanelTab.LAYERS, icon: 'layer', tooltip: 'Layers' });
  }
  return panelTabs;
};

export const EditDashboardLeftPanel: FC<Props> = ({
  dashboardId,
  isFullPageDashboard,
  shareLinkTitle,
}) => {
  const dispatch = useDispatch();

  const {
    currTab,
    isEditing,
    viewMode,
    editingLayout,
    element,
    dataPanel,
    leftPaneOpen,
    hasSavedDataset,
    selectedDatasetId,
    enableDrilldownDashboards,
  } = useSelector(
    (state: ReduxState) => ({
      currTab: state.dashboardInteractions.panelTab,
      isEditing: state.dashboardInteractions.interactionsInfo.isEditing,
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      editingLayout: state.dashboardInteractions.editingLayout,
      element: getSelectedElement(state),
      dataPanel: getSelectedDataPanel(state),
      leftPaneOpen: state.dashboardInteractions.paneOpenStates.left,
      hasSavedDataset: isDashboardDatasetSaved(Object.values(getEditableDatasets(state))),
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
      enableDrilldownDashboards:
        state.currentUser?.team?.feature_flags.enable_drilldown_dashboards || false,
    }),
    shallowEqual,
  );

  const isEditableSection =
    !isFullPageDashboard &&
    editingLayout === EditingLayout.EDITABLE_SECTION &&
    currTab === PanelTab.CONFIGURE;

  if (!isEditing || (!isFullPageDashboard && !leftPaneOpen)) return null;

  const renderTabs = () => {
    if (isFullPageDashboard) return null;
    return (
      <Tabs
        className={styles.leftPanelTabs}
        onTabSelect={(tabId) => dispatch(setPanelTab(tabId as PanelTab))}
        selectedTabId={currTab}
        tabs={getPanelTabs(enableDrilldownDashboards)}
      />
    );
  };

  const renderPanel = () => {
    if (isFullPageDashboard) return <ElementShowHidePanel viewMode={viewMode} />;

    switch (currTab) {
      case PanelTab.ADD:
        return <EditDashboardPanel />;
      case PanelTab.VARIABLES:
        return <VariablesPanel dashboardId={dashboardId} />;
      case PanelTab.DATASETS: {
        const elementConfig = element?.config;
        return !selectedDatasetId ? (
          <DatasetsPanel
            dashboardId={dashboardId}
            selectedChartDatasetId={
              dataPanel?.table_id ??
              (elementConfig && 'valuesConfig' in elementConfig
                ? elementConfig.valuesConfig?.queryTable?.id
                : undefined)
            }
          />
        ) : null;
      }
      case PanelTab.LAYERS:
        return <LayersPanel />;
      default:
      // Fall through
    }

    if (editingLayout === EditingLayout.STICKY_HEADER) {
      return <PageHeaderConfig dashboardId={dashboardId} />;
    }

    if (isEditableSection) return <EditableSectionConfig dashboardId={dashboardId} />;

    if (dataPanel) return <DataPanelConfig dashboardId={dashboardId} dataPanel={dataPanel} />;
    if (element) {
      return (
        <ElementConfigPanel
          dashboardId={dashboardId}
          element={element}
          shareLinkTitle={shareLinkTitle}
        />
      );
    }
    return (
      <div className={styles.emptyConfigTab}>
        <div className={sprinkles({ heading: 'h3' })}>
          Select a chart or element to see its configuration
        </div>
        <div className={sprinkles({ body: 'b2', color: 'contentSecondary' })}>
          Adjust the data or formatting
        </div>
      </div>
    );
  };

  return (
    <div className={styles.leftPanelWrapper}>
      {renderTabs()}
      <div
        className={
          isEditableSection
            ? styles.editableSectionPanelWrapper
            : styles.editDashboardLeftPanelWrapper
        }
        style={{ overflow: !hasSavedDataset ? 'hidden' : undefined }}>
        {renderPanel()}
        {hasSavedDataset ||
        currTab === PanelTab.DATASETS ||
        currTab === PanelTab.VARIABLES ? null : (
          <div className={styles.savedDatasetFirstPane}>
            Click the datasets tab to run your first query.
          </div>
        )}
      </div>
    </div>
  );
};
