import { FC } from 'react';

import { Input, Switch } from 'components/ds';
import { SliderElementConfig } from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SliderElementConfig;

  updateSliderConfig: (config: SliderElementConfig) => void;
};

export const SliderAdjustDisplayConfig: FC<Props> = ({ config, updateSliderConfig }) => {
  const updateConfig = getUpdateConfigFunc(config, updateSliderConfig);

  return (
    <>
      <Switch
        className={styles.configInput}
        label={{ text: 'Label', variableInput: true }}
        onChange={() => updateConfig((draft) => (draft.showSliderLabel = !draft.showSliderLabel))}
        switchOn={config.showSliderLabel}
      />
      {config.showSliderLabel ? (
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.label}
          onSubmit={(newValue) => updateConfig((draft) => (draft.label = newValue))}
        />
      ) : null}
      <Switch
        className={styles.configInput}
        label="Descriptive Tooltip"
        onChange={() => updateConfig((draft) => (draft.showTooltip = !draft.showTooltip))}
        switchOn={config.showTooltip}
      />
      {config.showTooltip ? (
        <Input
          showInputButton
          className={styles.configInput}
          defaultValue={config.infoTooltipText}
          onSubmit={(newValue) => updateConfig((draft) => (draft.infoTooltipText = newValue))}
        />
      ) : null}
      <Switch
        className={styles.configInput}
        label="Show thumb labels"
        onChange={() => updateConfig((draft) => (draft.showThumbLabels = !config.showThumbLabels))}
        switchOn={config.showThumbLabels}
      />
    </>
  );
};
