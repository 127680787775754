import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { sprinkles, Tabs } from 'components/ds';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { AllStates } from 'reducers/rootReducer';
import { SIDE_PANE_WIDTH } from 'shared/ExploResource/constants';
import { canUserModifyResource } from 'utils/permissionUtils';

import { VIEW_MODE } from './types';

const rootClass = sprinkles({
  flexItems: 'column',
  height: 'fill',
  borderRight: 1,
  borderColor: 'gray7',
  backgroundColor: 'white',
});

const tabs = [
  { name: 'Datasets', id: VIEW_MODE.DATASETS },
  { name: 'Built Ins', id: VIEW_MODE.BUILT_INS },
  { name: 'Settings', id: VIEW_MODE.SETTINGS },
];

const tabNames = tabs.map((tab) => tab.name);

export const EditorLeftColumn: FC = ({ children }) => {
  const history = useHistory();
  const { reportBuilderId, view } = useParams<{ reportBuilderId: string; view: VIEW_MODE }>();

  const permissions = useSelector((state: AllStates) =>
    'currentUser' in state ? state.currentUser.permissions : {},
  );

  if (!canUserModifyResource(permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER])) return null;

  const selectedTabId = tabs.find((tab) => tab.id === view)?.name;

  const handleTabSelect = (tabName: string) => {
    const tab = tabs.find((tab) => tab.name === tabName);
    if (tab) history.push(`/report-builder/${reportBuilderId}/${tab.id}`);
  };

  return (
    <div className={rootClass} style={{ width: SIDE_PANE_WIDTH }}>
      <Tabs onTabSelect={handleTabSelect} selectedTabId={selectedTabId} tabs={tabNames} />
      <div className={sprinkles({ flex: 1, overflowY: 'auto' })}>{children}</div>
    </div>
  );
};
