import cx from 'classnames';
import { Draft } from 'immer';
import { FC, useState } from 'react';

import { Input, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { INTEGER_DATA_TYPE, STRING } from 'constants/dataConstants';
import { SwitchElementConfig } from 'types/dashboardTypes';

import * as styles from '../styles.css';
import { getUpdateConfigFunc } from '../utils';

type Props = {
  config: SwitchElementConfig;

  updateSwitchConfig: (config: SwitchElementConfig) => void;
};

export const SwitchValuesConfig: FC<Props> = ({ config, updateSwitchConfig }) => {
  const [selectedType, setSelectedType] = useState(config.switchValueType);
  const [onValueErrorMessage, setOnValueErrorMessage] = useState('');
  const [offValueErrorMessage, setOffValueErrorMessage] = useState('');

  const updateConfig = getUpdateConfigFunc(config, updateSwitchConfig);

  const typeItems: SelectItems<typeof STRING | typeof INTEGER_DATA_TYPE> = SWITCH_VALUE_TYPES.map(
    (type) => {
      return {
        label: SWITCH_VALUE_TYPE_TO_LABELS_MAP[type],
        value: type,
      };
    },
  );
  const handleSubmit = (
    newValue: string,
    setErrorMessageFn: (errorMessage: string) => void,
    updateStatusFn: (draft: Draft<SwitchElementConfig>) => void,
  ) => {
    const errorMessage = validateInputtedValue(newValue, selectedType || STRING);
    if (errorMessage) {
      setErrorMessageFn(errorMessage);
      return;
    }
    updateConfig((draft) => updateStatusFn(draft));
    setErrorMessageFn('');
  };
  return (
    <>
      <div className={cx(sprinkles({ display: 'flex' }), styles.configInput)}>
        <Input
          showInputButton
          className={sprinkles({ paddingRight: 'sp.5' })}
          defaultValue={config.onStatusValue}
          errorText={onValueErrorMessage}
          label="On Value"
          onSubmit={(newValue: string) => {
            handleSubmit(
              newValue,
              setOnValueErrorMessage,
              (draft) => (draft.onStatusValue = newValue),
            );
          }}
          placeholder="true"
        />
        <Input
          showInputButton
          className={sprinkles({ paddingLeft: 'sp.5' })}
          defaultValue={config.offStatusValue}
          errorText={offValueErrorMessage}
          label="Off Value"
          onSubmit={(newValue: string) => {
            handleSubmit(
              newValue,
              setOffValueErrorMessage,
              (draft) => (draft.offStatusValue = newValue),
            );
          }}
          placeholder="false"
        />
      </div>
      <Select
        label="Value type"
        onChange={(value) => {
          const castedValue = value as typeof STRING | typeof INTEGER_DATA_TYPE;
          setSelectedType(castedValue);
          updateConfig((draft) => (draft.switchValueType = castedValue));
        }}
        selectedValue={selectedType}
        values={typeItems}></Select>
    </>
  );
};

const SWITCH_VALUE_TYPES: Array<typeof STRING | typeof INTEGER_DATA_TYPE> = [
  STRING,
  INTEGER_DATA_TYPE,
];

const SWITCH_VALUE_TYPE_TO_LABELS_MAP = {
  [STRING]: 'String',
  [INTEGER_DATA_TYPE]: 'Integer',
};

const validateInputtedValue = (
  value: string,
  type: typeof STRING | typeof INTEGER_DATA_TYPE,
): string => {
  switch (type) {
    case STRING:
      return '';
    case INTEGER_DATA_TYPE: {
      if (isNaN(parseInt(value))) {
        return 'Value must be a valid integer';
      }
      return '';
    }
  }
};
