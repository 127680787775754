import { forwardRef, useCallback } from 'react';

import { Input, Props as InputProps } from '.';

interface NumberInputProps extends Omit<InputProps, 'onChange' | 'value' | 'onSubmit'> {
  value?: number;
  onChange?: (value: number) => void;
  onSubmit?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  decimal?: boolean;
}

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  ({ value, onChange, onSubmit, min, max, decimal = false, defaultValue, ...props }, ref) => {
    const parseAndValidateValue = useCallback(
      (newValue: string) => {
        let parsedValue = decimal ? parseFloat(newValue) : parseInt(newValue);

        if (isNaN(parsedValue)) {
          parsedValue = decimal ? 0 : 0;
        }

        if (min !== undefined && parsedValue < min) {
          parsedValue = min;
        }

        if (max !== undefined && parsedValue > max) {
          parsedValue = max;
        }

        return parsedValue;
      },
      [decimal, min, max],
    );

    const handleValueChange = useCallback(
      (newValue: string) => {
        const validatedValue = parseAndValidateValue(newValue);
        onChange?.(validatedValue);
      },
      [onChange, parseAndValidateValue],
    );

    const handleValueSubmit = useCallback(
      (newValue: string) => {
        const validatedValue = parseAndValidateValue(newValue);
        onSubmit?.(validatedValue);
      },
      [onSubmit, parseAndValidateValue],
    );

    return (
      // @ts-ignore
      <Input
        defaultValue={defaultValue !== undefined ? String(defaultValue) : undefined}
        onChange={handleValueChange}
        onSubmit={handleValueSubmit}
        ref={ref}
        value={value !== undefined ? String(value) : undefined}
        {...props}
      />
    );
  },
);

NumberInput.displayName = 'NumberInput';
