import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';

export enum AuthMethods {
  EMAIL = 'email',
  APP = 'app',
}

interface ActivateData {
  details: string; // Either a message. Or for app auth, an otpauth URL that can be turned into a QR code
}

export const {
  actionFn: mfaActivate,
  successAction: mfaActivateSuccess,
  errorAction: mfaActivateError,
  requestAction: mfaActivateRequest,
} = defineAPIAction<ActivateData>(ACTION.MFA_ACTIVATE, 'rest-auth', 'activate', 'POST');

interface ActivateConfirmBody {
  code: string;
}

interface ActivateConfirmData {
  backup_codes: string[];
}

export const {
  actionFn: mfaConfirm,
  successAction: mfaConfirmSuccess,
  errorAction: mfaConfirmError,
  requestAction: mfaConfirmRequest,
} = defineAPIPostAction<ActivateConfirmBody, ActivateConfirmData>(
  ACTION.MFA_CONFIRM,
  'rest-auth',
  'activate/confirm',
  'POST',
);

type DeactivateBody = ActivateConfirmBody;

export const {
  actionFn: mfaDeactivate,
  successAction: mfaDeactivateSuccess,
  errorAction: mfaDeactivateError,
  requestAction: mfaDeactivateRequest,
} = defineAPIPostAction<DeactivateBody, void>(
  ACTION.MFA_DEACTIVATE,
  'rest-auth',
  'deactivate',
  'POST',
);

interface RequestCodeBody {
  method: string;
}

export const {
  actionFn: requestCode,
  successAction: requestCodeSuccess,
  errorAction: requestCodeError,
  requestAction: requestCodeRequest,
} = defineAPIPostAction<RequestCodeBody, void>(
  ACTION.MFA_REQUEST_CODE,
  'rest-auth/code/request',
  '',
  'POST',
);

export type UserActiveMethodsData = {
  name: string;
  is_primary: boolean;
}[];

export const {
  actionFn: getUserActiveMethods,
  successAction: getUserActiveMethodsSuccess,
  errorAction: getUserActiveMethodsError,
  requestAction: getUserActiveMethodsRequest,
} = defineAPIAction<UserActiveMethodsData>(
  ACTION.MFA_USER_ACTIVE_METHODS,
  'rest-auth/mfa/user-active-methods',
  '',
  'GET',
);

type ChangePrimaryMethodBody = {
  method: string;
  code: string;
};

export const {
  actionFn: changePrimaryMethod,
  successAction: changePrimaryMethodSuccess,
  errorAction: changePrimaryMethodError,
  requestAction: changePrimaryMethodRequest,
} = defineAPIPostAction<ChangePrimaryMethodBody, void>(
  ACTION.MFA_CHANGE_PRIMARY_METHOD,
  'rest-auth/mfa/change-primary-method',
  '',
  'POST',
);
