import { Dataset } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';

export const getDatasetName = (dataset: Dataset | ReportBuilderDataset): string => {
  if ('table_name' in dataset) {
    return dataset.table_name;
  } else if ('name' in dataset) {
    return dataset.name;
  }

  throw new Error('Invalid dataset object, contains neither a table_name nor a name property');
};
