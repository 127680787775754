export type Testimonial = {
  companyLogo: string;
  personName: string;
  personTitle: string;
  personImage: string;
  quote: string;
};

export enum TESTIMONIAL_COMPANIES {
  SAFE_BASE = 'Safebase',
  TOGETHER = 'Together',
  SPEKIT = 'Spekit',
  CAJU = 'Caju',
  NASH = 'Nash',
}

export const TESTIMONIALS: Record<TESTIMONIAL_COMPANIES, Testimonial> = {
  [TESTIMONIAL_COMPANIES.SAFE_BASE]: {
    companyLogo:
      'https://assets-global.website-files.com/653aafdfb322ad22b5d0ceea/654939fb53cec5c2ddb7311f_Vectors-Wrapper.svg',
    personName: 'Adar Arnon',
    personTitle: 'Co-founder & CTO',
    personImage:
      'https://uploads-ssl.webflow.com/62f681c18d50329187681754/660e2622d00c1601e26ca61d_1548377861209.jpeg',
    quote:
      'Our Explo dashboard is the first thing our users see when they visit the app, providing them with real-time visibility into the recent activity on their portal.',
  },
  [TESTIMONIAL_COMPANIES.TOGETHER]: {
    companyLogo:
      'https://assets-global.website-files.com/5ce11396d0cadb55a02cabf7/61f1ba84663ca23dbde758c9_logo.svg',
    personName: 'Nathan Goldstein',
    personTitle: 'Co-founder & Head of Product',
    personImage:
      'https://assets-global.website-files.com/62f681c18d50329187681754/65ce678b68592bef1f54f238_nathan.jpeg',
    quote:
      'Every app needs reporting these days and if your data is already in bigquery or a common database type, you can have a beta version out the same day [with Explo]. The Explo team is very responsive to our feature requests.',
  },
  [TESTIMONIAL_COMPANIES.SPEKIT]: {
    companyLogo:
      'https://assets-global.website-files.com/652972dbf17aca5ff350c363/65312dd6672b6884ca95f186_Spekit_Logo_ForLightBackgrounds_FullColor_Digital.svg',
    personName: 'Melanie Fellay',
    personTitle: 'Co-founder & CEO',
    personImage:
      'https://uploads-ssl.webflow.com/62f681c18d50329187681754/660e267015a0dadcfc97405d_1641995229545.jpeg',
    quote:
      'With Explo we are able to get the flexibility we need. We are able to get high fidelity and amazing looking dashboards that we can put together really fast at a cost that makes sense for us.',
  },
  [TESTIMONIAL_COMPANIES.CAJU]: {
    companyLogo:
      'https://assets-global.website-files.com/620135165cdb9f1d60b5d1e3/6244d22a2b78fe6d97518ce9_logo-rodape.svg',
    personName: 'Samuel Denis Cardoso da Silva',
    personTitle: 'Data Operations Analytics',
    personImage:
      'https://uploads-ssl.webflow.com/62f681c18d50329187681754/6606cd635baf757b43dce213_1659370093036-removebg-preview.png',
    quote:
      "Caju has managed to reduce requests for custom reports by over 95%. Prior to Explo, Caju had an average of 115 [monthly] report requests. In April, Caju received only 5 requests for custom reports. This significantly improves Caju's development team productivity",
  },
  [TESTIMONIAL_COMPANIES.NASH]: {
    companyLogo:
      'https://uploads-ssl.webflow.com/62f681c18d50329187681754/66a003027070a485ca9a7765_80432510efe3639eb8e61bcd007e1b4c211e0bd2.png',
    personName: 'Jerry Shen',
    personTitle: 'Head of Data',
    personImage:
      'https://uploads-ssl.webflow.com/62f681c18d50329187681754/66a003069d9db4f113fb3e87_image%20(249).png',
    quote:
      "The time and cost savings we've realized, alongside the overwhelmingly positive feedback from our customers, truly highlight the impact of Explo's solution on our analytics and customer experience strategies.",
  },
};

export const dottedLine = (
  <svg
    fill="none"
    height="317"
    style={{ position: 'absolute', top: 64, right: 0, maxWidth: '100%' }}
    viewBox="0 0 1440 317"
    width="1440"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1495 26H1291C1277.75 26 1267 36.7452 1267 50V125C1267 138.255 1256.25 149 1243 149H1098C1084.75 149 1074 159.745 1074 173V291.5C1074 304.755 1063.25 315.5 1050 315.5H191C177.745 315.5 167 304.755 167 291.5V25C167 11.7452 156.255 1 143 1H-351"
      stroke="#BDBDBD"
      strokeDasharray="0.1 8"
      strokeLinecap="round"
      strokeWidth="2.5"
    />
    <circle cx="1348" cy="26" fill="white" r="5" />
    <circle cx="1417" cy="26" fill="white" r="15" />
  </svg>
);
