import cx from 'classnames';
import { FC } from 'react';

import { HEADER_CLASS, TEXT_CONTENT_CLASS } from 'components/VersionComparisonModal/index.css';
import { ResourceItemComparisonComponent } from 'components/VersionComparisonModal/resourceItemComparisonComponent';
import { ModalSection, sprinkles } from 'components/ds';
import {
  ComparableResourceItemType,
  ResourceBaseInfo,
  VersionComparatorResult,
  VersionComparisonResultType,
} from 'utils/resourceVersionComparator';

interface Props {
  versionComparisonResult: VersionComparatorResult;
  setSelectedComparisonItemId: (resourceItemId: string) => void;
}

export const VersionComparisonItemsSection: FC<Props> = ({
  versionComparisonResult,
  setSelectedComparisonItemId,
}) => {
  const renderModalSection = (
    title: string,
    sectionItems: ResourceBaseInfo[],
    resultType: VersionComparisonResultType,
  ): JSX.Element | null => {
    if (sectionItems.length === 0) {
      // Hide the section if there are no items.
      return null;
    }

    return (
      <ModalSection headerClass={HEADER_CLASS} textContentClass={TEXT_CONTENT_CLASS} title={title}>
        {sectionItems.map((item) => {
          return (
            <ResourceItemComparisonComponent
              key={item.id}
              onViewVersionButtonClickedFn={setSelectedComparisonItemId}
              resource={item}
              shouldRenderDetailedComparisonButton={
                resultType === VersionComparisonResultType.MODIFIED &&
                (item.type === ComparableResourceItemType.DATASET ||
                  item.type === ComparableResourceItemType.REPORT_BUILDER_DATASET)
              }
            />
          );
        })}
      </ModalSection>
    );
  };

  const areAllSectionsEmpty =
    versionComparisonResult.addedItems.length === 0 &&
    versionComparisonResult.deletedItems.length === 0 &&
    versionComparisonResult.modifiedItems.length === 0;

  return (
    <>
      {renderModalSection(
        'Added Items',
        versionComparisonResult.addedItems,
        VersionComparisonResultType.ADDED,
      )}
      {renderModalSection(
        'Deleted Items',
        versionComparisonResult.deletedItems,
        VersionComparisonResultType.DELETED,
      )}
      {renderModalSection(
        'Modified Items',
        versionComparisonResult.modifiedItems,
        VersionComparisonResultType.MODIFIED,
      )}
      {areAllSectionsEmpty ? (
        <div className={cx(TEXT_CONTENT_CLASS, sprinkles({ margin: 'sp1' }))}>
          No changes between versions
        </div>
      ) : null}
    </>
  );
};
