import { Team } from 'actions/teamActions';
import { User } from 'actions/userActions';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { getEnvironment, isEnvironmentSecure } from 'utils/environmentUtils';
import { isReportBuilderEnabled } from 'utils/paymentPlanUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';

import { NAV_ITEMS } from './constants';

export const getOrderedSetupItems = (currentUser: User, teamData?: Team) => {
  const orderedSetupItems = [];

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.DATA_SOURCE],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.DATA);

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.CUSTOMER],
      PERMISSIONED_ACTIONS.READ,
    ) ||
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.END_USER_GROUP],
      PERMISSIONED_ACTIONS.READ,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.CUSTOMERS);

  if (
    doesUserHavePermission(
      currentUser.permissions[PERMISSIONED_ENTITIES.TEAM],
      PERMISSIONED_ACTIONS.UPDATE_CUSTOM_STYLES,
    )
  )
    orderedSetupItems.push(NAV_ITEMS.STYLES);

  if (
    (teamData?.internal_analytics_user_group_token || getEnvironment() === 'development') &&
    !isEnvironmentSecure()
  )
    orderedSetupItems.push(NAV_ITEMS.ANALYTICS);

  return orderedSetupItems;
};

export const getOrderedProductItems = (currentUser: User) => {
  const { permissions } = currentUser;
  const orderedProductItems = [];

  if (
    doesUserHavePermission(permissions[PERMISSIONED_ENTITIES.DASHBOARD], PERMISSIONED_ACTIONS.READ)
  ) {
    orderedProductItems.push(NAV_ITEMS.DASHBOARDS);
  }

  if (
    doesUserHavePermission(
      permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER],
      PERMISSIONED_ACTIONS.READ,
    )
  ) {
    const reportBuilderInfo = {
      ...NAV_ITEMS.REPORT_BUILDER,
      additionalInfo: isReportBuilderEnabled(currentUser) ? undefined : 'Upgrade',
    };
    orderedProductItems.push(reportBuilderInfo);
  }

  if (currentUser.is_superuser) orderedProductItems.push(NAV_ITEMS.SUPERUSER);
  return orderedProductItems;
};

export const getUserInitials = (currentUser: User) => {
  const firstInitial = currentUser.first_name
    ? currentUser.first_name.charAt(0).toUpperCase()
    : 'A';
  const secondInitial = currentUser.last_name.charAt(0).toUpperCase();

  return `${firstInitial}${secondInitial}`;
};
