import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  superuserGetTeams,
  superuserGetTeamUsers,
  superuserGetTeam,
  superuserRemoveUser,
  superuserUpdateTeam,
  superuserStartTrial,
  superuserExtendTrial,
  superuserUpdatePaymentPlan,
  superuserVerifyEmail,
} from 'actions/superuserActions';
import { AlertModal, Button, Input, Switch, Toggle, ToggleItem, Select } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { PLAN_TYPES, TRIAL_STATUS } from 'constants/paymentPlanConstants';
import { EnabledDataSourcesInput } from 'pages/SuperuserPage/EnabledDataSourcesInput';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';

import * as styles from './index.css';

const PLAN_SELECT_OPTIONS: SelectItems<string> = Object.values(PLAN_TYPES).map((plan) => ({
  value: plan,
}));

const AdminPage = () => {
  const dispatch = useDispatch();
  const superuser = useSelector((state: ReduxState) => state.superuser);

  const [view, setView] = useState<'team' | 'users'>('team');
  const [trialExtendDays, setTrialExtendDays] = useState<number>();
  const [paymentPlan, setPaymentPlan] = useState<{
    tier: PLAN_TYPES | undefined;
    maintain_trial_status: boolean;
  }>({ tier: undefined, maintain_trial_status: false });
  const [userToDelete, setUserToDelete] = useState<number>();
  const [emailToVerify, setEmailToVerify] = useState<string>();

  useEffect(() => {
    if (!RD.isSuccess(superuser.teams)) {
      dispatch(superuserGetTeams());
    }
  }, [superuser, dispatch]);

  useEffect(() => {
    if (RD.isSuccess(superuser.team) && paymentPlan.tier === undefined) {
      setPaymentPlan({
        tier: superuser.team.data.payment_plan,
        maintain_trial_status: superuser.team.data.trial_status === TRIAL_STATUS.IN_PROGRESS,
      });
    }
  }, [paymentPlan.tier, superuser.team]);

  const teamOptions = RD.getOrDefault(superuser.teams, []).map((team) => ({
    label: team.team_name,
    value: team.id.toString(),
  }));

  const renderHeader = () => {
    return (
      <div className={styles.container}>
        <h2>Welcome to Mission Control! </h2>
        <Select
          className={styles.dropdown}
          filterProps={{}}
          label="Select a Team"
          onChange={(id) => {
            const teamId = parseInt(id);
            dispatch(superuserGetTeam({ postData: { team_id: teamId } }));
            dispatch(superuserGetTeamUsers({ postData: { team_id: teamId } }));
          }}
          placeholder="Select a Team"
          selectedValue={
            RD.isSuccess(superuser.team) ? superuser.team.data.id.toString() : undefined
          }
          values={teamOptions}
        />
        <div className={styles.headerToggle}>
          <Toggle
            label=""
            // @ts-ignore
            onValueChange={setView}
            selectedValue={view}>
            <ToggleItem value="team">Team</ToggleItem>
            <ToggleItem value="users">Users</ToggleItem>
          </Toggle>
        </div>
      </div>
    );
  };

  const renderTeamView = () => {
    if (!RD.isSuccess(superuser.team)) return null;
    const teamData = superuser.team.data;

    const renderFlagSection = (
      key: string,
      value: boolean | number | string | string[],
      type: string,
    ) => {
      return (
        <div className={styles.item} key={key}>
          {key}
          {key === 'enabled_data_sources' ? (
            <EnabledDataSourcesInput
              enabledDataSources={value as string[]}
              onChange={(enabledDataSources) => {
                dispatch(
                  superuserUpdateTeam({
                    postData: {
                      team_id: teamData.id,
                      type,
                      name: key,
                      value: enabledDataSources,
                    },
                  }),
                );
              }}
            />
          ) : typeof value === 'boolean' ? (
            <Switch
              onChange={() =>
                dispatch(
                  superuserUpdateTeam({
                    postData: {
                      team_id: teamData.id,
                      type,
                      name: key,
                      value: !value,
                    },
                  }),
                )
              }
              switchOn={value}
            />
          ) : (
            <Input
              defaultValue={value?.toString() ?? ''}
              onSubmit={(newValue) =>
                dispatch(
                  superuserUpdateTeam({
                    postData: {
                      team_id: teamData.id,
                      type,
                      name: key,
                      value: newValue,
                    },
                  }),
                )
              }
            />
          )}
        </div>
      );
    };

    return (
      <>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => dispatch(superuserStartTrial({ postData: { team_id: teamData.id } }))}>
            Start Trial
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          <Input
            onSubmit={(newValue) => {
              const intValue = parseInt(newValue);

              if (isNaN(intValue)) setTrialExtendDays(undefined);
              else setTrialExtendDays(intValue);
            }}
          />
          <Button
            disabled={trialExtendDays === undefined}
            onClick={() =>
              trialExtendDays !== undefined
                ? dispatch(
                    superuserExtendTrial({
                      postData: { team_id: teamData.id, days: trialExtendDays },
                    }),
                  )
                : null
            }>
            Extend Trial
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          <Select
            className={styles.dropdown}
            contentWidth="auto"
            onChange={(tier) => setPaymentPlan({ ...paymentPlan, tier: tier as PLAN_TYPES })}
            placeholder="Select a Plan"
            selectedValue={paymentPlan.tier}
            values={PLAN_SELECT_OPTIONS}
          />
          <Switch
            label="Maintain Trial Status"
            onChange={() =>
              setPaymentPlan({
                ...paymentPlan,
                maintain_trial_status: !paymentPlan.maintain_trial_status,
              })
            }
            switchOn={paymentPlan.maintain_trial_status}
          />
          <Button
            disabled={paymentPlan.tier === undefined}
            onClick={() =>
              paymentPlan.tier !== undefined
                ? dispatch(
                    superuserUpdatePaymentPlan({
                      postData: {
                        team_id: teamData.id,
                        tier: paymentPlan.tier,
                        maintain_trial_status: paymentPlan.maintain_trial_status,
                      },
                    }),
                  )
                : null
            }>
            Update Payment Plan
          </Button>
        </div>
        <div className={styles.buttonContainer}>
          <Input onSubmit={(newValue) => setEmailToVerify(newValue)} />
          <Button
            disabled={emailToVerify === undefined}
            onClick={() =>
              emailToVerify !== undefined
                ? dispatch(
                    superuserVerifyEmail({
                      postData: { email: emailToVerify },
                    }),
                  )
                : null
            }>
            Verify Email
          </Button>
        </div>
        <div className={styles.instructions}>
          Look{' '}
          <a
            href="https://www.notion.so/explo/Superuser-Entitlements-Feature-Flags-Configurations-d9ba2e50bf0949ae9671754785f2c0e4"
            rel="noreferrer"
            target="_blank">
            here
          </a>{' '}
          for more information about the below entitlements, feature flags, & configurations.
        </div>
        <div className={styles.containerTitle}>Entitlements</div>
        {Object.entries(teamData.entitlements).map(([key, value]) =>
          renderFlagSection(key, value, 'entitlements'),
        )}
        <div className={styles.containerTitle}>Feature Flags</div>
        {Object.entries(teamData.feature_flags).map(([key, value]) =>
          renderFlagSection(key, value, 'feature_flags'),
        )}
        <div className={styles.containerTitle}>Configuration</div>
        {Object.entries(teamData.configuration).map(([key, value]) =>
          renderFlagSection(key, value, 'configuration'),
        )}
        {superuser.team.data.tenant_id ? (
          <>
            <div className={styles.containerTitle}>FIDO</div>
            <div className={styles.item}>
              Tenant Id <div>{superuser.team.data.tenant_id}</div>
            </div>
          </>
        ) : null}
      </>
    );
  };

  const renderUsersView = () => {
    if (!RD.isSuccess(superuser.users)) return null;
    const userData = superuser.users.data;

    return userData.map((user) => {
      return (
        <div className={styles.item} key={user.id}>
          <div className={styles.nameSection}>
            <p className={styles.memberName}>
              {user.first_name} {user.last_name}
            </p>
            <p className={styles.memberEmail}>{user.email}</p>
          </div>
          <Button onClick={() => setUserToDelete(user.id)}>Remove</Button>
        </div>
      );
    });
  };

  return (
    <div className={styles.root}>
      {renderHeader()}
      {view === 'team' ? renderTeamView() : renderUsersView()}
      {userToDelete ? (
        <AlertModal
          actionButtonProps={{
            text: 'Send it',
            onClick: () => {
              dispatch(superuserRemoveUser({ postData: { user_id: userToDelete } }));
              setUserToDelete(undefined);
            },
          }}
          cancelButtonProps={{ text: 'Cancel' }}
          isOpen={userToDelete !== undefined}
          onClose={() => setUserToDelete(undefined)}
          title="Are you sure you want to do this?"
        />
      ) : null}
    </div>
  );
};

export default AdminPage;
