import cx from 'classnames';
import Color from 'color';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { Link, sprinkles, Switch, InfoIcon } from 'components/ds';
import { PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { ReportBuilder } from 'pages/ReportBuilder/ReportBuilder';
import { TotalFooterExample } from 'pages/ReportBuilderEditor/Preview/TotalFooterExample';
import {
  getReportBuilderConfig,
  setTotalsOnByDefault,
  setShowTotals,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import {
  clearReportBuilderPreview,
  setUpReportBuilderPreview,
} from 'reducers/thunks/reportBuilderEditorThunks';
import { setIsPreview } from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { canUserModifyResource } from 'utils/permissionUtils';

import { EditorLeftColumn } from '../EditorLeftColumn';

import * as styles from './Preview.css';

export const ReportBuilderPreview: FC = () => {
  const dispatch = useDispatch();
  const { globalStyleConfig, reportBuilderConfig, permissions } = useSelector(
    (state: ReduxState) => ({
      globalStyleConfig: state.dashboardStyles.globalStyleConfig,
      reportBuilderConfig: getReportBuilderConfig(state.reportBuilderEdit),
      permissions: state.currentUser.permissions,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(setUpReportBuilderPreview());
    return () => {
      dispatch(clearReportBuilderPreview());
      dispatch(setIsPreview(false));
    };
  }, [dispatch]);

  const globalStyleVars = useMemo(
    () => getGlobalStyleVars(globalStyleConfig, { forReportBuilder: true }),
    [globalStyleConfig],
  );

  const settingLink = <Link to="/styles">Edit in custom styles</Link>;
  const backgroundColor = globalStyleConfig.base.actionColor.default;
  const backgroundColorObject = new Color(backgroundColor);

  const showTotals = reportBuilderConfig?.general?.showTotals;

  const renderLeftColumnHeader = () => (
    <div className={styles.infoText}>
      <p>
        This is a preview of your Report Builder&rsquo;s report editor. Your users will see the same
        view when creating and editing their own reports.
      </p>
      <p>
        Begin by clicking &quot;Select data&quot; or &quot;Edit data&quot;, add filters, aggregate
        data, and add views.
      </p>
      <p>
        You cannot export or save because changes made here won&rsquo;t affect your users and are
        purely for your own testing.
      </p>
    </div>
  );

  const renderLeftColumnBody = () => {
    if (!canUserModifyResource(permissions[PERMISSIONED_ENTITIES.REPORT_BUILDER])) return null;

    return (
      <>
        <div className={sprinkles({ body: 'b1', fontWeight: 700 })}>General</div>
        <div className={styles.settingSection}>
          <div className={styles.settingContainer}>
            <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
              <Switch
                onChange={(newValue) => dispatch(setShowTotals(newValue))}
                switchOn={showTotals}
              />
              Show table footer totals
              <InfoIcon text="Display aggregated values for each column or group in a pivot table" />
            </div>
            {showTotals ? (
              <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
                <Switch
                  onChange={(newValue) => dispatch(setTotalsOnByDefault(newValue))}
                  switchOn={reportBuilderConfig?.general?.totalsOnByDefault}
                />
                Expand table footer by default
                <InfoIcon text="If on, table footer will be expanded by default. Table footer can be toggled at any time by the user" />
              </div>
            ) : null}
            <TotalFooterExample />
          </div>
        </div>
        <div className={sprinkles({ body: 'b1', fontWeight: 700 })}>Styling</div>
        <div className={styles.settingSection}>
          <div className={styles.settingHeader}>
            <div className={styles.settingHeaderTitle}>Color</div>
            {settingLink}
          </div>
          <div className={styles.styleSettingContainer}>
            Actions
            <div
              className={cx(
                styles.colorSquare,
                sprinkles({
                  color: backgroundColorObject.isDark() ? 'white' : 'black',
                }),
              )}
              style={{ backgroundColor }}>
              {backgroundColor ?? 'Default'}
            </div>
          </div>
        </div>
        <div className={styles.settingSection}>
          <div className={styles.settingHeader}>
            <div className={styles.settingHeaderTitle}>Text</div>
            {settingLink}
          </div>
          <div className={styles.styleSettingContainer}>
            Font family
            <div
              className={cx(
                sprinkles({ fontWeight: 700 }),
                embedSprinkles({ body: 'primaryWithoutColor' }),
              )}>
              {globalStyleConfig.text.primaryFont ?? 'Default'}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.root}>
      <EditorLeftColumn>
        <div className={styles.leftCol}>
          {renderLeftColumnHeader()}
          {renderLeftColumnBody()}
        </div>
      </EditorLeftColumn>
      <div className={styles.previewContainer}>
        <div className={styles.sandboxBanner} style={{ height: 40, minHeight: 40 }}>
          This is a Preview of your Report Builder report editor. Changes made on this page
          won&rsquo;t be saved.
        </div>
        <div
          className={sprinkles({
            flex: 1,
            overflow: 'hidden',
            fontFamily: 'inherit',
            flexItems: 'column',
          })}
          style={globalStyleVars}>
          <ReportBuilder isIframe={false} />
        </div>
      </div>
    </div>
  );
};
