import produce from 'immer';
import { FC } from 'react';

import { CollapsibleMenu } from 'components/CollapsibleMenu';
import { sprinkles, Switch, Input } from 'components/ds';
import { CustomStylesCornerRadiusCategories } from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import LineSelect from 'shared/LineSelect';
import { capitalize } from 'utils/standard';

import ColorPicker from './CustomStylesColorPicker';
import { CustomizedTag } from './CustomizedTag';
import { ConfigSectionHeader } from './configSectionHeader';

type Props = {
  styleConfig: GlobalStyleConfig;
  updateConfig: (newConfig: GlobalStyleConfig) => void;
};

export const CardsConfigSection: FC<Props> = (props) => {
  return (
    <>
      <ConfigSectionHeader className={sprinkles({ marginTop: 'sp5' })} title="Cards" />
      <ConfigSectionHeader compact isSubTitle title="Fill and border" />
      <div className={sprinkles({ body: 'b3', marginBottom: 'sp.5' })}>Fill</div>
      <ColorPicker
        fill
        color={props.styleConfig.container.fill}
        onClose={(newColor) => {
          const newConfig = produce(props.styleConfig, (draft) => {
            draft.container.fill = newColor;
          });
          props.updateConfig(newConfig);
        }}
      />
      <ConfigSectionHeader className={sprinkles({ marginTop: 'sp3' })} title="Chart Tooltip" />
      <ColorPicker
        fill
        color={props.styleConfig.container.tooltip.backgroundColor}
        onClose={(newColor) => {
          const newConfig = produce(props.styleConfig, (draft) => {
            draft.container.tooltip.backgroundColor = newColor;
          });
          props.updateConfig(newConfig);
        }}
      />
      <ToggleRow {...props} configField="outline" />
      <ToggleRow {...props} configField="shadow" />
      <ConfigSectionHeader isSubTitle className={sprinkles({ marginTop: 'sp3' })} title="Padding" />
      <InputRow
        {...props}
        breakpoints={[10, 15, 20, 25, 30]}
        configField="padding"
        labels={['Narrow', 'Moderate', 'Wide']}
        minValue={0}
      />
      <ConfigSectionHeader
        isSubTitle
        className={sprinkles({ marginTop: 'sp3' })}
        title="Line Width"
      />
      <InputRow
        {...props}
        breakpoints={[1, 2, 3, 4, 5]}
        configField="lineWidth"
        labels={['Thin', 'Moderate', 'Thick']}
        maxValue={5}
        minValue={1}
      />
      <ConfigSectionHeader
        isSubTitle
        className={sprinkles({ marginTop: 'sp3' })}
        title="Corner Radius"
      />
      <InputRow
        {...props}
        breakpoints={[0, 4, 8, 12, 16]}
        configField="cornerRadius"
        labels={['Sharp', 'Moderate', 'Round']}
        minValue={0}
      />
      <CornerRadiusMenu {...props} />
    </>
  );
};

function ToggleRow({
  styleConfig,
  updateConfig,
  configField,
}: Props & { configField: 'outline' | 'shadow' }) {
  return (
    <div className={sprinkles({ marginY: 'sp2.5', flexItems: 'alignCenter', gap: 'sp1.5' })}>
      <div className={sprinkles({ flex: 1 })}>
        <Switch
          label={capitalize(configField)}
          onChange={() => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.container[configField].enabled = !styleConfig.container[configField].enabled;
            });
            updateConfig(newConfig);
          }}
          switchOn={styleConfig.container[configField].enabled}
        />
      </div>
      <div className={sprinkles({ flex: 1 })}>
        <ColorPicker
          fill
          color={
            styleConfig.container[configField].enabled
              ? styleConfig.container[configField].color
              : undefined
          }
          onClose={(newField) => {
            const newConfig = produce(styleConfig, (draft) => {
              draft.container[configField].color = newField;
            });
            updateConfig(newConfig);
          }}
        />
      </div>
      <Input
        defaultValue={`${
          configField === 'outline'
            ? styleConfig.container.outline.weight
            : styleConfig.container.shadow.size
        }px`}
        disabled={!styleConfig.container[configField].enabled}
        onSubmit={(newField) => {
          const newValue = Number(newField.replace('px', ''));
          let newConfig = styleConfig;
          if (isNaN(newValue) || newValue < 1) return;
          if (configField === 'outline') {
            newConfig = produce(styleConfig, (draft) => {
              draft.container.outline.weight = newValue;
            });
          } else {
            newConfig = produce(styleConfig, (draft) => {
              draft.container.shadow.size = newValue;
            });
          }
          updateConfig(newConfig);
        }}
        style={{ width: 55 }}
      />
    </div>
  );
}

function InputRow({
  styleConfig,
  updateConfig,
  configField,
  labels,
  breakpoints,
  minValue,
  maxValue,
}: Props & {
  configField: 'padding' | 'cornerRadius' | 'lineWidth';
  labels: string[];
  breakpoints: number[];
  minValue: number;
  maxValue?: number;
}) {
  return (
    <div className={sprinkles({ display: 'flex', alignItems: 'flex-end', gap: 'sp1.5' })}>
      <Input
        defaultValue={`${styleConfig.container[configField].default}px`}
        onSubmit={(newValueString) => {
          const newValue = Number(newValueString.replace('px', ''));

          if (isNaN(newValue) || newValue < minValue || (maxValue && newValue > maxValue)) return;

          const newConfig = produce(styleConfig, (draft) => {
            draft.container[configField].default = newValue;
          });
          updateConfig(newConfig);
        }}
        style={{ width: 55 }}
      />
      <LineSelect
        fill
        breakpoints={breakpoints}
        className={sprinkles({ flex: 1 })}
        labels={labels}
        setValue={(newValue) => {
          const newConfig = produce(styleConfig, (draft) => {
            draft.container[configField].default = newValue;
          });
          updateConfig(newConfig);
        }}
        value={styleConfig.container[configField].default}
      />
    </div>
  );
}

function CornerRadiusMenu({ styleConfig, updateConfig }: Props) {
  return (
    <CollapsibleMenu className={sprinkles({ marginTop: 'sp2' })} title="Custom corner radiuses">
      {Object.values(CustomStylesCornerRadiusCategories).map((config) => {
        return (
          <div className="" key={config}>
            <div
              className={sprinkles({ body: 'b3', marginBottom: 'sp.5', flexItems: 'alignCenter' })}>
              <div>{config === 'buttons' ? 'Buttons' : 'Input Fields'}</div>
              <CustomizedTag
                hidden={styleConfig.container.cornerRadius[config] === undefined}
                onCancel={() => {
                  const newConfig = produce(styleConfig, (draft) => {
                    draft.container.cornerRadius[config] = undefined;
                  });
                  updateConfig(newConfig);
                }}
              />
            </div>
            <Input
              defaultValue={`${
                styleConfig.container.cornerRadius[config] ??
                styleConfig.container.cornerRadius.default
              }px`}
              onSubmit={(newButtonCornerRadiusString) => {
                const newButtonCornerRadius = Number(newButtonCornerRadiusString.replace('px', ''));

                if (isNaN(newButtonCornerRadius) || newButtonCornerRadius < 0) return;

                const newConfig = produce(styleConfig, (draft) => {
                  draft.container.cornerRadius[config] = newButtonCornerRadius;
                });
                updateConfig(newConfig);
              }}
            />
          </div>
        );
      })}
    </CollapsibleMenu>
  );
}
