import produce from 'immer';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateVisualizeOperation } from 'actions/dataPanelConfigActions';
import { ColorPickerButton } from 'components/ColorPickerButton';
import { Input, Label, Switch } from 'components/ds';
import { KPITrendDisplayFormat, OPERATION_TYPES, V2KPITrendInstructions } from 'constants/types';
import { getCategoricalColors } from 'globalStyles';
import { configInputClass, configRootClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';
import { isNumberTrendTextPanelVisualizationType } from 'pages/dashboardPage/charts/utils/trendUtils';
import { ReduxState } from 'reducers/rootReducer';

type Props = {
  visualizationType: OPERATION_TYPES;
  instructions: V2KPITrendInstructions;
};

export const KPITrendTrendConfig: FC<Props> = ({ visualizationType, instructions }) => {
  const dispatch = useDispatch();
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const displayFormat = instructions.displayFormat;
  const defaultDecimalPlaces = displayFormat?.showAbsoluteChange ? 0 : 1;

  const updateDisplayFormat = (updates: KPITrendDisplayFormat): void => {
    const newInstructions = produce(instructions, (draft) => {
      draft.displayFormat = { ...draft.displayFormat, ...updates };
    });
    dispatch(updateVisualizeOperation(newInstructions, visualizationType));
  };

  // Some options won't work with the Charts version of the KPI Trend panel so hide them when the trend lines are being shown.
  // TODO: Migrate the chart w/ trend lines enabled to use these options
  // https://linear.app/explo/issue/PD-934/migrate-kpi-trend-w-lines-enabled-to-use-trend-change-options
  return (
    <div className={configRootClass}>
      {isNumberTrendTextPanelVisualizationType(visualizationType, instructions) ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={String(displayFormat?.trendDecimalPlaces ?? defaultDecimalPlaces)}
            label="Trend Decimal Places"
            onSubmit={(newValue) => {
              const intValue = parseInt(newValue);
              updateDisplayFormat({ trendDecimalPlaces: intValue > 0 ? intValue : 0 });
            }}
          />
          <Switch
            className={configInputClass}
            label="Show Absolute Trend Change"
            onChange={() =>
              updateDisplayFormat({ showAbsoluteChange: !displayFormat?.showAbsoluteChange })
            }
            switchOn={displayFormat?.showAbsoluteChange}
          />
          <Switch
            className={configInputClass}
            label="Show Trend Change Period Label"
            onChange={() =>
              updateDisplayFormat({
                showTrendChangePeriodLabel: !displayFormat?.showTrendChangePeriodLabel,
              })
            }
            switchOn={displayFormat?.showTrendChangePeriodLabel}
          />
          <Switch
            className={configInputClass}
            label="Use Trend Tag Format"
            onChange={() => updateDisplayFormat({ useTrendTag: !displayFormat?.useTrendTag })}
            switchOn={displayFormat?.useTrendTag}
          />
        </>
      ) : (
        <>
          <div className={configInputClass}>
            <Label htmlFor="">Main Trend Color</Label>
            <ColorPickerButton
              fillWidth
              color={displayFormat?.periodColor || getCategoricalColors(globalStyleConfig)[0]}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor) => updateDisplayFormat({ periodColor: newColor })}
            />
          </div>
          <div className={configInputClass}>
            <Label htmlFor="">Comparison Trend Color</Label>
            <ColorPickerButton
              fillWidth
              color={displayFormat?.comparisonColor || '#757575'}
              colorPalette={getCategoricalColors(globalStyleConfig)}
              onColorChange={(newColor) => updateDisplayFormat({ comparisonColor: newColor })}
            />
          </div>
        </>
      )}
      <Switch
        className={configInputClass}
        label="Reverse Trend Label Colors"
        onChange={() =>
          updateDisplayFormat({ trendColorsReversed: !displayFormat?.trendColorsReversed })
        }
        switchOn={displayFormat?.trendColorsReversed}
      />
      {visualizationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL ? (
        <Switch
          className={configInputClass}
          label="Show Previous Value"
          onChange={() =>
            updateDisplayFormat({ showPreviousValue: !displayFormat?.showPreviousValue })
          }
          switchOn={displayFormat?.showPreviousValue}
        />
      ) : null}
    </div>
  );
};
