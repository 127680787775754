import { Dataset } from 'actions/datasetActions';
import {
  BuiltInReportConfig,
  ReportBuilderConfig,
  ReportBuilderDataset,
} from 'actions/reportBuilderConfigActions';
import { ReportBuilderVersion } from 'actions/reportBuilderVersionActions';
import { DASHBOARD_ELEMENT_TYPE_TO_NAME } from 'constants/dashboardConstants';
import { VIZ_TO_NAME } from 'constants/dataConstants';
import { DashboardElement } from 'types/dashboardTypes';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { isEqual } from 'utils/standard';

import { deepMapKeysAndValues, ValueType } from './objectUtils';

const DATASET_IGNORED_FIELDS = new Set(['fido_id']);

export enum ComparableResourceItemType {
  DATA_PANEL_TEMPLATE,
  DASHBOARD_ELEMENT,
  DATASET,
  REPORT_BUILDER_DATASET,
  BUILT_IN_REPORTS,
}

export interface ResourceBaseInfo {
  id: string;
  type: ComparableResourceItemType;
  typePrefix: string;
  name: string;
}

export enum VersionComparisonResultType {
  ADDED,
  DELETED,
  MODIFIED,
}

export interface VersionComparatorResult {
  addedItems: ResourceBaseInfo[];
  deletedItems: ResourceBaseInfo[];
  modifiedItems: ResourceBaseInfo[];
}

export const compareDashboardVersions = (
  previousVersion: DashboardVersion,
  currentVersion: DashboardVersion,
): VersionComparatorResult => {
  const previousVersionConfig: DashboardVersionConfig = previousVersion.configuration;
  const currentVersionConfig: DashboardVersionConfig = currentVersion.configuration;
  const dataPanelResult = compareResourceItems(
    previousVersionConfig.data_panels,
    currentVersionConfig.data_panels,
    ComparableResourceItemType.DATA_PANEL_TEMPLATE,
  );
  const elementResult = compareResourceItems(
    previousVersionConfig.elements,
    currentVersionConfig.elements,
    ComparableResourceItemType.DASHBOARD_ELEMENT,
  );
  const previousDatasets = previousVersionConfig.datasets ?? {};
  // Transform datasets by stripping out ignored fields prior to comparison.
  const transformDatasetFn = (dataset: Dataset) => {
    return deepMapKeysAndValues(
      dataset as unknown as Record<string, ValueType>,
      (key) => key,
      (key, value) => (DATASET_IGNORED_FIELDS.has(key) ? null : value),
    );
  };
  const transformedPreviousDatasets = Object.values(previousDatasets).map(
    transformDatasetFn,
  ) as unknown as Record<string, Dataset>;
  const currentDatasets = currentVersionConfig.datasets ?? {};
  const transformedCurrentDatasets = Object.values(currentDatasets).map(
    transformDatasetFn,
  ) as unknown as Record<string, Dataset>;
  const datasetResult = compareResourceItems(
    transformedPreviousDatasets,
    transformedCurrentDatasets,
    ComparableResourceItemType.DATASET,
  );

  return {
    addedItems: concatAndSortItems(
      dataPanelResult.addedItems,
      elementResult.addedItems,
      datasetResult.addedItems,
    ),
    deletedItems: concatAndSortItems(
      dataPanelResult.deletedItems,
      elementResult.deletedItems,
      datasetResult.deletedItems,
    ),
    modifiedItems: concatAndSortItems(
      dataPanelResult.modifiedItems,
      elementResult.modifiedItems,
      datasetResult.modifiedItems,
    ),
  };
};

export const compareReportBuilderVersions = (
  previousVersion: ReportBuilderVersion,
  currentVersion: ReportBuilderVersion,
): VersionComparatorResult => {
  const previousVersionConfig: ReportBuilderConfig = previousVersion.config;
  const currentVersionConfig: ReportBuilderConfig = currentVersion.config;
  const datasetResult = compareResourceItems(
    previousVersionConfig.datasets,
    currentVersionConfig.datasets,
    ComparableResourceItemType.REPORT_BUILDER_DATASET,
  );
  const builtInReportResult = compareResourceItems(
    previousVersionConfig.builtInReports ?? {},
    currentVersionConfig.builtInReports ?? {},
    ComparableResourceItemType.BUILT_IN_REPORTS,
  );

  return {
    addedItems: concatAndSortItems(datasetResult.addedItems, builtInReportResult.addedItems),
    deletedItems: concatAndSortItems(datasetResult.deletedItems, builtInReportResult.deletedItems),
    modifiedItems: concatAndSortItems(
      datasetResult.modifiedItems,
      builtInReportResult.modifiedItems,
    ),
  };
};

const concatAndSortItems = (...items: ResourceBaseInfo[][]): ResourceBaseInfo[] => {
  let concatenatedItems: ResourceBaseInfo[] = [];
  concatenatedItems = concatenatedItems.concat(...items);
  concatenatedItems.sort((firstItem, secondItem) =>
    firstItem.typePrefix.localeCompare(secondItem.typePrefix),
  );
  return concatenatedItems;
};

type ComparableResourceItem =
  | DataPanelTemplate
  | DashboardElement
  | Dataset
  | BuiltInReportConfig
  | ReportBuilderDataset;

const compareResourceItems = (
  previousVersionItems: Record<string, ComparableResourceItem>,
  currentVersionItems: Record<string, ComparableResourceItem>,
  resourceType: ComparableResourceItemType,
): VersionComparatorResult => {
  const addedItems: ResourceBaseInfo[] = [];
  const deletedItems: ResourceBaseInfo[] = [];
  const modifiedItems: ResourceBaseInfo[] = [];

  for (const [itemId, item] of Object.entries(currentVersionItems)) {
    const resourceBaseInfo = createResourceBaseInfo(item, resourceType);
    if (!previousVersionItems[itemId]) {
      addedItems.push(resourceBaseInfo);
    } else if (!isEqual(item, previousVersionItems[itemId])) {
      modifiedItems.push(resourceBaseInfo);
    }
  }

  // Iterate through the previous version items to find deleted items.
  for (const [itemId, item] of Object.entries(previousVersionItems)) {
    if (!currentVersionItems[itemId]) {
      deletedItems.push(createResourceBaseInfo(item, resourceType));
    }
  }

  return { addedItems, deletedItems, modifiedItems };
};

const createResourceBaseInfo = (
  item: ComparableResourceItem,
  type: ComparableResourceItemType,
): ResourceBaseInfo => {
  let name = '';
  let typePrefix = '';
  switch (type) {
    case ComparableResourceItemType.DATA_PANEL_TEMPLATE: {
      const dataPanel = item as DataPanelTemplate;
      typePrefix = VIZ_TO_NAME[dataPanel.visualize_op.operation_type] ?? DEFAULT_DATA_PANEL_PREFIX;
      name =
        dataPanel.visualize_op.generalFormatOptions?.headerConfig?.title || dataPanel.provided_id;
      break;
    }
    case ComparableResourceItemType.DASHBOARD_ELEMENT: {
      const dashboardElement = item as DashboardElement;
      typePrefix =
        DASHBOARD_ELEMENT_TYPE_TO_NAME[dashboardElement.element_type] ??
        DEFAULT_DASHBOARD_ELEMENT_PREFIX;
      name = dashboardElement.name;
      break;
    }
    case ComparableResourceItemType.DATASET: {
      const dataset = item as Dataset;
      typePrefix = 'Dataset';
      name = dataset.table_name;
      break;
    }
    case ComparableResourceItemType.REPORT_BUILDER_DATASET: {
      const reportBuilderDataset = item as ReportBuilderDataset;
      typePrefix = 'Dataset';
      name = reportBuilderDataset.name;
      break;
    }
    case ComparableResourceItemType.BUILT_IN_REPORTS: {
      const builtInReport = item as BuiltInReportConfig;
      typePrefix = 'Built in Report';
      name = builtInReport.name;
      break;
    }
    default:
      throw new Error('Invalid type');
  }

  return {
    id: item.id,
    type,
    typePrefix,
    name,
  };
};

const DEFAULT_DATA_PANEL_PREFIX = 'Data panel';

const DEFAULT_DASHBOARD_ELEMENT_PREFIX = 'Dashboard element';
