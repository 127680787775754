import produce, { Draft } from 'immer';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Input, sprinkles } from 'components/ds';
import { setVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import { DashboardElement, SliderElementConfig, SliderElementValue } from 'types/dashboardTypes';
import { convertArrayToSliderElementValue } from 'utils/sliderUtils';
import { clamp } from 'utils/standard';

interface Props {
  config: SliderElementConfig;
  dashboardElement: DashboardElement;

  updateSliderConfig: (config: SliderElementConfig) => void;
}

export const SliderValuesConfig: FC<Props> = ({ config, dashboardElement, updateSliderConfig }) => {
  const dispatch = useDispatch();

  const setValue = useCallback(
    (newValue: SliderElementValue) => {
      dispatch(
        setVariableThunk({
          varName: dashboardElement.name,
          value: newValue,
          elementId: dashboardElement.id,
        }),
      );
    },
    [dashboardElement.id, dashboardElement.name, dispatch],
  );

  const handleChangeToNumberConfig = useCallback(
    (
      newValue: string,
      updateConfig: (config: Draft<SliderElementConfig>, newValue: number) => void,
    ) => {
      const parsed = Number(newValue);
      if (isNaN(parsed)) {
        return;
      }
      updateSliderConfig(
        produce(config, (draft) => {
          updateConfig(draft, parsed);
        }),
      );
    },
    [config, updateSliderConfig],
  );

  const onChangeMin = useCallback(
    (newMin: string) => {
      updateSliderConfig(
        produce(config, (draft) => {
          draft.min = newMin;
        }),
      );
    },
    [config, updateSliderConfig],
  );

  const onChangeMax = useCallback(
    (newMax: string) => {
      updateSliderConfig(
        produce(config, (draft) => {
          draft.max = newMax;
        }),
      );
    },
    [config, updateSliderConfig],
  );

  const onChangeNumThumbs = useCallback(
    (newValueStr: string) => {
      handleChangeToNumberConfig(newValueStr, (draft, newValue) => {
        draft.numThumbs = clampNumThumbs(newValue);

        const newValues = convertArrayToSliderElementValue(Array(newValue).fill(0));
        draft.defaultValue = newValues;
        setValue(newValues);
      });
    },
    [handleChangeToNumberConfig, setValue],
  );

  const onChangeStepSize = useCallback(
    (newValueStr: string) => {
      handleChangeToNumberConfig(newValueStr, (draft, newStepSize) => {
        draft.stepSize = newStepSize;
      });
    },
    [handleChangeToNumberConfig],
  );

  return (
    <div className={sprinkles({ display: 'flex', flexDirection: 'column', gap: 'sp1' })}>
      <Input
        label={{ text: 'Minimum value', variableInput: true }}
        onChange={onChangeMin}
        value={String(config.min)}
      />
      <Input
        label={{ text: 'Maximum value', variableInput: true }}
        onChange={onChangeMax}
        value={String(config.max)}
      />
      <Input
        label="Number of thumbs"
        onChange={onChangeNumThumbs}
        type="number"
        useValuePropOnly={false}
        value={String(clampNumThumbs(config.numThumbs))}
      />
      <Input
        label="Step size"
        onChange={onChangeStepSize}
        type="number"
        useValuePropOnly={false}
        value={String(config.stepSize)}
      />
    </div>
  );
};

const MAX_NUM_THUMBS = 10;
function clampNumThumbs(numThumbs: number) {
  return Math.round(clamp(numThumbs, 1, MAX_NUM_THUMBS));
}
