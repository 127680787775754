import { forwardRef } from 'react';

import { Button, Menu, MenuActionItem, sprinkles } from 'components/ds';
import { ButtonProps } from 'components/ds/Button';
import { Icon, IconName } from 'components/ds/Icon';

type ActionItem = {
  label: string;
  icon?: IconName;
  value: string;
  disabled?: boolean;
  onClick?: () => void;
};

export type Props = Omit<ButtonProps, 'children'> & {
  children?: never;
  actions: ActionItem[];
  label: string;
  icon?: IconName;
};

export const MenuButton = forwardRef<HTMLButtonElement, Props>(
  ({ variant, actions, label, icon, ...props }, ref) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Menu
          align="start"
          alignOffset={-3}
          trigger={
            <Button
              {...props}
              icon={icon}
              onClick={(e) => {
                e.stopPropagation();
              }}
              ref={ref}
              style={{ paddingRight: 0 }}
              variant={variant ?? 'primary'}>
              {label}
              <div className={iconClass}>
                <Icon name="caret-down" size="md" />
              </div>
            </Button>
          }>
          {actions.map((action) => (
            <MenuActionItem
              disabled={action.disabled}
              iconName={action.icon}
              key={action.value}
              onSelect={action.onClick}
              text={action.label}
            />
          ))}
        </Menu>
      </div>
    );
  },
);

MenuButton.displayName = 'MenuButton';

const iconClass = sprinkles({
  height: 'fill',
  flexItems: 'center',
  paddingX: 'sp1',
});
