import { FC, useState, MouseEvent as ReactMouseEvent } from 'react';

import { Button, Icon, IconButton, NavigationToggle, sprinkles } from 'components/ds';

import * as styles from './index.css';

export enum ViewType {
  List,
  Card,
}

type PrimaryActionProps = {
  disabled?: boolean;
  id?: string;
  text: string;
  to?: string;
  tooltipText?: string;
  onClick?: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeSearchOnClick?: boolean;
};

type SecondaryActionProps = {
  text: string;
  onClick: (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
  closeSearchOnClick?: boolean;
};

type Props = {
  pageTitle: string | JSX.Element;
  currentView?: ViewType;
  primaryActionProps?: PrimaryActionProps;
  secondaryActionProps?: SecondaryActionProps;
  searchBarPlaceholderText: string;
  searchBarOverride?: JSX.Element;
  searchBarSubmit: (searchText: string) => void;
  toggleView?: (view: ViewType) => void;
  heading?: 'h2' | 'h3';
};

export const PageHeader: FC<Props> = ({
  pageTitle,
  currentView,
  primaryActionProps,
  secondaryActionProps,
  searchBarPlaceholderText,
  searchBarOverride,
  searchBarSubmit,
  toggleView,
  heading = 'h2',
}) => {
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);

  const renderSearchBar = () => {
    if (isSearchBarExpanded) {
      return (
        <div className={styles.openSearchBar}>
          <Icon className={sprinkles({ color: 'active' })} name="search" />
          <input
            autoFocus
            className={styles.input}
            onChange={(event) => {
              searchBarSubmit(event.currentTarget.value);
            }}
            placeholder={searchBarPlaceholderText || 'Search'}
          />
          <IconButton
            className={styles.cross}
            name="cross"
            onClick={() => {
              setIsSearchBarExpanded(false);
              searchBarSubmit('');
            }}
            variant="tertiary"
          />
        </div>
      );
    }
    return (
      <IconButton name="search" onClick={() => setIsSearchBarExpanded(true)} variant="primary" />
    );
  };

  return (
    <div className={styles.headerContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.nameSearch}>
          <div
            className={sprinkles({
              heading,
              display: 'flex',
              width: 'fill',
              overflow: 'hidden',
            })}>
            {pageTitle}
          </div>
          {searchBarOverride ? searchBarOverride : renderSearchBar()}
        </div>
        <div className={styles.buttonContainer}>
          {toggleView ? (
            <NavigationToggle
              valueRequired
              defaultValue={currentView === ViewType.List ? 'list' : 'grid'}
              items={[
                { iconName: 'grid2', value: 'grid' },
                { iconName: 'list', value: 'list' },
              ]}
              onValueChange={(value) =>
                toggleView?.(value === 'grid' ? ViewType.Card : ViewType.List)
              }
              type="single"
            />
          ) : null}
          {secondaryActionProps ? (
            <Button
              onClick={(value) => {
                if (secondaryActionProps.closeSearchOnClick) {
                  setIsSearchBarExpanded(false);
                  searchBarSubmit('');
                }
                secondaryActionProps.onClick?.(value);
              }}
              variant="secondary">
              {secondaryActionProps.text}
            </Button>
          ) : null}
          {primaryActionProps && primaryActionProps?.onClick ? (
            <Button
              disabled={primaryActionProps.disabled}
              onClick={(value) => {
                if (primaryActionProps.closeSearchOnClick) {
                  setIsSearchBarExpanded(false);
                  searchBarSubmit('');
                }
                primaryActionProps.onClick?.(value);
              }}
              tooltipProps={
                primaryActionProps.tooltipText
                  ? {
                      text: primaryActionProps.tooltipText,
                    }
                  : undefined
              }
              variant="primary">
              {primaryActionProps.text}
            </Button>
          ) : primaryActionProps && primaryActionProps.to ? (
            <Button
              to={primaryActionProps.to}
              tooltipProps={
                primaryActionProps.tooltipText
                  ? {
                      text: primaryActionProps.tooltipText,
                    }
                  : undefined
              }
              variant="primary">
              {primaryActionProps.text}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
