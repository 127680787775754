import * as Dialog from '@radix-ui/react-dialog';
import { usePrevious } from '@radix-ui/react-use-previous';
import { FC, useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { ParentSchema } from 'actions/dataSourceActions';
import { Dataset, DatasetData } from 'actions/datasetActions';
import { sprinkles, Tabs } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DatasetSchema } from 'types/datasets';

import { DatasetFormattingTab } from './DatasetFormattingTab';
import { DatasetMetadataPanel } from './DatasetMetadataPanel';
import { DatasetQueryPanels } from './DatasetQueryPanels';
import { DatasetSelectTopBar } from './DatasetSelectTopBar';
import { DATASET_VIEWS, ModalStatus } from './constants';
import { DeleteDatasetModal } from './modals/DeleteDatasetModal';
import { EditDatasetModal } from './modals/EditDatasetModal';
import * as sharedStyles from './styles.css';

const DatasetViewTabs = Object.values(DATASET_VIEWS);

type Props = {
  activeQuery: string;
  activeDatasetData: DatasetData | null;
  activeDatasetConfig: Dataset | null;
  activeDatasetSchema: DatasetSchema | null;
  // This is the saved schema which should be used for drilldown configuration
  activeDatasetSavedSchema: DatasetSchema | null;
  datasetConfigs: Record<string, Dataset>;
  fetchData: (query: string, pageNumber?: number) => void;
  onSave: (query: string) => void;
  onSaveDraft: (query: string | undefined) => void;
  onSelectSchema: (schemaId: number) => void;
  parentSchemas: ParentSchema[];
  selectedDatasetId: string | null;
  setSelectedDatasetId: (datasetId: string | null) => void;
};

export const DashboardDatasetEditor: FC<Props> = ({
  activeQuery,
  activeDatasetData,
  activeDatasetConfig,
  activeDatasetSchema,
  activeDatasetSavedSchema,
  datasetConfigs,
  fetchData,
  onSave,
  onSaveDraft,
  onSelectSchema,
  selectedDatasetId,
  setSelectedDatasetId,
}) => {
  const { currentDashboard, isDrilldownPreview } = useSelector(
    (state: ReduxState) => ({
      currentDashboard: state.dashboard.currentDashboard,
      isDrilldownPreview: state.dashboardInteractions.isDrilldownPreview,
    }),
    shallowEqual,
  );

  const [editorView, setEditorView] = useState(
    isDrilldownPreview ? DATASET_VIEWS.FORMATTING : DATASET_VIEWS.QUERY,
  );
  const [currentQuery, setCurrentQuery] = useState(activeQuery);
  const [modalStatus, setModalStatus] = useState(ModalStatus.CLOSED);

  const prevActiveQuery = usePrevious(activeQuery);
  useEffect(() => {
    if (prevActiveQuery !== activeQuery) {
      setCurrentQuery(activeQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuery]);

  const renderMetadataPanel = () => {
    if (!selectedDatasetId || !activeDatasetConfig) return;

    return (
      <DatasetMetadataPanel
        dashboardId={RD.isSuccess(currentDashboard) ? currentDashboard.data.id : undefined}
        error={activeDatasetData?.error}
        onSelectSchema={onSelectSchema}
        query={activeQuery}
        selectedDatasetId={activeDatasetConfig.parent_schema_id}
      />
    );
  };

  const renderDatasetEditor = () => (
    <div className={datasetEditorContainerClass}>
      <DatasetSelectTopBar
        datasetConfigs={datasetConfigs}
        selectedDatasetId={selectedDatasetId}
        setModalStatus={setModalStatus}
        setSelectedDatasetId={setSelectedDatasetId}
      />
      <div className={editorMenuContainerClass} style={{ minHeight: 48 }}>
        <Tabs
          className={sharedStyles.datasetEditorTabs}
          onTabSelect={(tabId) => openTab(tabId)}
          selectedTabId={editorView}
          tabs={DatasetViewTabs}
        />
      </div>
      {editorView === DATASET_VIEWS.QUERY
        ? renderDatasetQueryEditor()
        : renderDatasetFormattingBody()}
    </div>
  );

  const openTab = (tabId: string) => setEditorView(tabId as DATASET_VIEWS);

  const renderDatasetQueryEditor = () => {
    return (
      <div className={queryEditorContainerClass}>
        <DatasetQueryPanels
          activeDatasetConfig={activeDatasetConfig}
          activeDatasetData={activeDatasetData}
          activeDatasetSchema={activeDatasetSchema}
          activeQuery={activeQuery}
          currentQuery={currentQuery}
          fetchData={fetchData}
          onSave={onSave}
          onSaveDraft={onSaveDraft}
          selectedDatasetId={selectedDatasetId}
          setCurrentQuery={setCurrentQuery}
        />
        {renderMetadataPanel()}
      </div>
    );
  };

  const renderDatasetFormattingBody = () => {
    if (!selectedDatasetId) return;

    return (
      <DatasetFormattingTab
        activeDatasetConfig={activeDatasetConfig}
        activeDatasetData={activeDatasetData}
        activeDatasetSavedSchema={activeDatasetSavedSchema}
        activeQuery={activeQuery}
        currentQuery={currentQuery}
        fetchData={fetchData}
        openTab={openTab}
      />
    );
  };

  return (
    <Dialog.Root modal open>
      <Dialog.Content
        className={sprinkles({ parentContainer: 'fill' })}
        onInteractOutside={() => setSelectedDatasetId(null)}>
        <>
          {renderDatasetEditor()}
          {modalStatus === ModalStatus.DELETE_DATASET ? (
            <DeleteDatasetModal
              datasetConfigs={datasetConfigs}
              deleteDatasetId={selectedDatasetId}
              onClose={() => setModalStatus(ModalStatus.CLOSED)}
            />
          ) : null}
          {modalStatus === ModalStatus.EDIT_DATASET ? (
            <EditDatasetModal
              datasetConfigs={datasetConfigs}
              editDatasetId={selectedDatasetId}
              onClose={() => setModalStatus(ModalStatus.CLOSED)}
            />
          ) : null}
        </>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const editorMenuContainerClass = sprinkles({
  backgroundColor: 'white',
  paddingRight: 'sp1.5',
  overflow: 'hidden',
  borderBottom: 1,
  borderColor: 'outline',
  width: 'fill',
});

const queryEditorContainerClass = sprinkles({
  flexItems: 'alignCenter',
  parentContainer: 'fill',
  padding: 'sp2',
  gap: 'sp2',
  overflow: 'hidden',
});

const datasetEditorContainerClass = sprinkles({
  parentContainer: 'fill',
  flexItems: 'column',
  backgroundColor: 'white',
  boxShadow: 'md',
  borderRadiusLeft: 8,
  flex: 1,
});
