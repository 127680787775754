import produce from 'immer';
import { FC } from 'react';

import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Switch, Input } from 'components/ds';
import { BOOLEAN, DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { PieChartFormat, V2TwoDimensionChartInstructions } from 'constants/types';
import { DateFormatDescriptiveText } from 'pages/dashboardPage/DataPanelConfig/FormatConfigTab/DateFormatDescriptiveText';

type Props = {
  configInputClass: string;
  instructions: V2TwoDimensionChartInstructions;

  updateInstructions: (instructions: V2TwoDimensionChartInstructions) => void;
};

export const SharedPieChartConfigs: FC<Props> = ({
  configInputClass,
  instructions,
  updateInstructions,
}) => {
  const { chartSpecificFormat, categoryColumn } = instructions;
  const { pieChart } = chartSpecificFormat ?? {};
  const hideChartValues = pieChart?.hideChartValues ?? false;
  const showLabelsInsideSlices = pieChart?.showLabelsInsideSlices ?? false;
  const useColorForLabel = pieChart?.useColorForLabel ?? false;
  const selectedFormat = pieChart?.valuesFormat || V2_NUMBER_FORMATS.PERCENT;
  const useStandardPieSize = pieChart?.useStandardPieSize ?? false;

  const updatePieChartFormat = (pieChartUpdates: PieChartFormat) => {
    const newInstructions = produce(instructions, (draft) => {
      draft.chartSpecificFormat = {
        ...draft.chartSpecificFormat,
        pieChart: { ...draft.chartSpecificFormat?.pieChart, ...pieChartUpdates },
      };
    });
    updateInstructions(newInstructions);
  };

  return (
    <>
      <Switch
        className={configInputClass}
        label="Show Value Labels"
        onChange={() => updatePieChartFormat({ hideChartValues: !hideChartValues })}
        switchOn={!hideChartValues}
      />
      {!hideChartValues && (
        <>
          <Switch
            className={configInputClass}
            label="Show Labels Inside Slices"
            onChange={() =>
              updatePieChartFormat({ showLabelsInsideSlices: !showLabelsInsideSlices })
            }
            switchOn={showLabelsInsideSlices}
          />
          <NumberFormatToggle
            disableAbbr
            disableTime
            className={configInputClass}
            label="Value Format"
            selectedFormat={selectedFormat.id}
            updateFormat={(numberFormat) =>
              updatePieChartFormat({ valuesFormat: { id: numberFormat } })
            }
          />

          <Input
            showInputButton
            className={configInputClass}
            defaultValue={String(pieChart?.pctDecimalPlaces ?? 1)}
            label="Label Decimal Places"
            onSubmit={(newValue) => {
              const intValue = parseInt(newValue);
              updatePieChartFormat({ pctDecimalPlaces: intValue >= 0 ? intValue : 1 });
            }}
          />
        </>
      )}
      <Switch
        className={configInputClass}
        label="Show Category Names"
        onChange={() => updatePieChartFormat({ useColorForLabel: !useColorForLabel })}
        switchOn={useColorForLabel}
      />
      <Input
        showInputButton
        className={configInputClass}
        defaultValue={String(pieChart?.maxCategories ?? '')}
        label="Max Categories"
        onSubmit={(newValue) => {
          const intValue = parseInt(newValue);
          updatePieChartFormat({ maxCategories: intValue > 0 ? intValue : undefined });
        }}
      />
      {DATE_TYPES.has(categoryColumn?.column.type || '') ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={instructions.xAxisFormat?.dateFormat}
            label="Category Date Format"
            onSubmit={(newValue) => {
              const newInstructions = produce(instructions, (draft) => {
                draft.xAxisFormat = { ...draft.xAxisFormat, dateFormat: newValue };
              });
              updateInstructions(newInstructions);
            }}
          />
          <DateFormatDescriptiveText />
        </>
      ) : null}
      {categoryColumn?.column.type === BOOLEAN ? (
        <>
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={instructions.chartSpecificFormat?.pieChart?.trueLabel || 'true'}
            label="True Label"
            onSubmit={(newValue) => updatePieChartFormat({ trueLabel: newValue })}
          />
          <Input
            showInputButton
            className={configInputClass}
            defaultValue={instructions.chartSpecificFormat?.pieChart?.falseLabel || 'false'}
            label="False Label"
            onSubmit={(newValue) => updatePieChartFormat({ falseLabel: newValue })}
          />
        </>
      ) : null}
      <Switch
        className={configInputClass}
        label="Use Standardized Pie Size"
        onChange={() => updatePieChartFormat({ useStandardPieSize: !useStandardPieSize })}
        switchOn={useStandardPieSize}
      />
    </>
  );
};
