import { sha256 } from 'js-sha256';
import { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { passwordResetConfirm } from 'actions/authAction';
import { InfoCard } from 'components/InfoCard';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { PasswordInput } from 'components/PasswordInput';
import { Button, sprinkles } from 'components/ds';
import { showErrorToast, showSuccessToast } from 'shared/sharedToasts';
import { pageView } from 'telemetry/exploAnalytics';
import { validatePassword } from 'utils/passwordUtils';

export const ResetPasswordPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { resetToken } = useParams<{ resetToken: string }>();

  const [resetLoading, setResetLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordRetyped, setPasswordRetyped] = useState('');

  useEffect(() => {
    pageView('Forgot password');
  }, []);

  const onPasswordResetConfirmSubmitted = () => {
    setResetLoading(true);
    dispatch(
      passwordResetConfirm(
        { postData: { token: resetToken, password: sha256(password) } },
        () => {
          showSuccessToast('Password reset successfully.');
          setPassword('');
          setPasswordRetyped('');
          setResetLoading(false);
          history.push('/login');
        },
        () => {
          showErrorToast('Something went wrong. Please contact Explo support if this continues.');
          setResetLoading(false);
        },
      ),
    );
  };
  const passwordError =
    password && passwordRetyped ? validatePassword(password, passwordRetyped) : '';

  return (
    <OnboardingFlowPage title="Password Reset">
      <div className={sprinkles({ flexItems: 'column', gap: 'sp2', width: 'fill' })}>
        <PasswordInput
          data-testid="reset-password"
          label="New password"
          onChange={setPassword}
          value={password}
        />
        <PasswordInput
          data-testid="reset-password-confirm"
          label="Confirm new password"
          onChange={setPasswordRetyped}
          value={passwordRetyped}
        />

        {passwordError ? (
          <InfoCard error className={sprinkles({ height: 'fitContent' })} text={passwordError} />
        ) : null}
        <Button
          fillWidth
          data-testid="reset-password-submit"
          disabled={!password || !passwordRetyped || !!passwordError}
          loading={resetLoading}
          onClick={onPasswordResetConfirmSubmitted}>
          Set new password
        </Button>
      </div>
    </OnboardingFlowPage>
  );
};
