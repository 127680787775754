import { isFinite } from 'utils/standard';

export const getValidRange = (
  min: number | undefined,
  max: number | undefined,
  isLogScale: boolean | undefined,
): { min: number | undefined; max: number | undefined } => {
  const isMinInvalid = min == null || !isFinite(min) || (isLogScale && min <= 0);
  const validMin = isMinInvalid ? undefined : min;
  const isMaxInvalid =
    max == null ||
    !isFinite(max) ||
    (isLogScale && max <= 0) ||
    (validMin != null && validMin > max);
  const validMax = isMaxInvalid ? undefined : max;
  return { min: validMin, max: validMax };
};
