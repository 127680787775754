import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { ColorZonesConfig } from 'components/ChartConfigs/ColorZonesConfig';
import { SettingHeader } from 'components/SettingHeader';
import { Button, Input, Label, Select, Tooltip, sprinkles } from 'components/ds';
import {
  INTENSITY,
  INTENSITY_MAX,
  INTENSITY_MIN,
  MAP_STYLES_SELECT_VALUES_DENSITY_MAP,
  OPACITY,
  OPACITY_MAX,
  OPACITY_MIN,
} from 'constants/maps';
import { OPERATION_TYPES, VisualizeGeospatialChartInstructions } from 'constants/types';
import { updateOperationConfigThunk } from 'reducers/thunks/dataPanelConfigThunks';
import { showSuccessToast } from 'shared/sharedToasts';
import { BoundaryType } from 'types/maps';
import { saveMapViewState } from 'utils/customEventUtils';

type Props = {
  instructions: VisualizeGeospatialChartInstructions;
};

export const DensityMapConfig: FC<Props> = ({ instructions }) => {
  const dispatch = useDispatch();

  const mapFormat = instructions.densityMapFormat;

  return (
    <>
      <SettingHeader name="Map Options" />

      <div className={containerStyle}>
        <Select
          label="Map Style"
          onChange={(value) =>
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_DENSITY_MAP,
                instructions,
                (draft) => {
                  (draft.densityMapFormat ??= {}).style = value as BoundaryType;
                },
              ),
            )
          }
          selectedValue={mapFormat?.style}
          values={MAP_STYLES_SELECT_VALUES_DENSITY_MAP}
        />
        <Tooltip text="This will save the current map view as the starting point.">
          <Button
            fillWidth
            onClick={() => {
              saveMapViewState();
              showSuccessToast('Saved Map View');
            }}>
            Save Initial View
          </Button>
        </Tooltip>
      </div>

      <SettingHeader name="Density" />
      <div className={containerStyle}>
        <Input
          defaultValue={(mapFormat?.intensity ?? INTENSITY).toString()}
          label={{
            text: 'Intensity',
            infoText: `Intensity must be between ${INTENSITY_MIN} and ${INTENSITY_MAX} `,
          }}
          onSubmit={(value) => {
            const parsedVal = parseInt(value);
            const isOutOfRange = parsedVal > INTENSITY_MAX || parsedVal < INTENSITY_MIN;
            const newVal = isNaN(parsedVal) || isOutOfRange ? undefined : parsedVal;
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_DENSITY_MAP,
                instructions,
                (draft) => {
                  (draft.densityMapFormat ??= {}).intensity = newVal;
                },
              ),
            );
          }}
        />
        <Input
          defaultValue={(mapFormat?.opacity ?? OPACITY).toString()}
          label={{
            text: 'Opacity',
            infoText: `Opacity must be between ${OPACITY_MIN} and ${OPACITY_MAX}`,
          }}
          onSubmit={(value) => {
            const parsedVal = parseFloat(value);
            const isOutOfRange = parsedVal > OPACITY_MAX || parsedVal < OPACITY_MIN;
            const newVal = isNaN(parsedVal) || isOutOfRange ? undefined : parsedVal;
            dispatch(
              updateOperationConfigThunk(
                OPERATION_TYPES.VISUALIZE_DENSITY_MAP,
                instructions,
                (draft) => {
                  (draft.densityMapFormat ??= {}).opacity = newVal;
                },
              ),
            );
          }}
        />
        <div className={sprinkles({ flexItems: 'column' })}>
          <Label
            forVariableInput
            htmlFor=""
            infoText="Add different bands of color based on a density threshold.">
            Color Zones
          </Label>
          <ColorZonesConfig
            instructions={mapFormat ?? {}}
            maxThreshold={1}
            minThreshold={0}
            updateColorFormat={(colorFormat) =>
              dispatch(
                updateOperationConfigThunk(
                  OPERATION_TYPES.VISUALIZE_DENSITY_MAP,
                  instructions,
                  (draft) => {
                    (draft.densityMapFormat ??= {}).colorFormat = colorFormat;
                  },
                ),
              )
            }
          />
        </div>
      </div>
    </>
  );
};

const containerStyle = sprinkles({ padding: 'sp1.5', flexItems: 'column', gap: 'sp1.5' });
