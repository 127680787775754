import { FC, useMemo } from 'react';

import { Dataset } from 'actions/datasetActions';
import { SettingHeader } from 'components/SettingHeader';
import { sprinkles } from 'components/ds';
import { FilterLink } from 'pages/dashboardPage/DataPanelConfig/DataConfigTab/FilterConfigSection/FilterLink';
import { DashboardElement } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getPossibleLinksForDataPanel } from 'utils/filterLinking';

interface Props {
  dataPanel: DataPanelTemplate;
  datasets: Record<string, Dataset>;
  dashboardElements: DashboardElement[];
}

export const ElementFilterSection: FC<Props> = ({ dataPanel, datasets, dashboardElements }) => {
  const possibleLinksForDataPanel = useMemo(
    () => getPossibleLinksForDataPanel(dataPanel, datasets, dashboardElements),
    [dataPanel, datasets, dashboardElements],
  );

  return (
    <>
      {possibleLinksForDataPanel.length > 0 ? (
        <SettingHeader
          className={sprinkles({ marginY: 'sp1' })}
          infoText="Filters controlled by dashboard elements"
          name="Element Filter"
        />
      ) : null}
      <div className={sprinkles({ marginX: 'sp1.5' })}>
        {possibleLinksForDataPanel.map((link) => (
          <FilterLink
            dataPanelId={dataPanel.id}
            key={`${dataPanel.id}-${link.elementName}`}
            link={link}
          />
        ))}
      </div>
    </>
  );
};
