import { Draft } from 'immer';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { DatePresetManager } from 'components/ChartConfigs/DateConfig/DatePresetManager';
import {
  SortableListItemDragHandle,
  SortableList,
  SortableListItem,
} from 'components/SortableList/SortableList';
import { sprinkles, Checkbox } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { DateElemConfig } from 'types/dashboardTypes';
import { getSortedDateRangesWithVisibility, toggleRangeVisibility } from 'utils/dateRangeUtils';

import * as styles from '../styles.css';

interface Props {
  config: DateElemConfig;
  shouldRenderHeader: boolean;
  updateConfigFn: (updateFunc: (draft: Draft<DateElemConfig>) => void) => void;
}

export const RelativeDatesDropdownConfig: FC<Props> = ({
  config,
  shouldRenderHeader,
  updateConfigFn,
}) => {
  const enableDateRangePresets = useSelector(
    (state: ReduxState) =>
      'currentUser' in state && !!state.currentUser.team?.feature_flags?.enable_date_range_presets,
  );

  const orderedRanges = useMemo(() => getSortedDateRangesWithVisibility(config), [config]);

  return (
    <>
      {shouldRenderHeader ? (
        <div className={styles.headerClass}>Pre-set Dropdown Values</div>
      ) : null}
      <SortableList
        getIdFromElem={(item) => item.id}
        onListUpdated={(newList) =>
          updateConfigFn((draft) => {
            draft.defaultRangesOrder = newList.map((item) => item.id);
          })
        }
        sortableItems={orderedRanges}>
        {orderedRanges.map(({ id, name, isVisible }) => {
          return (
            <SortableListItem key={id} sortId={id}>
              <div className={styles.sortableRangeOption}>
                <SortableListItemDragHandle className={sprinkles({ color: 'contentSecondary' })} />
                <Checkbox
                  isChecked={isVisible}
                  onChange={() =>
                    updateConfigFn((draft) => {
                      toggleRangeVisibility(id, isVisible, draft);
                    })
                  }
                />
                <div>{name}</div>
              </div>
            </SortableListItem>
          );
        })}
      </SortableList>
      {enableDateRangePresets ? (
        <div className={sprinkles({ marginTop: 'sp1.5' })}>
          <DatePresetManager
            onAdd={(value) =>
              updateConfigFn((draft) => {
                (draft.presetRanges ??= {})[v4()] = value;
              })
            }
            onDelete={(name) =>
              updateConfigFn((draft) => {
                delete (draft.presetRanges ??= {})[name];
              })
            }
            onUpdate={(id, value) =>
              updateConfigFn((draft) => {
                (draft.presetRanges ??= {})[id] = value;
              })
            }
            presets={config.presetRanges || {}}
          />
        </div>
      ) : null}
    </>
  );
};
