import { ThemeProvider } from '@material-ui/core/styles';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './i18n';

import 'utils/globalSetupUtils';
import { baseThemeName, APP_PORTAL_ID } from 'components/ds';
import { store } from 'store/store';
import { initDataDog } from 'telemetry/datadog';
import { isEnvironmentSecure } from 'utils/environmentUtils';

import App from './App';
import theme from './theme';

initDataDog();

const MainApp = () => {
  return (
    <div
      className={baseThemeName}
      id={APP_PORTAL_ID}
      style={{ fontFamily: 'Inter, sans-serif', lineHeight: 'normal' }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <DndProvider backend={HTML5Backend}>
            {isEnvironmentSecure() ? (
              <App />
            ) : (
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? ''}>
                <App />
              </GoogleOAuthProvider>
            )}
          </DndProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
};

ReactDOM.render(<MainApp />, document.getElementById('root'));
