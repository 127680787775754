import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { EmbeddedChart } from 'components/EmbeddedChart';

type MatchParams = {
  embedJwt: string;
};

type Props = RouteComponentProps<MatchParams>;
class IFrameChartPage extends Component<Props> {
  render() {
    const { match } = this.props;

    // Only JWT is supported
    return (
      <EmbeddedChart
        dashboardEmbedId=""
        embedJwt={match.params.embedJwt}
        isScreenshotDownload={false}
      />
    );
  }
}

export default withRouter(IFrameChartPage);
