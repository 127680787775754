import { DateOperation, DateOperationType, DatePeriodOperation } from 'types/dashboardTypes';

// Provide default operations for new presets, ensuring DatePresetForm has its own defaults
// to avoid relying on the DatePresetEditor to provide them

export const getDefaultNow = (): DateOperation => ({ id: DateOperationType.Now });

export const getDefaultStartOf = (): DatePeriodOperation => ({
  id: DateOperationType.StartOf,
  unit: 'day',
});

export const getDefaultOperations = (): DateOperation[] => [getDefaultNow(), getDefaultStartOf()];
