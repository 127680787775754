import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, IconButton, sprinkles } from 'components/ds';
import { MenuButton } from 'components/ds/MenuButton';
import { Props as TooltipProps } from 'components/ds/Tooltip';
import { ReduxState } from 'reducers/rootReducer';
import { AIGoal, getHandleAIClickFn } from 'utils/aiUtils';

type Props = {
  disableAI?: boolean;
  saveText: string;
  onRevertDraft?: () => void;
  onPreview?: () => void;
  onSave?: () => void;
  onFormat: () => void;
  saveTooltipProps?: TooltipProps;
  query?: string;
  error?: string;
};

/**
 * Provides buttons for reverting edits, previewing, saving, and formatting the SQL
 */
export const QueryRunButtons: FC<Props> = ({
  disableAI,
  saveText,
  onRevertDraft,
  onPreview,
  onSave,
  onFormat,
  query,
  error,
  saveTooltipProps,
}) => {
  const dispatch = useDispatch();
  const team = useSelector((state: ReduxState) => state.teamData.data);
  const customerName = useSelector((state: ReduxState) => state.currentUser.first_name);

  const enableAi = useSelector(
    (state: ReduxState) => state.currentUser.team?.entitlements.enable_ai && !disableAI,
  );

  const handleClick = (e: React.MouseEvent, onClickFn: (() => void) | undefined) => {
    if (!onClickFn) return;
    // Prevent query panel from expanding/collapsing when button is clicked
    e.stopPropagation();
    onClickFn();
  };

  const handleAIClick = getHandleAIClickFn({ dispatch, team, customerName, query, error });

  return (
    <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
      <IconButton
        disabled={!onRevertDraft}
        name="trashUndo"
        onClick={(e) => handleClick(e, onRevertDraft)}
        tooltipProps={{ text: 'Revert draft' }}
        variant="primary"
      />
      <IconButton
        name="wrench"
        onClick={(e) => handleClick(e, onFormat)}
        tooltipProps={{ text: 'Format SQL (⌘+⇧+F)' }}
        variant="primary"
      />
      {enableAi ? (
        <MenuButton
          actions={[
            {
              label: 'Optimize Query',
              value: AIGoal.OPTIMIZE,
              icon: 'wand',
              onClick: () => handleAIClick(AIGoal.OPTIMIZE),
            },
            {
              label: 'Explain Query',
              value: AIGoal.EXPLAIN,
              icon: 'wand',
              onClick: () => handleAIClick(AIGoal.EXPLAIN),
            },
            {
              label: 'Debug Query',
              value: AIGoal.DEBUG,
              icon: 'wand',
              disabled: !error,
              onClick: () => handleAIClick(AIGoal.DEBUG),
            },
          ]}
          disabled={!query}
          icon="wand"
          label="Ask AI"
          variant="aiTertiary"
        />
      ) : null}

      <Button
        disabled={!onPreview}
        onClick={(e) => handleClick(e, onPreview)}
        tooltipProps={{ text: 'Preview results without saving' }}
        variant="secondary">
        Preview
      </Button>
      <Button
        disabled={!onSave}
        icon="play"
        onClick={(e) => handleClick(e, onSave)}
        tooltipProps={saveTooltipProps}
        variant="primary">
        {saveText}
      </Button>
    </div>
  );
};
