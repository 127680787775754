import { createSlice } from '@reduxjs/toolkit';

import {
  mfaConfirmSuccess,
  AuthMethods,
  getUserActiveMethodsError,
  getUserActiveMethodsRequest,
  getUserActiveMethodsSuccess,
  mfaActivateSuccess,
  mfaDeactivateSuccess,
  mfaActivateRequest,
  mfaActivateError,
  mfaConfirmError,
  mfaDeactivateError,
  mfaConfirmRequest,
  mfaDeactivateRequest,
  requestCodeSuccess,
  requestCodeRequest,
  requestCodeError,
  UserActiveMethodsData,
  changePrimaryMethodRequest,
  changePrimaryMethodSuccess,
  changePrimaryMethodError,
} from 'actions/mfaActions';
import { logInUserSuccess } from 'actions/userActions';
import * as RD from 'remotedata';

export interface MethodState {
  isActive: boolean;
  isPrimary: boolean;
}

interface MfaReducerState {
  activeMethodResponse: RD.ResponseData<UserActiveMethodsData>;
  activateResponse: RD.ResponseData<string>; // details string
  deactivateResponse: RD.ResponseData<void>;
  codeResponse: RD.ResponseData<void>;
  changePrimaryResponse: RD.ResponseData<void>;
  backupCodes: RD.ResponseData<string[]>;
  methods: Record<AuthMethods, MethodState>;
}

const getInactiveMethodState = (): MethodState => ({
  isActive: false,
  isPrimary: false,
});

const getActiveMethodState = (isPrimary: boolean): MethodState => ({
  isActive: true,
  isPrimary,
});

const getInitialState = (): MfaReducerState => ({
  activeMethodResponse: RD.Idle(),
  activateResponse: RD.Idle(),
  deactivateResponse: RD.Idle(),
  codeResponse: RD.Idle(),
  changePrimaryResponse: RD.Idle(),
  backupCodes: RD.Idle(),
  methods: Object.values(AuthMethods).reduce(
    (acc, method) => ({
      ...acc,
      [method]: getInactiveMethodState(),
    }),
    {} as Record<AuthMethods, MethodState>,
  ),
});

const mfaSlice = createSlice({
  name: 'mfaReducer',
  initialState: getInitialState,
  reducers: {
    resetMethod: (state) => {
      state.backupCodes = RD.Idle();
      state.activateResponse = RD.Idle();
      state.deactivateResponse = RD.Idle();
      state.codeResponse = RD.Idle();
      state.changePrimaryResponse = RD.Idle();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logInUserSuccess, getInitialState)
      .addCase(getUserActiveMethodsRequest, (state) => {
        state.activeMethodResponse = RD.Loading();
      })
      .addCase(getUserActiveMethodsSuccess, (state, { payload }) => {
        state.activeMethodResponse = RD.Success(payload);
        Object.values(payload).forEach(({ name, is_primary }) => {
          state.methods[name as AuthMethods] = getActiveMethodState(is_primary);
        });
      })
      .addCase(getUserActiveMethodsError, (state, { payload }) => {
        state.activeMethodResponse = RD.Error(payload.error_msg || '');
      })
      .addCase(mfaActivateRequest, (state) => {
        state.activateResponse = RD.Loading();
      })
      .addCase(mfaActivateSuccess, (state, { payload: { details } }) => {
        state.activateResponse = RD.Success(details);
      })
      .addCase(mfaActivateError, (state, { payload }) => {
        state.activateResponse = RD.Error(payload.error_msg || '');
      })
      .addCase(mfaConfirmRequest, (state) => {
        state.backupCodes = RD.Loading();
      })
      .addCase(mfaConfirmSuccess, (state, { payload: { id, backup_codes } }) => {
        if (id) state.methods[id as AuthMethods] = getActiveMethodState(false);
        state.backupCodes = RD.Success(backup_codes);
      })
      .addCase(mfaConfirmError, (state, { payload }) => {
        state.backupCodes = RD.Error(payload.error_msg || '');
      })
      .addCase(mfaDeactivateRequest, (state) => {
        state.deactivateResponse = RD.Loading();
      })
      .addCase(mfaDeactivateSuccess, (state, { payload: { id } }) => {
        if (id) state.methods[id as AuthMethods] = getInactiveMethodState();
        state.deactivateResponse = RD.Success(undefined);
      })
      .addCase(mfaDeactivateError, (state, { payload }) => {
        state.deactivateResponse = RD.Error(payload.error_msg || '');
      })
      .addCase(requestCodeRequest, (state) => {
        state.codeResponse = RD.Loading();
      })
      .addCase(requestCodeSuccess, (state) => {
        state.codeResponse = RD.Success(undefined);
      })
      .addCase(requestCodeError, (state, { payload }) => {
        state.codeResponse = RD.Error(payload.error_msg || '');
      })
      .addCase(changePrimaryMethodRequest, (state) => {
        state.changePrimaryResponse = RD.Loading();
      })
      .addCase(changePrimaryMethodSuccess, (state, { payload: { postData } }) => {
        state.changePrimaryResponse = RD.Success(undefined);
        for (const method of Object.keys(state.methods)) {
          state.methods[method as AuthMethods].isPrimary = method === postData.method;
        }
      })
      .addCase(changePrimaryMethodError, (state, { payload }) => {
        state.changePrimaryResponse = RD.Error(payload.error_msg || '');
      });
  },
});

export const { resetMethod } = mfaSlice.actions;

export const mfaReducer = mfaSlice.reducer;
