import { FC } from 'react';

import {
  getDefaultNow,
  getDefaultStartOf,
} from 'components/ChartConfigs/DateConfig/DefaultOperations';
import { Select, sprinkles } from 'components/ds';
import { NumberInput } from 'components/ds/Input/NumberInput';
import { SelectItems } from 'components/ds/Select';
import {
  DateAdditionOperation,
  DateNowOperation,
  DateOperation,
  DateOperationType,
  DatePeriodOperation,
} from 'types/dashboardTypes';

type Props = {
  operations: DateOperation[];
  updateOperation: (index: number, operation: DateOperation) => void;
};

export const DatePresetForm: FC<Props> = ({ operations, updateOperation }) => {
  const firstOperation =
    (operations[0] as DateAdditionOperation | DateNowOperation) || getDefaultNow();
  const secondOperation = (operations[1] as DatePeriodOperation) || getDefaultStartOf();

  return (
    <>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <Select
          onChange={(value) =>
            updateOperation(
              0,
              value === DateOperationType.Now
                ? getDefaultNow()
                : { unit: 'day', quantity: 1, ...firstOperation, id: value as DateOperationType },
            )
          }
          selectedValue={firstOperation.id}
          values={addOperations}
        />
        {firstOperation.id === DateOperationType.Plus ||
        firstOperation.id === DateOperationType.Minus ? (
          <>
            <NumberInput
              onChange={(value) => updateOperation(0, { ...firstOperation, quantity: value })}
              placeholder="Enter Quantity"
              value={firstOperation.quantity}
            />
            <Select
              onChange={(value) =>
                updateOperation(0, { ...firstOperation, unit: value as DateTimeUnit })
              }
              placeholder="Select Unit"
              selectedValue={firstOperation.unit}
              values={unitOptions}
            />
          </>
        ) : null}
      </div>

      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
        <Select
          onChange={(value) =>
            updateOperation(1, {
              ...secondOperation,
              id: value as DateOperationType.StartOf | DateOperationType.EndOf,
            })
          }
          selectedValue={secondOperation.id}
          values={periodOperations}
        />
        <Select
          onChange={(value) =>
            updateOperation(1, { ...secondOperation, unit: value as DateTimeUnit })
          }
          placeholder="Select Unit"
          selectedValue={secondOperation.unit}
          values={unitOptions}
        />
      </div>
    </>
  );
};

const addOperations: SelectItems<DateOperationType> = [
  { label: 'Now', value: DateOperationType.Now },
  { label: 'Plus', value: DateOperationType.Plus },
  { label: 'Minus', value: DateOperationType.Minus },
];

const periodOperations: SelectItems<DateOperationType> = [
  { label: 'Start Of', value: DateOperationType.StartOf },
  { label: 'End Of', value: DateOperationType.EndOf },
];

// values must match those in Luxon's DateTimeUnit
enum DateTimeUnit {
  Year = 'year',
  Quarter = 'quarter',
  Month = 'month',
  Week = 'week',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
  Millisecond = 'millisecond',
}

const unitOptions: SelectItems<DateTimeUnit> = Object.entries(DateTimeUnit).map(
  ([label, value]) => ({ value, label }),
);
