import produce from 'immer';
import { FC } from 'react';

import {
  SortableList,
  SortableListItem,
  SortableListItemDragHandle,
} from 'components/SortableList/SortableList';
import { APP_PORTAL_ID, IconButton, Input, Tooltip, sprinkles } from 'components/ds';
import { ColorZone } from 'constants/types';
import CustomStylesColorPicker from 'pages/GlobalCustomStylesPage/CustomStylesColorPicker';
import { showErrorToast } from 'shared/sharedToasts';

import * as styles from './styles.css';

type ColorZoneListProps = {
  colorZones: ColorZone[];
  updateColorZones: (zones: ColorZone[]) => void;
  updateZoneAtIndex: (idx: number, updateZone: (colorZone: ColorZone) => void) => void;
  minThreshold?: number;
  maxThreshold?: number;
};

export const ColorZoneList: FC<ColorZoneListProps> = ({
  colorZones,
  updateColorZones,
  updateZoneAtIndex,
  minThreshold,
  maxThreshold,
}) => {
  const numZones = colorZones.length;

  const validateColorZoneThreshold = (newValue: string) => {
    const floatValue = parseFloat(newValue);
    if (minThreshold !== undefined && floatValue <= minThreshold) return false;
    if (maxThreshold !== undefined && floatValue >= maxThreshold) return false;
    return true;
  };

  return (
    <SortableList
      getIdFromElem={(item) => item.zoneId}
      onListUpdated={updateColorZones}
      sortableItems={colorZones}>
      {colorZones.map((zone, i) => {
        const isLastZone = numZones === i + 1;

        let zoneThresholdInput = (
          <Input
            className={sprinkles({ marginRight: 'sp1' })}
            defaultValue={isLastZone ? undefined : zone.zoneThreshold}
            disabled={isLastZone}
            onSubmit={(newValue) => {
              if (!validateColorZoneThreshold(newValue)) {
                showErrorToast(
                  `Invalid threshold. The threshold must be strictly between ${minThreshold} and ${maxThreshold}.`,
                );
                return;
              }
              updateZoneAtIndex(i, (newZone) => (newZone.zoneThreshold = newValue));
            }}
            placeholder="Threshold"
          />
        );

        if (isLastZone) {
          zoneThresholdInput = (
            <Tooltip
              align="center"
              portalContainerId={APP_PORTAL_ID}
              side="bottom"
              text={
                <div className={sprinkles({ textAlign: 'center' })}>
                  The last zone fills the remaining vertical space
                </div>
              }>
              <span>{zoneThresholdInput}</span>
            </Tooltip>
          );
        }

        return (
          <SortableListItem key={zone.zoneId} sortId={zone.zoneId}>
            <div className={styles.colorZoneContainer}>
              <SortableListItemDragHandle className={sprinkles({ marginRight: 'sp1' })} />
              <CustomStylesColorPicker
                fill
                className={sprinkles({ marginRight: 'sp1' })}
                color={zone.zoneColor}
                onClose={(newColor) =>
                  updateZoneAtIndex(i, (newZone) => (newZone.zoneColor = newColor))
                }
              />
              {zoneThresholdInput}
              <IconButton
                className={styles.colorZoneTrashButton}
                name="trash"
                onClick={() =>
                  updateColorZones(
                    produce(colorZones, (draft) => {
                      draft.splice(i, 1);
                    }),
                  )
                }
                variant="destructive"
              />
            </div>
          </SortableListItem>
        );
      })}
    </SortableList>
  );
};
