import { FetchProfileData } from 'actions/userActions';
import { PLAN_TYPES } from 'constants/paymentPlanConstants';

export const pingCustomerOnlineMessage = (user: FetchProfileData) => {
  const deactivatedText =
    user.team?.payment_plan === PLAN_TYPES.DEACTIVATED ? '[DEACTIVATED] ' : '';
  const name = `${user.first_name} ${user.last_name} (${user.email})`;
  const teamName = user.team?.team_name || '';
  const env = process.env.REACT_APP_ENVIRONMENT;

  return `${name} from ${deactivatedText}${teamName} just logged into Explo (${env})`;
};

export const pingUserWithoutTeamMessage = (user: FetchProfileData) => {
  return `${user.first_name} ${user.last_name} (${user.email}) just logged into Explo without a team (${process.env.REACT_APP_ENVIRONMENT})`;
};
