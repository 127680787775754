import { useCallback, useMemo } from 'react';

import MultiSelect from 'components/multiSelect';
import { SelectedDropdownInputItem } from 'constants/types';
import { SUPPORTED_FIDO_DATA_SOURCES } from 'pages/ConnectDataSourceFlow/constants';

import * as styles from './index.css';

interface EnabledDataSourcesMultiselectProps {
  enabledDataSources: string[];
  onChange: (enabledDataSources: string[]) => void;
}

export const EnabledDataSourcesMultiselect: React.FC<EnabledDataSourcesMultiselectProps> = ({
  enabledDataSources,
  onChange,
}) => {
  const selectedItems = useMemo(() => {
    return enabledDataSources.map(convertDataSourceToMultiselectItem);
  }, [enabledDataSources]);

  const updateSelectedValues = useCallback(
    (newDataSources: string[] | number[] | undefined) => {
      onChange((newDataSources ?? []) as string[]);
    },
    [onChange],
  );

  return (
    <MultiSelect
      containerClassName={styles.enabledDataSourcesMultiselect}
      options={MULTISELECT_OPTIONS}
      selectedItems={selectedItems}
      updateSelectedValues={updateSelectedValues}
    />
  );
};

const MULTISELECT_OPTIONS = SUPPORTED_FIDO_DATA_SOURCES.map(convertDataSourceToMultiselectItem);

function convertDataSourceToMultiselectItem(dataSourceName: string): SelectedDropdownInputItem {
  return {
    id: dataSourceName,
    name: dataSourceName,
    value: dataSourceName,
  };
}
