import { FC } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sqlFormatter from 'sql-formatter';
import XRegExp from 'xregexp';

import { sprinkles } from 'components/ds';
import Markdown from 'components/markdown';

import * as styles from './QuerySuggestion.css';

// TODO: unit tests for this component SHIBA-5824

type Props = {
  explanation: string;
};

const codeSQLRegex = /(<code>(?<code>(.|\n)+?)<\/code>)/g;

export const FormattedQueryText: FC<Props> = ({ explanation }) => {
  const parts: { text: string; type: 'text' | 'sql' }[] = [];

  let remainingText = explanation;

  XRegExp.forEach(explanation, codeSQLRegex, (match) => {
    const completeBlock = match[0];
    const sql = match[2]?.trim();
    const split = remainingText.split(completeBlock);
    const previousText = split[0];
    remainingText = split[1] ?? '';

    parts.push({
      text: previousText,
      type: 'text',
    });
    parts.push({
      text: sqlFormatter.format(sql, {
        indent: '  ',
      }),
      type: 'sql',
    });
  });

  if (remainingText.length > 0) parts.push({ text: remainingText, type: 'text' });

  return (
    <div className={styles.formattedOutputContainer}>
      {parts.map((explanationPart, i) => (
        <div key={i}>
          {explanationPart.type === 'sql' ? (
            <div
              className={sprinkles({
                paddingX: 'sp1.5',
                width: 'fill',
                backgroundColor: 'white',
                borderRadius: 4,
                marginBottom: 'sp2',
              })}
              style={{ fontFamily: 'Source Code Pro' }}>
              <SyntaxHighlighter showLineNumbers language="sql">
                {explanationPart.text}
              </SyntaxHighlighter>
            </div>
          ) : (
            <Markdown markdownText={explanationPart.text} />
          )}
        </div>
      ))}
    </div>
  );
};
