import { FC } from 'react';

import { Dataset } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { VERSION_COMPARISON_HEADER_CLASS } from 'components/VersionComparisonModal/index.css';
import { getDatasetName } from 'components/VersionComparisonModal/utils';
import { sprinkles } from 'components/ds';

import { DatasetDetailsSection } from './DatasetDetailsSection';

interface Props {
  previousVersionDataset: Dataset | ReportBuilderDataset;
  previousVersionNumber: number;
  currentVersionDataset: Dataset | ReportBuilderDataset;
  currentVersionNumber: number;
}

export const DatasetComparisonSection: FC<Props> = ({
  previousVersionDataset,
  previousVersionNumber,
  currentVersionDataset,
  currentVersionNumber,
}) => {
  const previousVersionDetailsSection = (
    <DatasetDetailsSection
      datasetQuery={previousVersionDataset.query || ''}
      versionNumber={previousVersionNumber}
    />
  );
  const currentVersionDetailsSection = (
    <DatasetDetailsSection
      datasetQuery={currentVersionDataset.query || ''}
      versionNumber={currentVersionNumber}
    />
  );

  return (
    <>
      <div className={VERSION_COMPARISON_HEADER_CLASS}>{getDatasetName(currentVersionDataset)}</div>
      <div
        className={sprinkles({
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        })}>
        {previousVersionDetailsSection}
        {currentVersionDetailsSection}
      </div>
    </>
  );
};
