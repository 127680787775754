import React from 'react';

import { NumberFormatToggle } from 'components/NumberFormatToggle';
import { Input, Switch } from 'components/ds';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import { ValueFormatOptions } from 'constants/types';
import { configInputClass } from 'pages/dashboardPage/DataPanelConfig/styles.css';

export interface ValueFormatConfigProps {
  instructions: ValueFormatOptions | undefined;
  updateInstructions: (instructions: ValueFormatOptions) => void;
  valueFormatLabelHelpText?: string;
}

export const ValueFormatConfig: React.FC<ValueFormatConfigProps> = ({
  instructions,
  updateInstructions,
  valueFormatLabelHelpText,
}) => {
  const selectedValueFormat = instructions?.numberFormat || V2_NUMBER_FORMATS.NUMBER;

  const handleUpdate = (updates: Partial<ValueFormatOptions>) => {
    updateInstructions({
      ...instructions,
      ...updates,
    });
  };

  return (
    <>
      <NumberFormatToggle
        disableAbbr
        disableTime
        className={configInputClass}
        label="Value Format"
        labelHelpText={valueFormatLabelHelpText}
        selectedFormat={selectedValueFormat.id}
        updateFormat={(numberFormat) => handleUpdate({ numberFormat: { id: numberFormat } })}
      />
      <Switch
        className={configInputClass}
        label="Show Decimals"
        onChange={() => handleUpdate({ showDecimals: !instructions?.showDecimals })}
        switchOn={instructions?.showDecimals}
      />
      {instructions?.showDecimals ? (
        <Input
          showInputButton
          className={configInputClass}
          defaultValue={String(instructions?.decimalPlaces ?? 2)}
          label={{
            text: 'Decimal Places',
            infoText:
              "This will only apply to the data tooltip unless 'Show Axis Decimals' is enabled.",
          }}
          onSubmit={(newValue) => {
            const intValue = parseInt(newValue);
            handleUpdate({ decimalPlaces: intValue > 0 ? intValue : 0 });
          }}
        />
      ) : null}
    </>
  );
};
