import { FC } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sqlFormatter from 'sql-formatter';

import { sprinkles } from 'components/ds';

interface Props {
  versionNumber: number;
  datasetQuery: string;
}

export const DatasetDetailsSection: FC<Props> = ({ versionNumber, datasetQuery }) => {
  return (
    <div className={sprinkles({ width: 'half', paddingX: 'sp3', borderRight: 1 })}>
      <div className={sprinkles({ color: 'contentSecondary' })}>{`Version ${versionNumber}`}</div>
      <SyntaxHighlighter showLineNumbers customStyle={{ overflow: 'auto' }} language="sql">
        {sqlFormatter.format(datasetQuery, { indent: '    ' })}
      </SyntaxHighlighter>
    </div>
  );
};
