import { sha256 } from 'js-sha256';
import { useState, useEffect, useCallback, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import parse from 'url-parse';
import { useLocalStorage } from 'usehooks-ts';

import { logInUser, fetchSignupEmail } from 'actions/authAction';
import { ACTION } from 'actions/types';
import { GoogleSSOLoginButton } from 'components/GoogleSSOLoginButton';
import { InfoCard } from 'components/InfoCard';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import * as onboardingStyles from 'components/Onboarding/styles.css';
import { PasswordInput } from 'components/PasswordInput';
import { Input, Button, sprinkles } from 'components/ds';
import { ROUTES } from 'constants/routes';
import { email } from 'pages/ReportBuilder/ModalViews/ScheduleExportModal/ScheduleManager.css';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { pageView } from 'telemetry/exploAnalytics';
import { isEnvironmentSecure } from 'utils/environmentUtils';
import { titleCase } from 'utils/graphUtils';

import * as styles from './SignInPage.css';

const LOGIN_ERROR_TO_INPUT_ERRORS: Record<string, string> = {
  'Must include "email" and "password".': 'Please enter an email and password to log in.',
  'Enter a valid email address.': 'Please enter a valid email to log in.',
  'Unable to log in with provided credentials.':
    'Unable to log in with the provided email and password.',
};

export const EMAIL_LOCAL_STORAGE_KEY = 'verify_email';

export const SignInPage: FC = () => {
  const [signupEmail, setSignupEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [ephemeralToken, setEphemeralToken] = useState('');
  const [method, setMethod] = useState('');

  // when the user signs in, if that email isn't verified we send them to the check your email page
  // and use local storage to store the email they used to sign in, which that page needs
  const setLocalStorageEmail = useLocalStorage(EMAIL_LOCAL_STORAGE_KEY, '')[1];

  const { loginLoading, ssoLoginLoading } = useSelector(
    (state: ReduxState) => ({
      loginLoading: createLoadingSelector([ACTION.LOGIN_USER], false)(state),
      ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const urlQueryParams = parse(window.location.href, true).query;

  const disableSubmit = !!ephemeralToken && !code.trim();

  const onSubmit = useCallback(() => {
    if (disableSubmit) return;

    dispatch(
      logInUser(
        signupEmail,
        sha256(password),
        code,
        ephemeralToken,
        (response) => {
          if (response.ephemeral_token && response.method) {
            setEphemeralToken(response.ephemeral_token[0]);
            setMethod(response.method[0]);
          }

          const lockoutError = response.detail;
          if (lockoutError !== '' || Object.keys(response).length > 0) {
            let error;
            if (lockoutError) {
              error = lockoutError;
            } else if (response.email) {
              error = response.email[0];
            } else if (response.non_field_errors) {
              error = response.non_field_errors[0];
            }

            if (error) {
              if (error === 'E-mail is not verified.') {
                setLocalStorageEmail(email);
                history.push(ROUTES.CHECK_YOUR_EMAIL);
              }

              setErrorMsg(LOGIN_ERROR_TO_INPUT_ERRORS[error] || error);
            }
          }
        },
        () => {
          setSignupEmail('');
          setPassword('');
          setCode('');
          history.push('/home');
        },
      ),
    );
  }, [
    disableSubmit,
    dispatch,
    signupEmail,
    password,
    code,
    ephemeralToken,
    setLocalStorageEmail,
    history,
  ]);

  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      dispatch(
        fetchSignupEmail(
          {
            postData: {
              invite_hash: urlQueryParams.invite_hash,
            },
          },
          (response) => {
            setSignupEmail(response.email);
            setErrorMsg(
              'This invitation has already been used to create an account. Please sign in.',
            );
          },
        ),
      );
    }
    pageView('Login');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onSubmit();
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [urlQueryParams.invite_hash, history, onSubmit, dispatch]);

  const location = useLocation();
  const isSessionExpired = location.hash === '#expired';

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Sign Up', to: ROUTES.SIGN_UP },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      subtitle={isSessionExpired ? 'Your session has expired. Please sign in again.' : undefined}
      title="Sign in">
      <div className={sprinkles({ width: 'fill', flexItems: 'column', gap: 'sp2' })}>
        <Input
          fillWidth
          data-testid="sign-in-email-input"
          label="Work email"
          onChange={setSignupEmail}
          value={signupEmail}
        />

        <PasswordInput
          data-testid="sign-in-password-input"
          label="Password"
          onChange={setPassword}
          value={password}
        />

        {ephemeralToken ? (
          <>
            <InfoCard
              className={sprinkles({ height: 'fitContent' })}
              text={`Please provide your 2-step verification code sent via ${titleCase(
                method,
              )} then sign in again.`}
            />
            <Input label="2-Step Verification Code" onChange={setCode} value={code} />
          </>
        ) : null}

        <Link className={onboardingStyles.link} to={ROUTES.FORGOT_PASSWORD}>
          Forgot your password?
        </Link>

        {errorMsg ? (
          <InfoCard error className={sprinkles({ height: 'fitContent' })} text={errorMsg} />
        ) : null}

        <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
          <Button
            fillWidth
            className={styles.signInButton}
            disabled={disableSubmit}
            loading={loginLoading || ssoLoginLoading}
            onClick={onSubmit}>
            Sign In
          </Button>
          {isEnvironmentSecure() ? null : <GoogleSSOLoginButton isSignIn />}
          <Button
            fillWidth
            className={styles.samlButton}
            disabled={loginLoading}
            onClick={() => history.push(ROUTES.SAML_INITIATE_AUTH)}>
            Sign In with SAML SSO
          </Button>
        </div>
      </div>
    </OnboardingFlowPage>
  );
};
