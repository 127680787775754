import { Middleware, Dispatch } from 'redux';

import * as dptActions from 'actions/dataPanelTemplateAction';
import * as embedActions from 'actions/embedActions';
import { Action, AllStates } from 'reducers/rootReducer';
import {
  fetchPrimaryDataThunk,
  fetchRowCountDataThunk,
  fetchSecondaryDataThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { fetchDatasetPreviewThunk } from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import {
  fetchFidoComputationData,
  fetchFidoEmbedReportBuilderView,
  fetchFidoViewData,
} from 'reducers/thunks/dashboardDataThunks/fetchFidoDataThunks';
import {
  fetchEmbeddedReportDataError,
  fetchEmbeddedRowCountError,
} from 'reportBuilderContent/actions/embeddedJobActions';
import {
  fetchEmbeddedReportDataApi,
  fetchEmbeddedReportRowCount,
} from 'reportBuilderContent/thunks/embeddedDataThunks';
import { sendErrorEventThunk } from 'telemetry/telemetryThunks';
import { get } from 'utils/standard';

const EMBED_DATA_ERROR_ACTIONS = new Set([
  fetchFidoComputationData.rejected.type,
  fetchPrimaryDataThunk.rejected.type,
  fetchSecondaryDataThunk.rejected.type,
  fetchRowCountDataThunk.rejected.type,
  fetchRowCountDataThunk.rejected.type,
  fetchDatasetPreviewThunk.rejected.type,
  fetchFidoViewData.rejected.type,
  fetchFidoEmbedReportBuilderView.rejected.type,
  fetchEmbeddedReportDataApi.rejected.type,
  fetchEmbeddedReportRowCount.rejected.type,
  fetchEmbeddedReportDataError.type,
  fetchEmbeddedRowCountError.type,
  dptActions.fetchDataPanelError.type,
  embedActions.embedFetchDataPanelError.type,
  embedActions.embedFetchDashboardDatasetPreviewError.type,
  embedActions.embedFetchSecondaryDataError.type,
  embedActions.embedFetchDataPanelRowCountError.type,
]);

export const telemetryMiddleware: Middleware<{}, AllStates> =
  ({ getState, dispatch }) =>
  (next: Dispatch<Action>) =>
  (action: Action) => {
    const response = next(action);
    if (EMBED_DATA_ERROR_ACTIONS.has(action.type)) {
      const error =
        get(action, 'error.message') || // For makeThunkRequest
        get(action, 'payload.error_msg') || // For Explore handleEnqueueError
        get(action, 'payload.error') || // For RB handleEnqueueError
        get(action, 'errorData'); // For runAction
      const dataPanelId =
        get(action, 'meta.arg.reducerArgs.dataPanelId') ||
        get(action, 'meta.arg.id') ||
        get(action, 'payload.postData.id');
      const datasetId = get(action, 'meta.arg.dataset_id');
      const variables = get(action, 'meta.arg.postData.variables');
      const state = getState();
      const dataPanelProvidedId =
        ('embedDashboard' in state &&
          state.embedDashboard.dashboardVersion?.configuration?.data_panels?.[dataPanelId]
            ?.provided_id) ||
        dataPanelId;

      sendErrorEventThunk(error, {
        data_panel_id: dataPanelProvidedId,
        dataset_id: datasetId,
        variables,
      })(dispatch, getState, {});
    }

    return response;
  };
