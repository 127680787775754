import cx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  deleteCurrentDashboardDraft,
  publishNewDashboardVersion,
  switchCurrentlyEditingDashboardVersion,
} from 'actions/dashboardV2Actions';
import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import {
  deleteCurrentReportBuilderDraft,
  publishNewReportBuilderVersion,
  ReportBuilderVersion,
} from 'actions/reportBuilderVersionActions';
import { ACTION } from 'actions/types';
import { TeamMember } from 'actions/userActions';
import { DeleteConfirmationButton } from 'components/DeleteConfirmationButton';
import { VersionComparisonModal } from 'components/VersionComparisonModal';
import { APP_PORTAL_ID, Button, Input, Modal, Spinner, sprinkles, TextArea } from 'components/ds';
import { DEFAULT_SUPPORT_EMAIL } from 'constants/emailConstants';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { getCurrentDashboard, getCurrentReportBuilder } from 'reducers/selectors';
import { cloneComputedViews } from 'reducers/thunks/fidoThunks';
import { createReportBuilderDraft } from 'reducers/thunks/resourceSaveThunks';
import * as RD from 'remotedata';
import { showWarningToast } from 'shared/sharedToasts';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { ResourcePageType } from 'types/exploResource';
import { getPermissionEntity } from 'utils/exploResourceUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { getUserInfoFromTeam } from 'utils/teamUtils';

import { VersionPanel } from './VersionPanel';

type Props = {
  closeModal: () => void;
  pageType: ResourcePageType;
};

type VersionComparisonModalConfiguration = {
  previousVersionNumber: number;
  currentVersionNumber: number;
};

const UNSET_VERSION_NUMBER = -1;

const UNUSET_VERSION_COMPARISON_CONFIGURATION: VersionComparisonModalConfiguration = {
  previousVersionNumber: UNSET_VERSION_NUMBER,
  currentVersionNumber: UNSET_VERSION_NUMBER,
};

export const VersionControlModal: FC<Props> = ({ closeModal, pageType }) => {
  const dispatch = useDispatch();
  const [draftChangeComment, setDraftChangeComment] = useState('');
  const [versionComparisonModalConfiguration, setVersionComparisonModalConfiguration] = useState({
    previousVersionNumber: UNSET_VERSION_NUMBER,
    currentVersionNumber: UNSET_VERSION_NUMBER,
  });

  const isExplore = pageType === ResourcePageType.EXPLORE;

  const {
    currentResourceId,
    resourceConfig,
    deleteLoading,
    publishLoading,
    tags,
    versions,
    viewingResourceVersion,
    permissions,
    shouldUseFido,
    teamData,
  } = useSelector((state: ReduxState) => {
    const sharedState = {
      tags: state.environmentTags.tags,
      permissions: state.currentUser.permissions,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      teamData: state.teamData.data,
    };

    if (isExplore) {
      return {
        ...sharedState,
        currentResourceId: getCurrentDashboard(state)?.id,
        resourceConfig: state.dashboardEditConfig.config,
        deleteLoading: createLoadingSelector([ACTION.DELETE_CURRENT_DASHBOARD_DRAFT], false)(state),
        publishLoading: createLoadingSelector([ACTION.PUBLISH_NEW_DASHBOARD_VERSION], false)(state),
        versions: state.dashboardVersions.versions,
        viewingResourceVersion: state.dashboardEditConfig.versionInfo,
      };
    } else {
      return {
        ...sharedState,
        currentResourceId: getCurrentReportBuilder(state)?.id,
        resourceConfig: RD.getOrDefault(state.reportBuilderEdit.config, undefined),
        deleteLoading: createLoadingSelector(
          [ACTION.DELETE_CURRENT_REPORT_BUILDER_DRAFT],
          false,
        )(state),
        publishLoading: createLoadingSelector(
          [ACTION.PUBLISH_NEW_REPORT_BUILDER_VERSION],
          false,
        )(state),
        versions: state.reportBuilderEdit.versions,
        viewingResourceVersion: state.reportBuilderEdit.versionInfo,
      };
    }
  }, shallowEqual);

  const versionIdToPublisherMap = useMemo(() => {
    const versionIdToPublishMap: Record<number, TeamMember | undefined> = {};
    versions.forEach((version) => {
      versionIdToPublishMap[version.id] = getUserInfoFromTeam(version.published_by_id, teamData);
    });
    return versionIdToPublishMap;
  }, [versions, teamData]);

  const mostRecentVersion = versions[0];
  const previousVersion = versions[1];

  const permissionResource = getPermissionEntity(pageType);

  const handlePublish = () => {
    if (!publishLoading && draftChangeComment) {
      if (currentResourceId === undefined || !resourceConfig) return;

      if (isExplore) {
        dispatch(
          publishNewDashboardVersion(
            {
              id: currentResourceId,
              postData: {
                config: resourceConfig as DashboardVersionConfig,
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => setDraftChangeComment(''),
          ),
        );
      } else {
        dispatch(
          publishNewReportBuilderVersion(
            {
              id: currentResourceId,
              postData: {
                config: resourceConfig as ReportBuilderConfig,
                version_number: mostRecentVersion.version_number,
                change_comments: draftChangeComment,
              },
            },
            () => {
              setDraftChangeComment('');

              if (!shouldUseFido) return;

              dispatch(
                cloneComputedViews({
                  configuration: resourceConfig,
                  onSuccess: (configuration) =>
                    dispatch(
                      createReportBuilderDraft({ config: configuration as ReportBuilderConfig }),
                    ),
                }),
              );
            },
          ),
        );
      }
    }
  };

  const handleDelete = () => {
    const args = {
      id: currentResourceId,
      postData: { version_number: mostRecentVersion.version_number },
    };
    const handleError = () => {
      showWarningToast(
        `There was an error deleting the current draft. Please try again and contact ${DEFAULT_SUPPORT_EMAIL} if the problem persists.`,
      );
    };
    if (isExplore) {
      dispatch(
        deleteCurrentDashboardDraft(
          args,
          () => {
            if (viewingResourceVersion?.version_number === mostRecentVersion.version_number)
              dispatch(
                switchCurrentlyEditingDashboardVersion({
                  dashboardVersion: versions[1] as DashboardVersion,
                }),
              );
          },
          handleError,
        ),
      );
    } else {
      dispatch(deleteCurrentReportBuilderDraft(args, undefined, handleError));
    }
  };

  if (!currentResourceId) return null;

  const renderVersionComparisonModal = () => {
    const comparisonCurrentVersionNumber = versionComparisonModalConfiguration.currentVersionNumber;
    const comparisonPreviousVersionNumber =
      versionComparisonModalConfiguration.previousVersionNumber;
    if (
      comparisonCurrentVersionNumber === UNSET_VERSION_NUMBER ||
      comparisonPreviousVersionNumber === UNSET_VERSION_NUMBER
    ) {
      return null;
    }

    // Have to do this ugly casting due to https://github.com/microsoft/TypeScript/issues/33591.
    const currentVersion = (versions as Array<DashboardVersion | ReportBuilderVersion>).find(
      (version) => version.version_number === comparisonCurrentVersionNumber,
    );
    const previousVersion = (versions as Array<DashboardVersion | ReportBuilderVersion>).find(
      (version: DashboardVersion | ReportBuilderVersion) =>
        version.version_number === comparisonPreviousVersionNumber,
    );

    if (currentVersion == undefined || previousVersion == undefined) return null;

    return (
      <VersionComparisonModal
        currentVersion={currentVersion}
        onCloseCallback={() =>
          setVersionComparisonModalConfiguration(UNUSET_VERSION_COMPARISON_CONFIGURATION)
        }
        previousVersion={previousVersion}
        resourceType={pageType}
      />
    );
  };

  return (
    <Modal
      isOpen
      onClose={closeModal}
      portalContainerId={APP_PORTAL_ID}
      size="large"
      title="Changelog">
      {versions.length > 0 ? (
        <div className={sprinkles({ paddingX: 'sp3' })}>
          {doesUserHavePermission(permissions[permissionResource], PERMISSIONED_ACTIONS.UPDATE) ? (
            mostRecentVersion.is_draft ? (
              <div className={draftContainerClass}>
                <div className={sprinkles({ heading: 'h4', marginBottom: 'sp1.5' })}>
                  Current Draft
                </div>
                <div
                  className={sprinkles({ display: 'flex', alignItems: 'flex-start', gap: 'sp1' })}>
                  <Input
                    disabled
                    defaultValue={`${mostRecentVersion.version_number}`}
                    label="Version"
                    onSubmit={() => null}
                    style={{ width: 70 }}
                  />

                  <TextArea
                    noResize
                    className={sprinkles({ flex: 1 })}
                    label="Description"
                    onChange={setDraftChangeComment}
                    placeholder="Describe what is different"
                    style={{ height: 72 }}
                    value={draftChangeComment}
                  />
                </div>
                <div
                  className={sprinkles({
                    flexItems: 'alignCenter',
                    paddingTop: 'sp2.5',
                    justifyContent: 'space-between',
                  })}>
                  <div />
                  <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
                    {previousVersion ? (
                      <Button
                        onClick={() =>
                          setVersionComparisonModalConfiguration({
                            previousVersionNumber: previousVersion.version_number,
                            currentVersionNumber: mostRecentVersion.version_number,
                          })
                        }>{`Compare to Version ${previousVersion.version_number}`}</Button>
                    ) : null}
                    <Button
                      disabled={!draftChangeComment || publishLoading}
                      loading={publishLoading}
                      onClick={handlePublish}
                      variant="primary">
                      Save
                    </Button>
                    {previousVersion &&
                    (!('deprecated' in previousVersion) || !previousVersion.deprecated) ? (
                      <DeleteConfirmationButton
                        fillWidth
                        loading={deleteLoading}
                        onDelete={handleDelete}>
                        Discard
                      </DeleteConfirmationButton>
                    ) : null}
                  </div>
                </div>
              </div>
            ) : (
              <div className={cx(draftContainerClass, sprinkles({ flexItems: 'center' }))}>
                No current draft
              </div>
            )
          ) : null}

          {versions.map((version, index) => {
            if (version.is_draft) return null;
            const previousVersion = index < versions.length ? versions[index + 1] : undefined;
            return (
              <VersionPanel
                canRevert={version.version_number !== mostRecentVersion.version_number}
                closeModal={closeModal}
                isDraft={mostRecentVersion.is_draft}
                key={`version-modal-version-${version.id}`}
                onSelectComparisonVersions={(previousVersionNumber, currentVersionNumber) =>
                  setVersionComparisonModalConfiguration({
                    previousVersionNumber,
                    currentVersionNumber,
                  })
                }
                pageType={pageType}
                previousVersion={previousVersion}
                publisher={versionIdToPublisherMap[version.id]}
                resourceId={currentResourceId}
                tags={tags}
                userPermissions={permissions[permissionResource]}
                version={version}
              />
            );
          })}
        </div>
      ) : (
        <Spinner fillContainer size="lg" />
      )}
      {renderVersionComparisonModal()}
    </Modal>
  );
};

const draftContainerClass = sprinkles({
  backgroundColor: 'elevationMid',
  padding: 'sp1.5',
  borderRadius: 4,
  marginBottom: 'sp1.5',
});
