import { Intent as BlueprintIntent } from '@blueprintjs/core';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Intent, sprinkles, Tag } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import {
  clearSavingReducer,
  SaveStatus,
  setResourceSaveStatus,
} from 'reducers/resourceSavingReducer';
import {
  areAnySaveRequestsRequestedOrInflight,
  areThereUnsavedChanges,
  getResourceIdsWithQueuedUnsavedChanges,
  hasErrorInSavingResource,
  isSaveRequestInFlight,
} from 'reducers/resourceSavingUtils';
import { ReduxState } from 'reducers/rootReducer';
import {
  saveRequestedResourceConfigs,
  saveResourceConfig,
} from 'reducers/thunks/resourceSaveThunks';
import { showCustomToast } from 'shared/sharedToasts';
import { INTERNAL_EVENT } from 'types/customEventTypes';
import { ResourcePageType } from 'types/exploResource';
import { createDebouncedFn } from 'utils/general';

const debounceFn = createDebouncedFn(2000);

type Props = { resourceType: ResourcePageType; versionNumber: number; isDraft: boolean };

export const SavingInfo: FC<Props> = ({ resourceType, versionNumber, isDraft }) => {
  const dispatch = useDispatch();

  const savingInfo = useSelector((state: ReduxState) => state.resourceSaving);

  useEffect(() => {
    return () => {
      dispatch(clearSavingReducer());
    };
  }, [dispatch]);

  const resourceIdsWithQueuedUnsavedChanges = useMemo(
    () => getResourceIdsWithQueuedUnsavedChanges(savingInfo.resourceSaveStatuses),
    [savingInfo.resourceSaveStatuses],
  );
  useEffect(() => {
    for (const resourceId of resourceIdsWithQueuedUnsavedChanges) {
      dispatch(saveResourceConfig(resourceType, resourceId));
    }
  }, [dispatch, resourceType, resourceIdsWithQueuedUnsavedChanges]);

  const saveConfigChangesListenerFn = useCallback(
    (event) => {
      const resourceId = event.detail.resourceId;
      if (isSaveRequestInFlight(savingInfo.resourceSaveStatuses[resourceId])) {
        dispatch(
          setResourceSaveStatus({
            resourceId,
            resourceSaveStatus: SaveStatus.REQUEST_IN_FLIGHT_HAD_ADDITIONAL_CHANGES,
          }),
        );
        return;
      }
      dispatch(setResourceSaveStatus({ resourceId, resourceSaveStatus: SaveStatus.REQUESTED }));
      debounceFn(() => dispatch(saveRequestedResourceConfigs(resourceType)));
    },
    [dispatch, resourceType, savingInfo.resourceSaveStatuses],
  );

  useEffect(() => {
    window.addEventListener(INTERNAL_EVENT.SAVE_RESOURCE_CONFIG, saveConfigChangesListenerFn);
    return () => {
      window.removeEventListener(INTERNAL_EVENT.SAVE_RESOURCE_CONFIG, saveConfigChangesListenerFn);
    };
  }, [saveConfigChangesListenerFn]);

  useEffect(() => {
    if (!savingInfo.errorMessage) return;
    showCustomToast(savingInfo.errorMessage || '', {
      timeoutInSeconds: 5,
      icon: 'error',
      intent: BlueprintIntent.DANGER,
      action: {
        target: '_blank',
        icon: 'refresh',
        text: 'Refresh',
        onClick: () => window.location.reload(),
      },
    });
  }, [savingInfo.errorMessage]);

  const alertUser = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!areThereUnsavedChanges(savingInfo.resourceSaveStatuses)) return;
      event.preventDefault();
      event.returnValue = '';
    },
    [savingInfo],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  let icon: IconName = 'check';
  if (areAnySaveRequestsRequestedOrInflight(savingInfo.resourceSaveStatuses)) {
    icon = 'arrows-rotate';
  } else if (hasErrorInSavingResource(savingInfo.resourceSaveStatuses)) {
    icon = 'circle-exclamation';
  }

  return (
    <Tag className={sprinkles({ marginRight: 'sp1' })} intent={Intent.NONE} leftIcon={icon}>
      {`Version: ${versionNumber}${isDraft ? ' (draft)' : ''}`}
    </Tag>
  );
};
