import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import { CSSProperties, FC, MouseEventHandler, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { BuiltInReportConfig } from 'actions/reportBuilderConfigActions';
import { CustomerPermissionsSummary } from 'components/CustomerPermissions/Summary';
import { Icon, sprinkles, Tooltip } from 'components/ds';
import { DEFAULT_DELAY, Props as TooltipProps } from 'components/ds/Tooltip';
import * as styles from 'pages/ReportBuilderEditor/BuiltIns/BuiltInListItem.css';
import { BuiltInMenu } from 'pages/ReportBuilderEditor/BuiltIns/BuiltInMenu';
import { deleteBuiltInReport, duplicateBuiltInReport } from 'reducers/reportBuilderEditReducer';
import { selectBuiltInForEdit } from 'reducers/thunks/reportBuilderEditorThunks';
import { openModalType } from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportModalType } from 'reportBuilderContent/reducers/types';

type Props = {
  selectedBuiltInId?: string;
  builtIn: BuiltInReportConfig;
};

export const BuiltInListItem: FC<Props> = ({ builtIn, selectedBuiltInId }) => {
  const dispatch = useDispatch();
  const itemRef = useRef<HTMLDivElement>(null);
  const isSelected = selectedBuiltInId === builtIn.id;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: builtIn.id,
  });
  const style: CSSProperties = { transform: CSS.Transform.toString(transform), transition };

  const onClick: MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.defaultPrevented) return;
    dispatch(selectBuiltInForEdit(builtIn));
  };

  const onDuplicate = () => dispatch(duplicateBuiltInReport(builtIn));
  const onDelete = () => dispatch(deleteBuiltInReport(builtIn.id));

  const onEdit = () => {
    dispatch(selectBuiltInForEdit(builtIn));
    dispatch(openModalType({ type: ReportModalType.EDIT_BUILT_IN }));
  };

  useEffect(() => {
    // Auto-scroll to the currently selected Item
    if (isSelected)
      itemRef.current?.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
  }, [isSelected]);

  return (
    <div
      className={cx(
        styles.builtInItem,
        isSelected ? styles.builtInItemActive : styles.builtInItemInactive,
      )}
      onClick={onClick}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      <div className={styles.builtInSummary}>
        <div className={styles.itemHeading} ref={itemRef}>
          <Tooltip {...TOOLTIP_PROPS} text={builtIn.name}>
            <span className={sprinkles({ heading: 'h4', truncateText: 'ellipsis' })}>
              {builtIn.name}
            </span>
          </Tooltip>
        </div>

        {builtIn.description ? (
          <Tooltip {...TOOLTIP_PROPS} text={builtIn.description}>
            <span className={styles.itemDescription}>{builtIn.description}</span>
          </Tooltip>
        ) : null}

        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          {builtIn.config.dataInfo?.datasetId ? null : (
            <Tooltip {...TOOLTIP_PROPS} text="Missing dataset. Not visible to customers">
              <Icon className={sprinkles({ color: 'contentSecondary' })} name="eye-closed" />
            </Tooltip>
          )}
          <CustomerPermissionsSummary permissions={builtIn.permissions} />
        </div>
      </div>
      <BuiltInMenu onDelete={onDelete} onDuplicate={onDuplicate} onEdit={onEdit} />
    </div>
  );
};

const TOOLTIP_PROPS: Partial<TooltipProps> = {
  className: sprinkles({ zIndex: 'base' }),
  delayDuration: DEFAULT_DELAY,
  side: 'right',
};

type BuiltInListItemOverlayProps = { builtIn: BuiltInReportConfig };

export const BuiltInListItemOverlay: FC<BuiltInListItemOverlayProps> = ({ builtIn }) => {
  return (
    <div className={cx(styles.builtInItem, styles.builtInItemActive)}>
      <div className={styles.itemHeading}>
        <span className={sprinkles({ heading: 'h4', truncateText: 'ellipsis' })}>
          {builtIn.name}
        </span>
      </div>
      {builtIn.description && <span className={styles.itemDescription}>{builtIn.description}</span>}
      <CustomerPermissionsSummary permissions={builtIn.permissions} />
    </div>
  );
};
