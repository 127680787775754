import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { activateDisabledDashboard, Dashboard, deleteDashboard } from 'actions/dashboardActions';
import { APP_PORTAL_ID, AlertModal, IconButton, Menu, MenuActionItem } from 'components/ds';
import { DELETE_MODAL_CONFIRMATION_TEXT } from 'pages/constants';
import { trackEvent, EVENTS } from 'telemetry/exploAnalytics';

import { ActivateDisabledDashboardModal } from './ActivateDisabledDashboardModal';

type Props = {
  dashboard: Dashboard;
  dashboardList: Dashboard[];
  setLoadingStateForDashboard: (isLoading: boolean) => void;
};

export const DisabledDashboardMenu: FC<Props> = ({
  dashboard,
  dashboardList,
  setLoadingStateForDashboard,
}) => {
  const dispatch = useDispatch();

  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [deleteDashboardModalOpen, setDeleteDashboardModalOpen] = useState(false);

  const onDeleteDashboard = () => {
    setLoadingStateForDashboard(true);
    setDeleteDashboardModalOpen(false);
    dispatch(
      deleteDashboard({ id: dashboard.id }, () => {
        setLoadingStateForDashboard(false);
        trackEvent(EVENTS.DELETED_DASHBOARD, {
          dashboard_template_id: dashboard.id,
        });
      }),
    );
  };
  return (
    <>
      <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />}>
        <MenuActionItem
          iconName="refresh"
          onSelect={() => setActivateModalOpen(true)}
          text="Activate"
        />
        <MenuActionItem
          isDestructive
          iconName="trash"
          onSelect={() => setDeleteDashboardModalOpen(true)}
          text="Delete"
        />
      </Menu>
      <ActivateDisabledDashboardModal
        activateDashboard={(disableDashboardId) =>
          dispatch(
            activateDisabledDashboard({
              id: dashboard.id,
              postData: { disable_dashboard_id: disableDashboardId },
            }),
          )
        }
        closeModal={() => setActivateModalOpen(false)}
        dashboardList={dashboardList}
        modalOpen={activateModalOpen}
        selectedDashboardId={dashboard.id}
      />
      <AlertModal
        actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteDashboard }}
        confirmationText={DELETE_MODAL_CONFIRMATION_TEXT}
        isOpen={deleteDashboardModalOpen}
        onClose={() => setDeleteDashboardModalOpen(false)}
        portalContainerId={APP_PORTAL_ID}
        title={`Are you sure you want to delete ${dashboard.name}? Type ${DELETE_MODAL_CONFIRMATION_TEXT} to confirm.`}
      />
    </>
  );
};
