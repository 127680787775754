import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Switch, sprinkles } from 'components/ds';
import { updateEditableSectionSettings } from 'reducers/dashboardEditConfigReducer';
import { EditableSectionSettings } from 'types/dashboardVersionConfig';

type Props = { settings: EditableSectionSettings };

export const SectionSettings: FC<Props> = ({ settings }) => {
  const dispatch = useDispatch();

  const isTooltipOn = settings.tooltipText !== undefined;

  return (
    <>
      <Input
        showInputButton
        className={sprinkles({ marginBottom: 'sp2' })}
        defaultValue={settings.title}
        label={{ text: 'Section title', variableInput: true }}
        onSubmit={(newTitle) => dispatch(updateEditableSectionSettings({ title: newTitle }))}
      />
      <Switch
        label="Descriptive Tooltip"
        onChange={() =>
          dispatch(updateEditableSectionSettings({ tooltipText: isTooltipOn ? undefined : '' }))
        }
        switchOn={isTooltipOn}
      />
      {isTooltipOn ? (
        <Input
          showInputButton
          className={sprinkles({ marginTop: 'sp.5' })}
          defaultValue={settings.tooltipText}
          onSubmit={(tooltipText) => dispatch(updateEditableSectionSettings({ tooltipText }))}
        />
      ) : null}
    </>
  );
};
