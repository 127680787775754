import { EnabledDataSourcesMultiselect } from 'pages/SuperuserPage/EnabledDataSourcesMultiselect';

import * as styles from './index.css';

export interface enabledDataSourcesInputProps {
  enabledDataSources: string[];
  onChange: (enabledDataSources: string[]) => void;
}

export const EnabledDataSourcesInput: React.FC<enabledDataSourcesInputProps> = ({
  enabledDataSources,
  onChange,
}) => {
  return (
    <div className={styles.enabledDataSourcesInput}>
      <EnabledDataSourcesMultiselect enabledDataSources={enabledDataSources} onChange={onChange} />
      <div className={styles.enabledDataSourcesInputHelpText}>
        Leave empty to allow all fido data sources
      </div>
    </div>
  );
};
