import { FC } from 'react';

import { Button, sprinkles } from 'components/ds';
import { ResourceBaseInfo } from 'utils/resourceVersionComparator';

interface Props {
  resource: ResourceBaseInfo;
  shouldRenderDetailedComparisonButton: boolean;
  onViewVersionButtonClickedFn: (resourceItemId: string) => void;
}

export const ResourceItemComparisonComponent: FC<Props> = ({
  resource,
  shouldRenderDetailedComparisonButton,
  onViewVersionButtonClickedFn,
}) => {
  return (
    <div
      className={sprinkles({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginY: 'sp1.5',
      })}>
      <div>
        <span className={sprinkles({ paddingRight: 'sp1' })}>{resource.typePrefix}</span>
        <span className={sprinkles({ color: 'contentSecondary' })}>{resource.name} </span>
      </div>
      {shouldRenderDetailedComparisonButton ? (
        <Button linkIcon={true} onClick={() => onViewVersionButtonClickedFn(resource.id)}>
          View version differences
        </Button>
      ) : null}
    </div>
  );
};
