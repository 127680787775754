import { AuthMethods } from 'actions/mfaActions';
import { Button, sprinkles, Switch } from 'components/ds';
import * as styles from 'pages/settingsPage/SecuritySection/index.css';
import { MethodState } from 'reducers/mfaReducer';
import { titleCase } from 'utils/graphUtils';

type MfaMethodRowProps = {
  id: AuthMethods;
  name: string;
  method: MethodState;
  onClick: (id: AuthMethods) => void;
  numActiveMethods: number;
  onChange: (id: AuthMethods, active: boolean) => void;
};

export function MfaMethodRow({
  id,
  numActiveMethods,
  method: { isActive, isPrimary },
  name,
  onChange,
  onClick,
}: MfaMethodRowProps) {
  const canToggleActive = isActive && (numActiveMethods === 1 || isPrimary);

  return (
    <div className={methodRowClassName}>
      <div className={styles.paragraph}>{titleCase(name)}</div>
      <div className={styles.row}>
        {isActive ? (
          <Button disabled={isPrimary} onClick={() => onClick(id)} variant="secondary">
            {isPrimary ? 'Primary' : 'Set as Primary'}
          </Button>
        ) : null}
        <Switch
          disabled={canToggleActive}
          label={{
            text: '',
            infoText: canToggleActive ? 'Primary method cannot be deactivated' : undefined,
          }}
          onChange={(value) => onChange(id, value)}
          switchOn={isActive}
        />
      </div>
    </div>
  );
}

const methodRowClassName = sprinkles({
  flexItems: 'alignCenter',
  justifyContent: 'space-between',
  gap: 'sp1',
});
