import cx from 'classnames';
import { FC, useContext, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { GlobalStylesContext } from 'globalStyles';
import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';
import { onNewDataPanelsAddedThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { PAGE_TYPE } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getDatasetNamesToId } from 'utils/datasetUtils';

import * as styles from './PreviewChart.css';

type Props = {
  dataPanel: DataPanelTemplate;
};

// Need to pass id in DashboardLayoutContext for portal id for chart popovers and menus
const previewContainerId = 'preview-container';

export const PreviewChart: FC<Props> = ({ dataPanel }) => {
  const dispatch = useDispatch();

  const { variables, datasets } = useSelector(
    (state: ReduxState) => ({
      variables: state.dashboardData.variables,
      datasets: getEditableDatasets(state),
    }),
    shallowEqual,
  );

  const datasetNamesToId = useMemo(() => getDatasetNamesToId(datasets), [datasets]);

  const dataPanelRef = useRef(dataPanel);

  useEffect(() => void (dataPanelRef.current = dataPanel), [dataPanel]);

  useEffect(() => {
    // Fetch data on mount even if loading/ loaded already (in case dependent variables have changed)
    // TODO: Fetch data more efficiently (probably in middleware)
    dispatch(onNewDataPanelsAddedThunk([dataPanelRef.current]));
  }, [dispatch]);

  const { globalStylesClassName, globalStyleVars } = useContext(GlobalStylesContext);

  return (
    <div className={styles.previewContainer}>
      <div className={styles.header}>Preview</div>
      <div
        className={cx(styles.chartContainer, globalStylesClassName)}
        id={previewContainerId}
        style={globalStyleVars}>
        <DashboardLayoutContext.Provider value={{ dashboardLayoutTagId: previewContainerId }}>
          <DashboardDatasetView
            editableChartPreview
            editableDashboard
            canDownloadDataPanel={false}
            dataPanel={dataPanel}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets}
            isViewOnly={false}
            pageType={PAGE_TYPE.EXPLO_APP}
            variables={variables ?? {}}
          />
        </DashboardLayoutContext.Provider>
      </div>
    </div>
  );
};
