import { FC, useState } from 'react';

import { Checkbox, sprinkles } from 'components/ds';
import { ChartColumnInfo } from 'constants/types';

interface Props {
  columnInfo: ChartColumnInfo;
}

/**
 * Component that allows for configuring a particular column on a data panel to columns within
 * datasets for drilldowns.
 */
export const ColumnTargetSection: FC<Props> = ({ columnInfo }) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <>
      <div className={sprinkles({ flexItems: 'alignCenterBetween' })}>
        <Checkbox isChecked={isChecked} onChange={() => setIsChecked(!isChecked)} />
        <div>{columnInfo.friendly_name}</div>
      </div>
    </>
  );
};
