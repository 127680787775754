import { FC } from 'react';

import { IconButton, sprinkles, Tooltip } from 'components/ds';
import { DateRangePresetConfig } from 'types/dashboardTypes';

type Props = {
  value: DateRangePresetConfig;
  onEdit: () => void;
  onDelete: () => void;
};

export const DatePresetItem: FC<Props> = ({ value, onEdit, onDelete }) => (
  <div className={itemClassName}>
    <Tooltip text={value.name}>
      <span className={headingClassName}>{value.name}</span>
    </Tooltip>

    <div className={actionRowClassName}>
      <IconButton name="pencil" onClick={onEdit} />
      <IconButton name="trash" onClick={onDelete} variant="destructive" />
    </div>
  </div>
);

const headingClassName = sprinkles({ body: 'b2', flex: 1, truncateText: 'ellipsis' });

const actionRowClassName = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
});

const itemClassName = sprinkles({
  flexItems: 'alignCenter',
  gap: 'sp1',
  padding: 'sp1',
  border: 1,
  borderColor: 'outline',
  borderRadius: 4,
});
