import cx from 'classnames';
import produce from 'immer';
import { FC, useMemo } from 'react';

import { Dataset } from 'actions/datasetActions';
import { Select, Switch, sprinkles } from 'components/ds';
import {
  TableJoinColumnConfig,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import { DatasetColumn } from 'types/datasets';
import { createColSelectOptionsWithIcon } from 'utils/general';
import { keyBy } from 'utils/standard';

type Props = {
  column: DatasetColumn;
  instructions: VisualizeTableInstructions | VisualizePivotTableInstructions;
  updateInstructions: (
    newInstructions: VisualizeTableInstructions | VisualizePivotTableInstructions,
  ) => void;
  dashboardDatasets: Record<string, Dataset>;
};

const configInputClass = sprinkles({ marginTop: 'sp1' });

export const EnrichColumnConfiguration: FC<Props> = ({
  column,
  instructions,
  updateInstructions,
  dashboardDatasets,
}) => {
  const config = instructions.schemaDisplayOptions?.[column.name];

  const savedDatasets = Object.values(dashboardDatasets).filter((dataset) => !!dataset.schema);
  const selectedDataset = config?.joinTable && dashboardDatasets[config.joinTable.id];

  const schemaByName = selectedDataset?.schema ? keyBy(selectedDataset.schema, 'name') : {};
  const selectedJoinColumn = config?.joinColumn && schemaByName[config.joinColumn.name];

  const updateSchemaDisplayOptions = (updates: TableJoinColumnConfig) => {
    const newInstructions = produce(instructions, (draft) => {
      if (!draft.schemaDisplayOptions) return;
      draft.schemaDisplayOptions[column.name] = {
        ...draft.schemaDisplayOptions[column.name],
        ...updates,
      };
    });

    updateInstructions(newInstructions);
  };

  const options = useMemo(
    () => createColSelectOptionsWithIcon(selectedDataset?.schema),
    [selectedDataset],
  );

  return (
    <div className={sprinkles({ width: 'fill' })}>
      <Switch
        className={configInputClass}
        label={{
          text: 'Join',
          infoText:
            'This config option will soon be deprecated. Explo does not recommend users to use this anymore. If you are currently using this config, the Explo team will reach out to you individually to migrate off of it. Please reach out to Explo support if you have any questions.',
        }}
        onChange={() => updateSchemaDisplayOptions({ joinOn: !config?.joinOn })}
        switchOn={config?.joinOn}
      />

      {config?.joinOn && (
        <>
          <Select
            className={configInputClass}
            label="Dataset"
            onCancel={() => updateSchemaDisplayOptions({ joinTable: undefined })}
            onChange={(datasetId) => {
              const dataset = dashboardDatasets[datasetId];
              if (!dataset) return;
              updateSchemaDisplayOptions({ joinTable: { id: datasetId } });
            }}
            placeholder="Select dataset"
            selectedValue={selectedDataset?.id}
            values={savedDatasets.map((dataset) => ({
              value: String(dataset.id),
              label: dataset.table_name || 'Untitled',
            }))}
          />
          <Select
            className={configInputClass}
            disabled={selectedDataset === undefined}
            label="Join Column"
            onCancel={() => updateSchemaDisplayOptions({ joinColumn: undefined })}
            onChange={(colName) => updateSchemaDisplayOptions({ joinColumn: { name: colName } })}
            placeholder="Select a column"
            selectedValue={config.joinColumn?.name}
            values={options}
          />
          <Select
            className={cx(configInputClass, sprinkles({ paddingBottom: 'sp1.5' }))}
            disabled={selectedDataset === undefined || selectedJoinColumn === undefined}
            label="Display Column"
            onCancel={() => updateSchemaDisplayOptions({ joinDisplayColumn: undefined })}
            onChange={(colName) =>
              updateSchemaDisplayOptions({
                joinDisplayColumn: {
                  name: colName,
                  column: { type: schemaByName[colName].type },
                },
              })
            }
            placeholder="Select a column"
            selectedValue={config.joinDisplayColumn?.name}
            values={options}
          />
        </>
      )}
    </div>
  );
};
