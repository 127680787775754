import { SaveStatus } from 'reducers/resourceSavingReducer';

export const areAnySaveRequestsRequestedOrInflight = (
  resourceSaveStatuses: Record<number, SaveStatus>,
) => {
  return Object.values(resourceSaveStatuses).some(isSaveRequestRequestedOrInflight);
};

const isSaveRequestRequestedOrInflight = (resourceSave: SaveStatus) =>
  isSaveRequestInFlight(resourceSave) || resourceSave === SaveStatus.REQUESTED;

export const isSaveRequestInFlight = (resourceSaveStatus: SaveStatus) =>
  resourceSaveStatus === SaveStatus.REQUEST_IN_FLIGHT ||
  resourceSaveStatus === SaveStatus.REQUEST_IN_FLIGHT_HAD_ADDITIONAL_CHANGES;

export const areThereUnsavedChanges = (resourceSaveStatuses: Record<number, SaveStatus>) => {
  return Object.values(resourceSaveStatuses).some(resourceHasUnsavedChanges);
};

export const resourceHasUnsavedChanges = (resourceSaveStatus: SaveStatus) =>
  resourceSaveStatus === SaveStatus.REQUESTED ||
  resourceSaveStatus === SaveStatus.REQUEST_IN_FLIGHT ||
  resourceSaveStatus === SaveStatus.REQUEST_IN_FLIGHT_HAD_ADDITIONAL_CHANGES ||
  resourceSaveStatus === SaveStatus.FAILED;

export const getResourceIdsWithQueuedUnsavedChanges = (
  resourceSaveStatuses: Record<number, SaveStatus>,
) => {
  return Object.entries(resourceSaveStatuses)
    .filter(
      ([, resourceSaveStatus]) =>
        resourceSaveStatus === SaveStatus.SUCCEEDED_HAS_ADDITIONAL_CHANGES_TO_SAVE,
    )
    .map(([resourceId]) => parseInt(resourceId));
};

export const getResourceIdsRequestedToSave = (resourceSaveStatuses: Record<number, SaveStatus>) => {
  return Object.entries(resourceSaveStatuses)
    .filter(([, resourceSaveStatus]) => resourceSaveStatus === SaveStatus.REQUESTED)
    .map(([resourceId]) => parseInt(resourceId));
};

export const hasErrorInSavingResource = (resourceSaveStatuses: Record<number, SaveStatus>) => {
  return Object.values(resourceSaveStatuses).some((status) => status === SaveStatus.FAILED);
};
