import * as ProgressBar from '@radix-ui/react-progress';
import * as Switch from '@radix-ui/react-switch';
import cx from 'classnames';
import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { logOutUser } from 'actions/authAction';
import { updateConversationStarted } from 'actions/chatWidgetActions';
import { fetchUserTeam } from 'actions/teamActions';
import { Icon, Tooltip, Menu, MenuActionItem, sprinkles } from 'components/ds';
import { TRIAL_STATUS } from 'constants/paymentPlanConstants';
import { ROUTES } from 'constants/routes';
import { SETTINGS_SUB_SECTION, SUB_SECTION_ROUTE_MAP } from 'constants/settingsPageConstants';
import { setIsSidebarOpen } from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { isEnvironmentSecure } from 'utils/environmentUtils';
import { onWidgetClick } from 'utils/hubspotUtils';
import { numDaysLeftInPlan } from 'utils/paymentPlanUtils';

import { SidebarItem, NavItemInfo } from './SidebarItem';
import {
  CHAT_TEXT,
  DOCS_TEXT,
  NUM_DAYS_IN_TRIAL,
  EXPANDED_STORAGE_KEY,
  TOOLTIP_SIDE,
  TOOLTIP_SIDE_OFFSET,
} from './constants';
import { EXPLO_HOMEPAGE_LOGO } from './icons';
import * as styles from './styles.css';
import { getOrderedProductItems, getOrderedSetupItems, getUserInitials } from './utils';

type Props = {
  activeItemId?: string;
};

export const Sidebar: FC<Props> = ({ activeItemId }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { currentUser, widget, teamData } = useSelector(
    (state: ReduxState) => ({
      currentUser: state.currentUser,
      widget: state.widget,
      teamData: state.teamData.data,
    }),
    shallowEqual,
  );

  const [isExpanded, setIsExpanded] = useLocalStorage(EXPANDED_STORAGE_KEY, true);

  useEffect(() => {
    const dispatchUpdate = () => {
      dispatch(updateConversationStarted({ conversationStarted: true }));
    };
    if (window.HubSpotConversations) {
      window.HubSpotConversations.on('conversationStarted', dispatchUpdate);

      return () => window.HubSpotConversations.off('conversationStarted', dispatchUpdate);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!teamData) dispatch(fetchUserTeam());
  }, [teamData, dispatch]);

  useEffect(() => {
    dispatch(setIsSidebarOpen(isExpanded));
  }, [dispatch, isExpanded]);

  const sidebarItemStyle = {
    [styles.sidebarItemOpen]: isExpanded,
    [styles.sidebarItemClosed]: !isExpanded,
  };

  const renderLogoSection = () => {
    return (
      <div className={styles.sidebarLogoContainer}>
        <div className={styles.sidebarLogoItems}>
          <div className={styles.sidebarLogo}>
            <Icon
              className={styles.hamburgerMenu}
              name="bars"
              onClick={() => setIsExpanded(!isExpanded)}
            />
            {isExpanded ? (
              <Link to="/home">
                <div className={sprinkles({ display: 'flex' })}>{EXPLO_HOMEPAGE_LOGO()}</div>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderTrialTag = () => {
    const daysLeftInPlan = numDaysLeftInPlan(currentUser.team?.trial_end_date);
    if (currentUser.team?.trial_status !== TRIAL_STATUS.IN_PROGRESS || daysLeftInPlan <= 0)
      return null;

    const trialText = `${daysLeftInPlan} day${
      daysLeftInPlan > 1 ? 's' : ''
    } remaining on your trial`;

    return (
      <Link className={styles.tagLink} to={ROUTES.BILLING_PAGE}>
        <div className={styles.trialTag}>
          <p className={sprinkles({ margin: 'sp0', body: 'b2' })}>{trialText}</p>
          <ProgressBar.Root className={styles.progressBarRoot} value={daysLeftInPlan}>
            <ProgressBar.Indicator
              className={styles.progressBarIndicator}
              style={{ transform: `translateX(-${(daysLeftInPlan / NUM_DAYS_IN_TRIAL) * 100}%)` }}
            />
          </ProgressBar.Root>
        </div>
      </Link>
    );
  };

  const renderChat = () => {
    return (
      <div
        className={cx(styles.hoverableItem, sidebarItemStyle)}
        onClick={() => onWidgetClick(widget.widgetType, widget.isOpen, dispatch)}>
        <div className={styles.sidebarItemIconName}>
          <Icon className={styles.leftIcon} name={widget.isOpen ? 'messages' : 'messages-reg'} />
          {isExpanded ? <p className={styles.itemText}>{CHAT_TEXT}</p> : null}
        </div>
        {isExpanded ? (
          <div className={styles.rightIcon}>
            <Switch.Root checked={widget.isOpen} className={styles.switchRoot}>
              <Switch.Thumb className={styles.switchThumb} />
            </Switch.Root>
          </div>
        ) : null}
      </div>
    );
  };

  const renderDocs = () => {
    return (
      <a
        className={cx(styles.hoverableItem, sidebarItemStyle)}
        href="https://docs.explo.co/"
        rel="noopener noreferrer"
        target="_blank">
        <div className={styles.sidebarItemIconName}>
          <Icon className={styles.leftIcon} name="file-reg" />
          {isExpanded ? <p className={styles.itemText}>{DOCS_TEXT}</p> : null}
        </div>
        {isExpanded ? (
          <div className={styles.rightIcon}>
            <Icon className={sprinkles({ color: 'blue7' })} name="arrow-up-right" />
          </div>
        ) : null}
      </a>
    );
  };

  const renderItemsSection = () => {
    const orderedSetupItems = getOrderedSetupItems(currentUser, teamData);
    const orderedProductItems = getOrderedProductItems(currentUser);

    const renderItems = (sectionTitle: string, itemList: NavItemInfo[]) => {
      return (
        <>
          <p className={styles.sidebarHeading}>{sectionTitle}</p>
          {itemList.map((item) => (
            <SidebarItem
              isExpanded={isExpanded}
              isSelected={activeItemId === item.id}
              key={item.id}
              navItemInfo={item}
            />
          ))}
        </>
      );
    };

    return (
      <div className={styles.sidebarContainer} data-testid="sidebarSection">
        <div
          className={
            isExpanded ? styles.sidebarSectionContainerOpen : styles.sidebarSectionContainerClosed
          }>
          {renderItems('Setup', orderedSetupItems)}
          {renderItems('Products', orderedProductItems)}
        </div>
        <div className={styles.sidebarBottomContainer}>
          {isExpanded ? <div className={styles.tagContainer}>{renderTrialTag()}</div> : null}
          <div
            className={
              isExpanded ? styles.sidebarSectionContainerOpen : styles.sidebarSectionContainerClosed
            }>
            {!isEnvironmentSecure() ? (
              isExpanded ? (
                renderChat()
              ) : (
                <Tooltip side={TOOLTIP_SIDE} sideOffset={TOOLTIP_SIDE_OFFSET} text={CHAT_TEXT}>
                  {renderChat()}
                </Tooltip>
              )
            ) : null}
            {isExpanded ? (
              renderDocs()
            ) : (
              <Tooltip side={TOOLTIP_SIDE} sideOffset={TOOLTIP_SIDE_OFFSET} text={DOCS_TEXT}>
                {renderDocs()}
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderAccountSection = () => {
    const renderUserInfoContent = () => {
      return (
        <div className={styles.sidebarAccountContainer}>
          <div className={styles.sidebarAccountInfo}>
            <div className={styles.avatarStyle} data-testid="profileIcon">
              <div className={sprinkles({ color: 'white' })}>{getUserInitials(currentUser)}</div>
            </div>
            {isExpanded ? (
              <div className={styles.sidebarNameEmail}>
                <p className={styles.sidebarAccountName}>
                  {currentUser.first_name + ' ' + currentUser.last_name}
                </p>
                <p className={styles.sidebarAccountEmail}> {currentUser.email}</p>
              </div>
            ) : null}
          </div>
          {isExpanded ? (
            <Icon className={sprinkles({ color: 'blue7' })} name="chevron-right" />
          ) : null}
        </div>
      );
    };

    return (
      <Menu align="end" side="right" trigger={<span>{renderUserInfoContent()}</span>} width="small">
        <MenuActionItem
          iconName="gear"
          onSelect={() => {
            history.push(`/settings/${SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.PROFILE]}`);
          }}
          text="Settings"
        />
        <MenuActionItem
          iconName="right-arrow-from-bracket"
          onSelect={() => dispatch(logOutUser())}
          text="Log Out"
        />
      </Menu>
    );
  };

  return (
    <div className={isExpanded ? styles.sidebarOpen : styles.sidebarClosed}>
      {renderLogoSection()}
      {renderItemsSection()}
      {renderAccountSection()}
    </div>
  );
};
