import { combineReducers, Reducer } from 'redux';

import { ACTION } from 'actions/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import loadingReducer from 'reducers/api/loadingReducer';
import { authReducer } from 'reducers/authReducer';
import { customersReducer } from 'reducers/customersReducer';
import { dashboardEditConfigReducer } from 'reducers/dashboardEditConfigReducer';
import { dashboardReducer } from 'reducers/dashboardReducer';
import { dashboardVersionsReducer } from 'reducers/dashboardVersionsReducer';
import { dataSourceReducer } from 'reducers/dataSourceReducer';
import datasetsReducer from 'reducers/datasetsReducer';
import { embedDashboardReducer } from 'reducers/embedDashboardReducer';
import { environmentTagsReducer } from 'reducers/environmentTagReducer';
import { mfaReducer } from 'reducers/mfaReducer';
import { parentSchemasReducer } from 'reducers/parentSchemaReducer';
import { styleConfigReducer } from 'reducers/styleConfigReducer';
import teamDataReducer from 'reducers/teamDataReducer';
import { reportAiReducer } from 'reportBuilderContent/reducers/reportAiReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { telemetryReducer } from 'telemetry/telemetryReducer';

import { embeddedReportBuilderReducer } from '../reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { reportBuilderJobReducer } from '../reportBuilderContent/reducers/reportBuilderJobReducer';
import { reportEditingReducer } from '../reportBuilderContent/reducers/reportEditingReducer';

import { aiReducer } from './aiReducer';
import { allowlistDomainReducer } from './allowlistDomainReducer';
import chatWidgetReducer from './chatWidgetReducer';
import cssLinkingReducer from './cssLinkingReducer';
import { customFontsReducer } from './customFontsReducer';
import { dashboardAttributesReducer } from './dashboardAttributesReducer';
import { dashboardDataReducer } from './dashboardDataReducer';
import { dashboardInteractionsReducer } from './dashboardInteractionsReducer';
import { dashboardLayoutReducer } from './dashboardLayoutReducer';
import { dashboardStylesReducer } from './dashboardStylesReducer';
import { emailCadenceReducer } from './emailReducer';
import { embedSecretsReducer } from './embedSecretsReducer';
import { embeddedAuthReducer } from './embeddedAuthReducer';
import { endUserReducer } from './endUserReducer';
import { fidoDataSourceConfigReducer } from './fidoDataSourceConfigurationReducer';
import { fidoReducer } from './fidoReducer';
import { folderReducer } from './folderReducer';
import internationalizationReducer from './internationalizationReducer';
import onboardingReducer from './onboardingReducer';
import reportBuilderEditReducer from './reportBuilderEditReducer';
import { reportBuilderReducer } from './reportBuilderReducer';
import { resourceSavingReducer } from './resourceSavingReducer';
import superuserReducer from './superuserReducer';

const appReducer = combineReducers({
  loading: loadingReducer,
  currentUser: authReducer,
  mfa: mfaReducer,
  teamData: teamDataReducer,
  dataSource: dataSourceReducer,
  dashboard: dashboardReducer,
  datasets: datasetsReducer,
  customers: customersReducer,
  environmentTags: environmentTagsReducer,
  styleConfig: styleConfigReducer,
  dashboardEditConfig: dashboardEditConfigReducer,
  dashboardVersions: dashboardVersionsReducer,
  parentSchemas: parentSchemasReducer,
  allowlistDomains: allowlistDomainReducer,
  internationalization: internationalizationReducer,
  emailCadence: emailCadenceReducer,
  dashboardAttributes: dashboardAttributesReducer,
  endUsers: endUserReducer,
  customFonts: customFontsReducer,
  onboarding: onboardingReducer,
  widget: chatWidgetReducer,
  dashboardData: dashboardDataReducer,
  dashboardLayout: dashboardLayoutReducer,
  dashboardInteractions: dashboardInteractionsReducer,
  dashboardStyles: dashboardStylesReducer,
  cssLinks: cssLinkingReducer,
  resourceSaving: resourceSavingReducer,
  fido: fidoReducer,
  embedSecrets: embedSecretsReducer,
  analytics: telemetryReducer,

  // Embedded Dashboard
  embedDashboard: embedDashboardReducer,

  // Report Builder
  reportBuilder: reportBuilderReducer,
  reportBuilderEdit: reportBuilderEditReducer,
  reportBuilderJob: reportBuilderJobReducer,
  // Embedded ReportBuilder
  embeddedReportBuilder: embeddedReportBuilderReducer,
  reportEditing: reportEditingReducer,
  reportAi: reportAiReducer,

  //Folder
  folder: folderReducer,
  // Superuser
  superuser: superuserReducer,

  ai: aiReducer,

  fidoDataSourceConfig: fidoDataSourceConfigReducer,
  embeddedAuth: embeddedAuthReducer,
});

const rootReducer: Reducer<ReduxState, Action> = (state, action) => {
  if (action.type === ACTION.CLEAR_STATE) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

// AppState
export type ReduxState = ReturnType<typeof appReducer>;

export type DashboardStates = ReduxState | EmbedReduxState;

export type ReportBuilderStates = ReduxState | ReportBuilderReduxState;

export type AllStates = DashboardStates | ReportBuilderStates;

export default rootReducer;

export interface Action {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}
