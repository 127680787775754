import { useState, useEffect, FC } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import parse from 'url-parse';

import { fetchSignupEmail } from 'actions/authAction';
import { ACTION } from 'actions/types';
import { GoogleSSOLoginButton } from 'components/GoogleSSOLoginButton';
import { InfoCard } from 'components/InfoCard';
import { OnboardingFlowPage } from 'components/Onboarding/OnboardingFlowPage';
import { PasswordInput } from 'components/PasswordInput';
import { Input, sprinkles, Button } from 'components/ds';
import { ROUTES } from 'constants/routes';
import * as styles from 'pages/SignInPage.css';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { pageView } from 'telemetry/exploAnalytics';
import { isEnvironmentSecure } from 'utils/environmentUtils';
import { onRegistrationSubmit } from 'utils/landingPageUtils';
import { validatePassword } from 'utils/passwordUtils';

import { registerUser } from './registerUser';

export const SignUpPage: FC = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupEmail, setSignupEmail] = useState('');
  const [teamName, setTeamName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const { registrationLoading, ssoLoginLoading } = useSelector(
    (state: ReduxState) => ({
      registrationLoading: createLoadingSelector([ACTION.REGISTER_USER], false)(state),
      ssoLoginLoading: createLoadingSelector([ACTION.GOOGLE_OAUTH_VERIFICATION], false)(state),
    }),
    shallowEqual,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const urlQueryParams = parse(window.location.href, true).query;
  useEffect(() => {
    if (urlQueryParams.invite_hash) {
      dispatch(
        fetchSignupEmail(
          { postData: { invite_hash: urlQueryParams.invite_hash } },
          (response) => {
            if (response.invite_accepted) {
              history.push(`/login?invite_hash=${urlQueryParams.invite_hash}`);
            }
            if (!response.invite_code) {
              setErrorMsg(
                'The invite code is invalid. Please make sure you copied the invite link correctly or request a new invitation.',
              );
            } else {
              setSignupEmail(response.email);
              setInviteCode(response.invite_code);
              setTeamName(response.team_name);
            }
          },
          () =>
            setErrorMsg(
              'The invite code is invalid. Please make sure you copied the invite link correctly or request a new invitation.',
            ),
        ),
      );
    }
  }, [urlQueryParams.invite_hash, history, dispatch]);

  useEffect(() => {
    pageView('Signup');

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return;
      onRegistrationSubmit(
        password,
        password2,
        signupEmail,
        firstName,
        lastName,
        registerUser,
        dispatch,
        setErrorMsg,
      );
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const formIncomplete = !password || !password2 || !signupEmail || !firstName || !lastName;

  const passwordError = password && password2 ? validatePassword(password, password2) : '';

  return (
    <OnboardingFlowPage
      helpLinks={[
        { name: 'Sign In', to: ROUTES.LOGIN },
        { name: 'Need Support?', url: 'https://docs.explo.co/' },
      ]}
      title={
        teamName ? (
          <>
            Join <b>{teamName}</b> on Explo
          </>
        ) : (
          'Get started with Explo'
        )
      }>
      <div className={sprinkles({ width: 'fill', flexItems: 'column', gap: 'sp2' })}>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp2' })}>
          <Input
            className={sprinkles({ flex: 1 })}
            data-testid="sign-up-first-name"
            label="First name"
            onChange={setFirstName}
            value={firstName}
          />
          <Input
            className={sprinkles({ flex: 1 })}
            data-testid="sign-up-last-name"
            label="Last name"
            onChange={setLastName}
            value={lastName}
          />
        </div>

        <Input
          fillWidth
          data-testid="sign-up-email"
          disabled={!!inviteCode}
          label="Email address"
          onChange={setSignupEmail}
          value={signupEmail}
        />

        <PasswordInput
          data-testid="sign-up-password"
          label="Password"
          onChange={setPassword}
          value={password}
        />
        <PasswordInput
          data-testid="sign-up-confirm-password"
          label="Confirm password"
          onChange={setPassword2}
          value={password2}
        />
        {errorMsg || passwordError ? (
          <InfoCard
            error
            noTopMargin
            className={sprinkles({ height: 'fitContent' })}
            text={errorMsg || passwordError}
          />
        ) : null}
        <div className={sprinkles({ flexItems: 'column', gap: 'sp2' })}>
          <Button
            fillWidth
            className={styles.signInButton}
            data-testid="sign-up-submit"
            disabled={formIncomplete || passwordError !== ''}
            loading={registrationLoading || ssoLoginLoading}
            onClick={() =>
              !formIncomplete &&
              onRegistrationSubmit(
                password,
                password2,
                signupEmail,
                firstName,
                lastName,
                registerUser,
                dispatch,
                setErrorMsg,
              )
            }>
            Sign Up
          </Button>
          {isEnvironmentSecure() ? null : <GoogleSSOLoginButton isSignIn />}
        </div>

        <div className={sprinkles({ body: 'b3' })}>
          By creating an account, you agree to our{' '}
          <a
            className={termsLinkClass}
            href="//explo.co/legal/terms-of-service"
            rel="noopener noreferrer"
            target="_blank">
            terms{' '}
          </a>
          and{' '}
          <a
            className={termsLinkClass}
            href="//explo.co/legal/privacy-policy"
            rel="noopener noreferrer"
            target="_blank">
            privacy policy
          </a>
          .
        </div>
      </div>
    </OnboardingFlowPage>
  );
};

const termsLinkClass = sprinkles({ color: 'active', textDecoration: 'none' });
