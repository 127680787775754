import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import plaintext from 'react-syntax-highlighter/dist/esm/languages/hljs/plaintext';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';

import { generateEmbedJwt } from 'actions/customerActions';
import { CustomerSelector } from 'components/CustomerSelector';
import { DocsLink } from 'components/DocsLink';
import {
  APP_PORTAL_ID,
  Modal,
  sprinkles,
  Icon,
  vars,
  ModalSection,
  Toggle,
  ToggleItem,
  Spinner,
} from 'components/ds';
import { SETTINGS_SUB_SECTION, SUB_SECTION_ROUTE_MAP } from 'constants/settingsPageConstants';
import { ReduxState } from 'reducers/rootReducer';
import { fetchCustomer } from 'reducers/thunks/customerThunks';
import * as RD from 'remotedata';
import { showSuccessToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { getDomain } from 'utils/environmentUtils';

import { codeBlockClass, headerClass, textContentClass } from './styles.css';

SyntaxHighlighter.registerLanguage('plaintext', plaintext);
SyntaxHighlighter.registerLanguage('javascript', javascript);

type Props = {
  onClose: () => void;
  embedId: string;
  embedName: string;
  pageType: ResourcePageType;
  modalOpen: boolean;
};

enum MethodType {
  WEB_COMPONENT = 'Web Component',
  IFRAME = 'iFrame',
}

export const ResourceEmbedModal: FC<Props> = ({
  onClose,
  embedId,
  embedName,
  pageType,
  modalOpen,
}) => {
  const dispatch = useDispatch();

  const { selectedCustomerId, selectedJwt } = useSelector(
    (state: ReduxState) => ({
      selectedCustomerId: state.customers.selectedGroupId,
      selectedJwt: state.customers.selectedCustomerJwt,
    }),
    shallowEqual,
  );

  const [method, setMethod] = useState(MethodType.WEB_COMPONENT);
  const [inputCustomerId, setInputCustomerId] = useState(selectedCustomerId);

  useEffect(() => {
    dispatch(fetchCustomer({ customer_id: inputCustomerId, get_default: true }));
  }, [inputCustomerId, dispatch]);

  useEffect(() => {
    if (selectedCustomerId)
      dispatch(
        generateEmbedJwt({
          postData: { customer_id: selectedCustomerId, dashboard_embed_id: embedId },
        }),
      );
  }, [selectedCustomerId, embedId, dispatch]);

  if (!modalOpen) return null;

  const embed_asset_url = process.env.REACT_APP_EMBED_ASSET_URL
    ? `${process.env.REACT_APP_EMBED_ASSET_URL}bundle.js`
    : 'https://embed.explo.co/bundle.js';
  const importInstructionsCode = `<script src="${embed_asset_url}"></script>`;

  const copyToClipboard = (apiToken: string) => {
    navigator.clipboard.writeText(apiToken);
    showSuccessToast('Copied To Clipboard');
  };

  const renderEmbedCode = (jwt: string) => {
    return pageType === ResourcePageType.EXPLORE
      ? `<explo-dashboard\n  dash-jwt="${jwt}"\n  updateUrlParams={true}\n  isProduction={true}\n  environment="ENVIRONMENT"\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n/>\n`
      : `<explo-report-builder\n  jwt="${jwt}"\n  environment="ENVIRONMENT"\n  variables={JSON.stringify({\n    element1: 'value',\n    element2: 'value2',\n  })}\n/>\n`;
  };

  const renderIframeCode = (jwt: string) => {
    return pageType === ResourcePageType.REPORT_BUILDER
      ? `<iframe\n  src="${getDomain()}report-builder/iframe/${jwt}"\n style="width: 100%;border: none;height: 100vh;">\n</iframe> `
      : `<iframe\n  src="${getDomain()}iframe/${jwt}"\n  style="width: 100%;border: none;height: 100vh;">\n</iframe> `;
  };

  const renderCodeSection = () => {
    if (!RD.isSuccess(selectedJwt)) return <Spinner size="sm" />;
    const jwt = selectedJwt.data ?? '<JWT>';

    return renderCodeBlock(
      method === MethodType.IFRAME ? renderIframeCode(jwt) : renderEmbedCode(jwt),
    );
  };

  const renderCodeBlock = (code: string) => (
    <div className={codeBlockClass} onClick={() => copyToClipboard(code)}>
      <SyntaxHighlighter
        wrapLines
        customStyle={{
          padding: '8px',
          margin: '0px',
          backgroundColor: vars.colors.elevationMid,
          color: 'black',
        }}
        language="javascript"
        lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
        style={style}>
        {code}
      </SyntaxHighlighter>
      <Icon name="clipboard-reg" />
    </div>
  );

  const renderInlineCode = (code: string) => (
    <SyntaxHighlighter
      customStyle={{
        display: 'inline-flex',
        borderRadius: '3px',
        padding: '2px 4px',
        margin: '0px',
        backgroundColor: vars.colors.elevationMid,
        color: 'black',
      }}
      language="plaintext"
      style={style}>
      {code}
    </SyntaxHighlighter>
  );

  return (
    <Modal
      isOpen
      onClose={onClose}
      portalContainerId={APP_PORTAL_ID}
      primaryButtonProps={{ text: 'Done', onClick: onClose }}
      size="medium"
      title={`Embed "${embedName}"`}>
      <div className={sprinkles({ flexItems: 'column', gap: 'sp1' })}>
        <div className={textContentClass}>
          Before embedding for the first time you must{' '}
          <Link
            className={sprinkles({ body: 'b2', color: 'contentSecondary' })}
            style={{ textDecoration: 'none' }}
            to={`/settings/${SUB_SECTION_ROUTE_MAP[SETTINGS_SUB_SECTION.DOMAIN_ALLOWLISTING]}`}>
            <b>allowlist your domains in Explo</b> <Icon name="arrow-up-right" />
          </Link>
          ,{' '}
          <a
            className={sprinkles({ body: 'b2', color: 'contentSecondary' })}
            href="https://docs.explo.co/embedding-documentation/add-explo-domains-to-csp"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            target="_blank">
            <b>add Explo&rsquo;s domains to your CSP</b>
            <Icon name="arrow-up-right" />
          </a>
          , and{' '}
          <a
            className={sprinkles({ body: 'b2', color: 'contentSecondary' })}
            href="https://docs.explo.co/data-sources/connecting-to-data-sources/connectivity-regions#default-connectivity-region-access"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            target="_blank">
            <b>allowlist Explo&rsquo;s egress IPs</b>
            <Icon name="arrow-up-right" />.
          </a>
        </div>
        <ModalSection
          headerClass={headerClass}
          textContentClass={textContentClass}
          title="Copy your embed ID">
          <div className={sectionContainerClass}>
            <div>
              Use the embed ID to generate an embed secret for use in production. Learn more{' '}
              <a href="https://docs.explo.co/embedding-documentation/embed-secrets">here</a>. If you
              are testing embedding a dashboard or report builder, you can skip this step.
            </div>
            {renderCodeBlock(embedId)}
          </div>
        </ModalSection>

        <ModalSection
          headerClass={headerClass}
          textContentClass={textContentClass}
          title="Choose a format">
          <div className={sectionContainerClass}>
            <Toggle
              onValueChange={(newValue) => setMethod(newValue as MethodType)}
              selectedValue={method}>
              <ToggleItem value={MethodType.WEB_COMPONENT} />
              <ToggleItem value={MethodType.IFRAME} />
            </Toggle>
          </div>
        </ModalSection>
        {method === MethodType.WEB_COMPONENT ? (
          <ModalSection
            headerClass={headerClass}
            textContentClass={textContentClass}
            title="Import the Explo JS Library">
            <div className={sectionContainerClass}>
              <div>
                In the {renderInlineCode('<head>')} of your {renderInlineCode('index.html')} include
                the following script imports
              </div>
              {renderCodeBlock(importInstructionsCode)}
            </div>
          </ModalSection>
        ) : null}
        <ModalSection
          headerClass={headerClass}
          textContentClass={textContentClass}
          title="Select a customer entity to test with">
          <div className={sectionContainerClass}>
            <CustomerSelector
              fillWidth
              onSelect={(id) => {
                const customerId = parseInt(id);
                if (isNaN(customerId)) return;
                setInputCustomerId(customerId);
              }}
              selectedCustomerId={selectedCustomerId ?? undefined}
            />
          </div>
        </ModalSection>
        <ModalSection
          headerClass={headerClass}
          textContentClass={textContentClass}
          title="Preview the snippet">
          <div className={sectionContainerClass}>
            <div>
              This code snippet should only be used for testing purposes since the generated embed
              secret (JWT) will expire after 24 hours. To use this code snippet in production, you
              should replace this test JWT with one you have generated for production use.
            </div>
            {renderCodeSection()}
            {method === MethodType.WEB_COMPONENT ? (
              <DocsLink url="https://docs.explo.co/embedding-documentation/dashboard/option-1-web-component">
                Using our web component
              </DocsLink>
            ) : (
              <DocsLink url="https://docs.explo.co/embedding-documentation/dashboard/option-2-iframe">
                Using iFrame
              </DocsLink>
            )}
          </div>
        </ModalSection>
      </div>
    </Modal>
  );
};

const sectionContainerClass = sprinkles({ flexItems: 'column', paddingY: 'sp3', gap: 'sp1' });
