import cx from 'classnames';
import JSURL from 'jsurl';
import { FC, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import parse from 'url-parse';

// @ts-ignore
import { embedFetchDashboard } from 'actions/embedActions';
import { ChartLayout } from 'components/ChartLayout/ChartLayout';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import {
  DASHBOARD_CLASS_NAME,
  DASHBOARD_LOADED_CLASS_NAME,
  DATA_PANEL_ERROR_CLASS_NAME,
} from 'constants/exportConstants';
import { UserTransformedSchema, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { GlobalStylesProvider } from 'globalStyles';
import { loadFonts } from 'globalStyles/utils';
import { getCurrentTheme, setDashboardTheme } from 'reducers/dashboardStylesReducer';
import { getEmbedDashboardConfigSelector } from 'reducers/selectors';
import * as RD from 'remotedata';
import { getOrDefault, hasNotReturned } from 'remotedata';
import { pageView } from 'telemetry/exploAnalytics';
import { useSetupAnalytics } from 'telemetry/telemetryUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { getCustomerVariables } from 'utils/customerUtils';
import { loadLocale } from 'utils/localizationUtils';
import { getQueryVariables } from 'utils/variableUtils';

const errorMessageClass = sprinkles({
  margin: 'sp5',
  padding: 'sp5',
  borderRadius: 8,
  backgroundColor: 'red3',
  fontSize: 24,
});

type Props = {
  dashboardEmbedId: string; // Empty string if using embedJwt
  dataPanelId?: string; // Optional if encoded in embedJwt
  versionNumber?: number; // Defaults to latest
  customerToken?: string; // Either customerToken or embedJwt must be provided
  embedJwt?: string;
  isScreenshotDownload: boolean;
};

export const EmbeddedChart: FC<Props> = ({
  dashboardEmbedId,
  versionNumber,
  customerToken,
  dataPanelId: passedDataPanelId,
  embedJwt,
  isScreenshotDownload,
}) => {
  const [urlVariables] = useState<DashboardVariableMap>(getQueryVariables('shared'));
  const [userTransformedSchema] = useState<UserTransformedSchema>(getUserTransformedSchema());
  const [adHocOps] = useState<AdHocOperationInstructions>(getAdHocOps());
  const [reportName] = useState<string>(getReportName());

  const dispatch = useDispatch();

  const { dashboard, dashboardConfig, dataPanelId, team, customer, globalStyleConfig, fontConfig } =
    useSelector(
      (state: EmbedReduxState) => ({
        dashboard: state.embedDashboard.dashboard,
        dashboardConfig: getEmbedDashboardConfigSelector(state),
        dataPanelId: state.embedDashboard.dataPanelId,
        team: state.embedDashboard.team,
        customer: state.embedDashboard.customer,
        fontConfig: state.dashboardStyles.fontConfig,
        globalStyleConfig: getCurrentTheme(state.dashboardStyles),
      }),
      shallowEqual,
    );

  useEffect(() => {
    const theme = urlVariables['theme'];
    if (!theme || typeof theme !== 'string') return;
    dispatch(setDashboardTheme(theme));
  }, [urlVariables, dispatch]);

  const archetypePropertySet = useMemo(
    () => new Set(team?.archetype_properties?.map((prop) => prop.name)),
    [team?.archetype_properties],
  );

  const { environment, isProduction } = useMemo(() => {
    const queryVariables = getQueryVariables('shared');
    const environment = queryVariables['environment'];
    const isProduction = queryVariables['is_production'];
    return { environment, isProduction };
  }, []);

  const onLoad = () => {
    pageView('Shared Chart');

    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          jwt: embedJwt,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
          },
        },
        (data) => {
          loadLocale({
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  };
  useEffect(onLoad, [
    dispatch,
    versionNumber,
    dashboardEmbedId,
    customerToken,
    environment,
    embedJwt,
  ]);

  const analyticsReady = useSetupAnalytics({
    pageViewEvent: REPORTED_ANALYTIC_ACTION_TYPES.SHARED_CHART_VIEWS,
    environment: environment as string | undefined,
    isProduction,
    embedType: 'chart',
  });

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [fontConfig, globalStyleConfig.text, team]);

  const variables = useMemo(
    () => ({
      ...urlVariables,
      ...(customer && getCustomerVariables(customer, archetypePropertySet)),
    }),
    [archetypePropertySet, customer, urlVariables],
  );

  if (RD.isError(dashboard)) {
    return (
      <div
        className={cx(DASHBOARD_LOADED_CLASS_NAME, DATA_PANEL_ERROR_CLASS_NAME, errorMessageClass)}>
        {dashboard.error}
      </div>
    );
  } else if (RD.isLoading(dashboard, true) || !analyticsReady) {
    return <EmbedSpinner fillContainer size="xl" />;
  } else if (!RD.isSuccess(dashboard) || !customer) {
    return (
      <div
        className={cx(DASHBOARD_LOADED_CLASS_NAME, DATA_PANEL_ERROR_CLASS_NAME, errorMessageClass)}>
        There was an error loading the dashboard. Please contact your support team for help.
      </div>
    );
  }

  return (
    <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
      {(globalStylesClassName) => (
        <div className={cx(DASHBOARD_CLASS_NAME, globalStylesClassName)}>
          <ChartLayout
            adHocOperationInstructions={adHocOps}
            customerToken={customerToken}
            dashboardConfig={dashboardConfig}
            dashboardEmbedId={dashboardEmbedId}
            dataPanelId={dataPanelId || passedDataPanelId || ''}
            embedJwt={embedJwt}
            isScreenshotDownload={isScreenshotDownload}
            reportName={reportName}
            userTransformedSchema={userTransformedSchema}
            variables={variables}
          />
        </div>
      )}
    </GlobalStylesProvider>
  );
};

function getReportName() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.reportName) return '';

  const reportNameWithQuotesRemoved = rawVars.reportName.substring(
    1,
    rawVars.reportName.length - 1,
  );

  return reportNameWithQuotesRemoved;
}

function getUserTransformedSchema() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.userTransformedSchema) return [];

  return JSURL.parse(
    rawVars.userTransformedSchema.substring(1, rawVars.userTransformedSchema.length),
  ) as UserTransformedSchema;
}

function getAdHocOps() {
  const rawVars = parse(window.location.href, true).query;
  if (!rawVars?.adHocOps) return {};

  return JSURL.parse(
    rawVars.adHocOps.substring(1, rawVars.adHocOps.length),
  ) as AdHocOperationInstructions;
}
